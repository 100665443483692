import { QpLoggerService } from '@library/services/qp-logger/qp-logger.service';
import { Injectable } from '@angular/core';
import { SwUpdate, VersionEvent, VersionReadyEvent } from '@angular/service-worker';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs';

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class SwReloadOnUpdateService {
  public constructor(
    private readonly _swUpdate: SwUpdate,
    private readonly _qpLoggerService: QpLoggerService
  ) {}

  public init(): void {
    this._swUpdate.versionUpdates
      .pipe(
        filter((event: VersionEvent): event is VersionReadyEvent => event.type === 'VERSION_READY'),
        untilDestroyed(this)
      )
      .subscribe({
        next: (): void => {
          this._qpLoggerService.info('🌈 New version available reload in progress');
          void this._swUpdate.activateUpdate().then((): void => document.location.reload());
        },
        error: (error: Error): void => {
          this._qpLoggerService.error(error);
        },
      });
  }
}

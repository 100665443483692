import { InjectionToken } from '@angular/core';
export const QIMA_MENU_CONFIG_TOKEN = new InjectionToken('The default values used by the inputs of the menu component', {
  factory() {
    /**
     * The fallback values should be identical as the fallback [provider]{@link QimaMenuModule#forRoot} values
     */
    return {
      allowUnselectingItem: false
    };
  }
});

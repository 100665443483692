import { ENVIRONMENT } from '@one/environments/environment';

export const { VERSION } = ENVIRONMENT;

export const { DEBUG_INFO_ENABLED } = ENVIRONMENT;

export const { SERVER_API_URL } = ENVIRONMENT;

export const ACADEMY_URL = 'https://academy.qimaone.';

export const DEBOUNCE_TIME_IN_MS: number = 500;

export const BILLING_PAGE_URL = 'https://qimaonebilling.bubbleapps.io/billing_page';

export const AI_BLUEBERRIES_PAGE_URL = 'https://qbdd.bubbleapps.io';

export const MINIMUM_REPORT_GROUP_SIZE = 1;

export const DEFAULT_IS_DEFECTS_PER_PRODUCT = true;

export const DEFAULT_IS_AQL_PER_PRODUCT = false;

export const COPILOT_BOOKING_PAGE_URL = 'https://qimaonebilling.bubbleapps.io/booking_copilot_v3';

export const COPILOT_REPORT_DECISION_PAGE_URL = 'https://qimaonebilling.bubbleapps.io/report_decision_rules_v3';

/**
 * @description This enum reflects `com.qima.platform.domain.enumeration.InspectionStatus.java`
 */
export enum InspectionStatus {
  FACTORY_REQUEST = 'FACTORY_REQUEST',
  PENDING = 'PENDING',
  FACTORY_INSPECTOR_PENDING = 'FACTORY_INSPECTOR_PENDING',
  DRAFT = 'DRAFT',
  INSPECTOR_TO_ASSIGN = 'INSPECTOR_TO_ASSIGN',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
  STARTED = 'STARTED',
  COMPLETED = 'COMPLETED',
  ABORTED = 'ABORTED',
  REPORT_REVISION_REQUESTED = 'REPORT_REVISION_REQUESTED',
  REPORT_IN_REVISION = 'REPORT_IN_REVISION',
  REPORT_IN_REVISION_BY_SUPERVISOR = 'REPORT_IN_REVISION_BY_SUPERVISOR',
  CANCELLED = 'CANCELLED',
}

export const COMPLETION_STATUSES: InspectionStatus[] = [InspectionStatus.COMPLETED, InspectionStatus.ABORTED];

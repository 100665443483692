import { EQimaInputBorder } from '../../abstract-input/enums/qima-input-border.enum';
import { EQimaInputSize } from '../../abstract-input/enums/qima-input-size.enum';
import { InjectionToken } from '@angular/core';
/**
 * @description
 * The token consumed by the {@link QimaInputSearchSelectComponent} to define the default values of its inputs
 * @type {InjectionToken<IQimaInputSearchSelectConfig>}
 */
export const QIMA_INPUT_SEARCH_SELECT_CONFIG_TOKEN = new InjectionToken('The default values used by the inputs of the input search select component', {
  factory() {
    /**
     * The fallback values should be identical as the fallback [provider]{@link QimaInputSearchSelectModule#forRoot} values
     */
    return {
      isDisabled: false,
      border: EQimaInputBorder.FULL,
      shouldAutoFocus: false,
      hasValidators: false,
      shouldFilterItemsOnSearch: true,
      hasClearButton: true,
      placeholder: '',
      size: EQimaInputSize.MEDIUM,
      menuMinWidth: 'input-width'
    };
  }
});

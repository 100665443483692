export enum EQpIconName {
  IC_CHEVRON_LEFT = 'ic-chevron-left',
  IC_ACCESSORIES = 'ic-accessories',
  IC_CATEGORY_MATERIAL = 'ic-category-material',
  IC_CATEGORY_ASSEMBLY = 'ic-category-assembly',
  IC_DISTRIBUTION_CENTER = 'ic-distribution-center',
  IC_STORAGE = 'ic-storage',
  IC_STORE = 'ic-store',
  IC_FARM = 'ic-farm',
  IC_COOPERATIVE = 'ic-cooperative',
  IC_TRADING_COMPANY = 'ic-trading-company',
  MEDIUM_COMPLETED = 'medium-completed',
  IC_OTHER = 'ic-other',
  IC_ANALYTICS = 'ic-analytics',
  IC_CHEVRON_RIGHT = 'ic-chevron-right',
  IC_CHEVRON_DOWN = 'ic-chevron-down',
  IC_CHEVRONDOWN = 'ic-chevrondown',
  IC_CHEVRONUP = 'ic-chevronup',
  IC_ADD_MEDIUM = 'ic-add-medium',
  IC_ADD = 'ic-add',
  IC_ADD_NEW = 'ic-add-new',
  IC_SEARCH = 'ic-search',
  IC_XS_CHEVRON_DOWN_FILTERS = 'ic-xs-chevron-down-filters',
  IC_XS_FILTERS_APPLICATED = 'ic-xs-filters-applicated',
  /**
   * @deprecated
   * Use instead {@link EQpIconName#IC_CROSS_SMALL} or {@link EQpIconName#IC_CROSS_LARGE}
   * @type {EQpIconName.IC_CLEAR}
   */
  IC_CLEAR = 'ic-clear',
  CLEAR = 'clear', // https://zeroheight.com/084a30acf/p/1034d3-iconography/i/58033771
  IC_CROSS_SMALL = 'ic-cross-small', // https://zeroheight.com/084a30acf/p/1034d3-iconography/i/58033751
  IC_CROSS_LARGE = 'ic-cross-large', // https://zeroheight.com/084a30acf/p/1034d3-iconography/i/58033750
  IC_TICK = 'ic-tick',
  /**
   * @deprecated
   * Too much padding inside
   * @type {EQpIconName.IC_TICK_MEDIUM}
   */
  IC_TICK_MEDIUM = 'ic-tick-medium',
  /**
   * @todo rename to remove suffix _NEW once {@link EQpIconName.IC_TICK_SMALL} is removed
   * @type {EQpIconName.IC_TICK_SMALL_NEW}
   */
  IC_TICK_SMALL_NEW = 'ic-tick-small-new',
  IC_TICK_LARGE = 'ic-tick-large',
  INFORMATIVE_LOCATION = 'informative-location',
  MEDIUM_ALERT = 'medium-alert',
  IC_EXPAND = 'ic-expand',
  IC_COLLAPSE = 'ic-collapse',
  IC_FACTORIES = 'ic-factories',
  MEDIUM_CANCELLED = 'medium-cancelled',
  IC_DELETE = 'ic-delete',
  IC_ERROR = 'ic-error',
  IC_ERROR_RED = 'ic-error-red',
  IC_PRODUCT = 'ic-product',
  IC_ARROW_BACK = 'ic-arrow-back',
  IC_ARROW_RIGHT = 'ic-arrow-right',
  IC_ARROW_DOWN = 'ic-arrow-down',
  IC_ARROW_UP = 'ic-arrow-up',
  IC_ARROW_LEFT = 'ic-arrow-left',
  IC_TASKS = 'ic-tasks',
  IC_INSPECTION_PREPARATION = 'ic-inspection-preparation',
  IC_INSPECTOR = 'ic-inspector',
  IC_INSPECTIONS = 'ic-inspections',
  IC_PHOTOS = 'ic-photos',
  IC_INFORMATION = 'ic-information',
  IC_INFORMATIVE_ALERT = 'ic-informative-alert',
  IC_REPORT = 'ic-report',
  IC_ATTACHMENT = 'ic-attachment',
  IC_DEFECT = 'ic-defect',
  IC_TEST = 'ic-test',
  IC_MINUS = 'ic-minus',
  IC_MEASURE = 'ic-measure',
  IC_VOICE_CONTROL = 'ic-voice-control',
  IC_CLOSE = 'ic-close',
  IC_CLOSE_12 = 'ic-close-12',
  IC_IN_PROGRESS = 'ic-in-progress',
  IC_SAVE = 'ic-save',
  MEDIUM_INFO = 'medium-info',
  IC_EDIT = 'ic-edit',
  IC_EDIT_FIELD = 'ic-edit-field',
  IC_BADGES_INFO_EMPTY = 'ic-badges-info-empty',
  IC_LOADING = 'ic-loading',
  IC_LOGOUT = 'ic-logout',
  IC_REMARK = 'ic-remark',
  IC_REPORT_MODIFY = 'ic-report-modify',
  IC_QUANTITY = 'ic-quantity',
  IC_SETTINGS = 'ic-settings',
  IC_DEFAULT = 'ic-default',
  IC_REPEAT = 'ic-repeat',
  IC_SP = 'ic-sp',
  IC_IPC = 'ic-ipc',
  IC_CAMERA = 'ic-camera',
  IC_NOTE = 'ic-note',
  IC_SCAN = 'ic-scan',
  IC_INSPECTION_CLOSURE = 'ic-inspection-closure',
  IC_LIBRARY = 'ic-library',
  IC_FROM_SCRATCH = 'ic-from-scratch',
  IC_REVERT = 'ic-revert',
  IC_REDO = 'ic-redo',
  IC_NEW_INSPECTION = 'ic-new-inspection',
  IC_COMPANY = 'ic-company',
  IC_DRAG_TO_REARRANGE = 'ic-drag-to-rearrange',
  IC_CATEGORY_GENERAL = 'ic-category-general',
  IC_QIMA = 'ic-qima',
  IC_QIMA_Q_LOGO = 'ic-qima-q-logo',
  IC_QIMA_LOGO = 'ic-qima-logo',
  /**
   * @deprecated
   * Too much padding inside
   * Use instead {@link EQpIconName#IC_TICK_SMALL_NEW}
   * @type {EQpIconName.IC_TICK_SMALL}
   */
  IC_TICK_SMALL = 'ic-tick-small',
  IC_CIRCLE_TICKED = 'ic-circle-ticked',
  IC_CIRCLE = 'ic-circle',
  IC_EMAIL = 'ic-email',
  IC_PHONE = 'ic-phone',
  IC_MESSAGE = 'ic-message',
  IC_WORKFLOW = 'ic-workflow',
  EXCLAMATION_CIRCLE = 'exclamation-circle',
  IC_PASSWORD_SHOW = 'ic-password-show',
  IC_PASSWORD_HIDE = 'ic-password-hide',
  IC_MORE_OPTIONS = 'ic-more-options',
  IC_CHECKLISTS = 'ic-checklists',
  IC_PASSWORD = 'ic-password',
  IC_VIEW = 'ic-view',
  IC_DATE = 'ic-date',
  IC_DENY = 'ic-deny',
  IC_MENU = 'ic-menu',
  IC_COLLAPSE_MENU = 'ic-collapse-menu',
  IC_BELL = 'ic-bell',
  IC_COST = 'ic-cost',
  IC_LOCK = 'ic-lock',
  IC_DRAW = 'ic-draw',
  IC_LOCATION = 'ic-location',
  IC_LOCATION_FILLED = 'ic-location-filled',
  IC_FILE = 'ic-file',
  IC_PDF = 'ic-pdf',
  IC_WORD = 'ic-word',
  IC_EXCEL = 'ic-excel',
  IC_DASH_LARGE = 'ic-dash-large',
  IC_DASH_SMALL = 'ic-dash-small',
  IC_ARROW_FORWARD = 'ic-arrow-forward',
  IC_CHEVRON_BREADCRUMBS = 'ic-chevron-breadcrumbs',
  IC_VIDEO = 'ic-video',
  IC_DUPLICATE = 'ic-duplicate',
  IC_BLOCKER = 'ic-blocker2',
  IC_TIME = 'ic-time',
  IC_SHARE = 'ic-share',
  IC_TEXT = 'ic-text',
  IC_TEXT_BKG = 'ic-text-bkg',
  IC_NUMBER = 'ic-number',
  IC_USERNAME = 'ic-username',
  IC_USERNAME_FILLED = 'ic-username-filled',
  IC_NETWORK = 'ic-network',
  IC_WARNING = 'ic-warning',
  IC_OFFLINE = 'ic-offline',
  IC_ONLINE = 'ic-online',
  IC_COMMENT = 'ic-comment',
  IC_DESCRIPTION = 'ic-description',
  IC_CHECKLIST_TEST = 'ic-checklist-test',
  IC_SUCCESS_COMPLETED = 'ic-completed-success',
  IC_BADGE_RESULT_FAIL = 'ic-badge-result-fail',
  IC_BADGE_RESULT_PASS = 'ic-badge-result-pass',
  IC_BADGE_RESULT_NA = 'ic-badge-result-na',
  IC_IMPORT = 'ic-import',
  IC_HOME = 'ic-home',
  IC_MYTEAM = 'ic-myteam',
  IC_ASSIGNMENTS = 'ic-assignments',
  IC_ICONS_ALL_IC_YES_NO_NA = 'icons-all-ic-yes-no-na',
  IC_DASHBOARD = 'ic-dashboard',
  IC_STANDARD = 'ic-standard',
  IC_SIGNATURE = 'ic-signature',
  IC_ENVIRONMENT = 'ic-environment',
  IC_PRINT_REPORT = 'ic-print-report',
  IC_DOWNLOAD_ATTACHMENTS = 'ic-download-attachments',
  IC_DOWNLOAD_PDF = 'ic-download-pdf',
  IC_EXPORT_DATA = 'ic-export-data',
  IC_SPOTLIGHT = 'ic-spotlight',
  IC_SPOTLIGHT_DEACTIVATED = 'ic-spotlight-deactivated',
  IC_CAPTION_DEACTIVATED = 'ic-caption-deactivated',
  IC_TOOLS = 'ic-tools',
  IC_IMAGE = 'ic-image',
  IC_IMAGE_BKG = 'ic-image-bkg',
  IC_REVERSE_CAMERA = 'ic-reverse-camera',
  IC_ASSIGNMENTS_REQUESTS = 'ic-assignments-requests',
  IC_INSPECTION_IN_PROGRESS = 'ic-inspection-in-progress',
  IC_INSPECTION_SYNCHRONIZING = 'ic-inspection-synchronizing',
  IC_UPCOMING_ASSIGNMENTS = 'ic-upcoming-assignments',
  IC_CUSTOMIZE = 'ic-customize',
  IC_CLEAR_FILTERS = 'ic-clear-filters',
  IC_COMPLETED = 'ic-completed',
  IC_EML = 'ic-eml',
  IC_ZIP = 'ic-zip',
  IC_RAR = 'ic-rar',
}

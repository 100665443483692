// @ts-strict-ignore
import { AutoUnsubscriber } from '@library/classes/auto-unsubscriber/auto-unsubscriber';
import { QpIconComponent } from '@library/components/qp-icon/qp-icon.component';
import { EQpIconName } from '@library/components/qp-icon/qp-icon.models';
import { QpLargeDeviceDirective } from '@library/directives/qp-large-device/qp-large-device.directive';
import { QpSmallDeviceDirective } from '@library/directives/qp-small-device/qp-small-device.directive';
import { IBreadcrumb } from '@one/app/components/subnav-breadcrumbs/subnav-breadcrumbs.models';
import { NgFor, NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd, PRIMARY_OUTLET, UrlSegment, RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { last, isEmpty, flatten } from 'lodash/index';
import { filter, startWith } from 'rxjs/operators';

@Component({
  selector: 'one-subnav-breadcrumbs',
  templateUrl: './subnav-breadcrumbs.component.html',
  styleUrls: ['./subnav-breadcrumbs.component.scss'],
  standalone: true,
  imports: [QpLargeDeviceDirective, NgFor, NgIf, RouterLink, QpSmallDeviceDirective, QpIconComponent, TranslateModule],
})
export class SubnavBreadcrumbsComponent extends AutoUnsubscriber implements OnInit {
  public breadcrumbs: IBreadcrumb[] = [];
  public readonly iconNames: typeof EQpIconName = EQpIconName;

  public get currentCrumb(): IBreadcrumb {
    return last(this.breadcrumbs);
  }

  public get hasPrevious(): boolean {
    return this.breadcrumbs.length > 1;
  }

  public constructor(
    private readonly _router: Router,
    private readonly _activatedRoute: ActivatedRoute
  ) {
    super();
  }

  public ngOnInit(): void {
    this.registerSubscription(
      this._router.events
        .pipe(
          filter((e): boolean => e instanceof NavigationEnd),
          startWith(true)
        )
        .subscribe((): void => {
          this.breadcrumbs = this.calculateBreadcrumbs(this._activatedRoute.root, []);
        })
    );
  }

  public goToPrevious(): void {
    void this._router.navigateByUrl(this.breadcrumbs[this.breadcrumbs.length - 2].url);
  }

  public calculateBreadcrumbs(route: ActivatedRoute, breadcrumbs: IBreadcrumb[], url = ''): IBreadcrumb[] {
    if (isEmpty(route.children)) {
      return breadcrumbs;
    }

    const childrenBreadcrumbs: IBreadcrumb[][] = [];

    route.children
      .filter((child: ActivatedRoute): boolean => child.outlet === PRIMARY_OUTLET)
      .forEach((child: ActivatedRoute): void => {
        const routeURL: string = child.snapshot.url.map((segment: UrlSegment): string => segment.path).join('/');
        const nextUrl = `${url}/${routeURL}`;
        const { breadcrumbLabel } = child.snapshot.data;
        // If have data, go to generate a breadcrumb for it.
        const previousBreadcrumb = breadcrumbs.length > 0 ? breadcrumbs[breadcrumbs.length - 1] : null;

        if (breadcrumbLabel && (!previousBreadcrumb || previousBreadcrumb.label !== breadcrumbLabel)) {
          const breadcrumb: IBreadcrumb = {
            label: breadcrumbLabel,
            params: child.snapshot.params,
            url: nextUrl,
            translationParams: child.snapshot.data.resolvedData ? child.snapshot.data.resolvedData.crumbData : {},
          };

          breadcrumbs.push(breadcrumb);
        }

        childrenBreadcrumbs.push(this.calculateBreadcrumbs(child, breadcrumbs, nextUrl));
      });

    return flatten(childrenBreadcrumbs);
  }

  public onClick(crumb: IBreadcrumb): void {
    void this._router.navigateByUrl(crumb.url);
  }
}

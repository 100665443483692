import { WorkflowContentSnapshotDTO } from '@library/dto/inspection/snapshot/workflow-content-snapshot.dto';
import { WorkflowSnapshotDTO } from '@library/dto/inspection/snapshot/workflow-snapshot.dto';
import { WorkflowSumRatingConfigDTO } from '@library/dto/workflow/settings/workflow-sum-rating-config.dto';
import { IWorkflow, IWorkflowAction, IWorkflowStep } from '@one/app/shared/models/workflow/workflow.models';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class InspectionWorkflowMapper {
  public fromWorkflowSnapshotDTO(
    inspectionUuid: string,
    workflow: WorkflowSnapshotDTO,
    sumRatingConfig?: WorkflowSumRatingConfigDTO
  ): IWorkflow {
    return {
      id: 0, // 💡 0 is the new default id for items create offline with an uuid aside 👇
      inspectionUuid,
      name: '',
      steps: this._buildInspectionWorkflowStep(workflow.content),
      globalImages: [],
      workmanship: [],
      sumRatingConfig,
      isAborted: false,
    };
  }

  private _buildInspectionWorkflowStep(content: WorkflowContentSnapshotDTO): IWorkflowStep[] {
    return content.steps.map(
      (step): IWorkflowStep => ({
        id: step.id,
        name: step.name,
        actions: step.actions as unknown as IWorkflowAction[],
      })
    );
  }
}

// @ts-strict-ignore
import { SimplifiedMeasurementChecklistMeasuresDTO } from '@library/dto/checklist/measure/simplified/simplified-measurement-checklist-measures.dto';
import { SimplifiedMeasurementChecklistStatusDTO } from '@library/dto/checklist/measure/simplified/simplified-measurement-checklist-status.dto';
import { AbortInspectionDTO } from '@library/dto/inspection/abort-inspection.dto';
import { CustomFieldInspectionAnswerPairDTO, CustomFieldValueDTO } from '@library/dto/workflow/custom-fields.dto';
import { CartonCalculationMethod } from '@library/dto-enums/carton-calculation-method.dto-enum';
import { InspectionStatus } from '@library/dto-enums/inspection-status.dto-enum';
import { QpIdType, QpUuidType } from '@library/models/qp-alias.models';
import { EQpImageElementType } from '@library/models/qp-image.models';
import { EQpQuestionType } from '@library/models/qp-question.models';
import {
  IQpTestsChecklistElement,
  IQpTestsChecklistElementTable,
  IQpTestsChecklistElementTableRows,
  IQpTestsChecklistElementTableValue,
  QpTestsChecklistElementTableColumnType,
} from '@library/models/qp-tests-checklist-element.models';
import { QpDateService } from '@library/services/qp-date/qp-date.service';
import { QpLoggerService } from '@library/services/qp-logger/qp-logger.service';
import { QpNotificationBarService } from '@library/services/qp-notification-bar/qp-notification-bar.service';
import { IDefectsChecklistDefect } from '@one/app/pages/isp/pages/inspection/pages/id/pages/defects-checklist/isp-inspection-id-defect.models';
import { IspWorkflow } from '@one/app/pages/isp/pages/inspection/pages/id/shared/classes/isp-workflow/isp-workflow';
import { IspWorkflowStepActionCustomFields } from '@one/app/pages/isp/pages/inspection/pages/id/shared/classes/isp-workflow-step-action-custom-fields/isp-workflow-step-action-custom-fields';
import WorkflowUtils from '@one/app/pages/isp/pages/inspection/pages/id/shared/services/workflow.utils';
import { getInspectionIdOrUuid } from '@one/app/pages/isp/shared/functions/isp-get-inspection-id-or-uuid';
import { InspectionId } from '@one/app/pages/isp/shared/models/isp-inspection.models';
import { IMeasure, IVariance } from '@one/app/pages/isp/shared/models/variances/inspection-variances.models';
import { InspectionComment } from '@one/app/shared/classes/inspection/inspection-comment';
import { ActionRequest } from '@one/app/shared/models/action-request.models';
import { Answer } from '@one/app/shared/models/answer/answer.models';
import { AqlDefectsType } from '@one/app/shared/models/defects/aql-defects.models';
import {
  IAttachmentData,
  IChecklistComment,
  IChecklistDocumentData,
  IChecklistImageData,
  IInspectionConsultation,
} from '@one/app/shared/models/inspection-consultation/inspection-consultation.models';
import { InspectionFile } from '@one/app/shared/models/inspection-file.models';
import { IWorkflowSamplingSize } from '@one/app/shared/models/sampling/sampling-size.models';
import {
  EWorkflowActionType,
  INewDefectInfo,
  IProductActualQuantities,
  IWorkflow,
  IWorkflowAction,
  IWorkflowInspectionFindingsSummaryReview,
  IWorkflowStep,
  WorkflowCheckType,
} from '@one/app/shared/models/workflow/workflow.models';
import { AccountService } from '@one/app/shared/services/account/account.service';
import { DatabaseAbstract } from '@one/app/shared/services/database/database-abstract';
import {
  ISaveProductPictureAction,
  ISaveTestChecklistAnswerData,
  UpdateDefectCommentDataType,
  UpdateDefectQuantityDataType,
} from '@one/app/shared/services/database/database-inspection.models';
import { ProductMeasurementsService } from '@one/app/shared/services/measurements/product-measurements.service';
import { Injectable } from '@angular/core';
import { QimaOptionalType } from '@qima/ngx-qima';
import { cloneDeep, forEach, isEmpty, isNil } from 'lodash/index';
import { v4 as uuidv4 } from 'uuid';

@Injectable({
  providedIn: 'root',
})
export class DatabaseService extends DatabaseAbstract {
  public constructor(
    _accountService: AccountService,
    protected readonly _qpNotificationBarService: QpNotificationBarService,
    protected readonly _qpLoggerService: QpLoggerService,
    private readonly _qpDateService: QpDateService
  ) {
    super(_accountService, _qpNotificationBarService, _qpLoggerService);
  }

  public async getInspections(): Promise<IInspectionConsultation[]> {
    if (isNil(this._database)) {
      throw new Error('Database is not initialized');
    }

    return await this._database.inspection.toArray();
  }

  public async getInspection(id: Readonly<InspectionId>): Promise<IInspectionConsultation | undefined> {
    if (id === 0) {
      throw new Error(`Invalid id 0 👉 it's because one of the migrations to the uuid was missed... 😥`);
    }

    return typeof id === 'number' ? this._getInspectionById(id) : this._getInspectionByInspectonUuid(id);
  }

  public async upsertInspection(inspection: IInspectionConsultation): Promise<IInspectionConsultation> {
    const id = getInspectionIdOrUuid(inspection);
    const dbInspection = await this.getInspection(id);

    try {
      if (dbInspection) {
        await this._updateInspection(id, inspection);
      } else {
        await this._database.inspection.put(inspection);
      }
    } catch (error) {
      this._displayErrorNotification(error);
      throw error;
    }

    return inspection;
  }

  public async updateInspectionAql(id: InspectionId, aqlDefects: AqlDefectsType): Promise<void> {
    try {
      const inspection = await this.getInspection(id);
      const workflow = await this.getWorkflow(id);

      if (inspection.isAqlPerProduct) {
        const perProductAqlAndSampling = WorkflowUtils.getUpdatedPerProductAqlAndSampling(
          inspection,
          workflow,
          aqlDefects.purchaseOrderProductId,
          {
            aqlDefects,
          }
        );

        await this._updateWorkflow(id, {
          perProductAqlAndSampling,
        });
      } else {
        await this._updateWorkflow(id, {
          aqlDefects,
        });
      }
    } catch (error) {
      this._displayErrorNotification(error);
      throw error;
    }
  }

  public async saveInspectionFindingsSummaryReview(
    inspectionId: InspectionId,
    findingsSummaryReview: IWorkflowInspectionFindingsSummaryReview
  ): Promise<void> {
    const workflow: QimaOptionalType<IWorkflow> = await this.getWorkflow(inspectionId);

    if (workflow) {
      const clonedWorkflowSteps: IWorkflowStep[] = cloneDeep(workflow.steps);

      clonedWorkflowSteps.forEach((step: IWorkflowStep): void => {
        step.actions.forEach((action: IWorkflowAction): void => {
          if (action.type === EWorkflowActionType.FINDINGS_SUMMARY_REVIEW) {
            action.findingsSummaryReview = findingsSummaryReview;
          }
        });
      });

      const newWorkflow: IWorkflow = {
        ...workflow,
        steps: clonedWorkflowSteps,
      };

      try {
        await this._updateWorkflow(inspectionId, newWorkflow);
      } catch (error) {
        this._displayErrorNotification(error);
        throw error;
      }
    }
  }

  public async updateInspectionStatus(id: InspectionId, status: InspectionStatus): Promise<void> {
    try {
      await this._updateInspection(id, {
        status,
      });
    } catch (error) {
      this._displayErrorNotification(error);
      throw error;
    }
  }

  public async addActionRequest(action: ActionRequest): Promise<number> {
    try {
      const insertedAction = await this._database.actionRequest.put(action);

      return insertedAction;
    } catch (error) {
      this._displayErrorNotification(error);
      throw error;
    }
  }

  public hasPendingRequests(inspectionId: Readonly<InspectionId>): Promise<boolean> {
    return this.retrieveActionRequests().then((actionRequests: ActionRequest[]): boolean => {
      const hasPendingRequests: boolean = !isNil(
        actionRequests.find((actionRequest: ActionRequest): boolean => actionRequest.url.includes(`inspections/${inspectionId}/`))
      );

      return hasPendingRequests;
    });
  }

  public async markActionRequestAsSynced(action: ActionRequest): Promise<number | never> {
    const actionDB = await this._database.actionRequest.get(action.id);

    actionDB.isSynced = 1;
    actionDB.syncDate = new Date();

    try {
      return await this._database.actionRequest.put(actionDB);
    } catch (error) {
      this._displayErrorNotification(error);
      throw error;
    }
  }

  public async retrieveActionRequests(): Promise<ActionRequest[]> {
    return await this._database.actionRequest.where('isSynced').equals(0).sortBy('id');
  }

  public async upsertWorkflow(workflow: IWorkflow): Promise<IWorkflow> {
    const id = workflow.inspectionUuid ?? workflow.id;
    const dbWorkflow = await this.getWorkflow(id);

    try {
      if (dbWorkflow) {
        await this._updateWorkflow(id, workflow);
      } else {
        await this._database.workflow.put(workflow);
      }
    } catch (error) {
      this._displayErrorNotification(error);
      throw error;
    }

    return workflow;
  }

  public async getWorkflow(id: InspectionId): Promise<QimaOptionalType<IWorkflow>> {
    if (id === 0) {
      throw new Error(`Invalid id 0 👉 it's because one of the migrations to the uuid was missed... 😥`);
    }

    return typeof id === 'number' ? this._getWorkflowById(id) : this._getWorkflowByInspectonUUID(id);
  }

  public async updateWorkflowSamplingSize(id: InspectionId, samplingSize: IWorkflowSamplingSize): Promise<void> {
    const inspection = await this.getInspection(id);
    const workflow = await this.getWorkflow(id);

    try {
      if (inspection.isAqlPerProduct) {
        const perProductAqlAndSampling = WorkflowUtils.getUpdatedPerProductAqlAndSampling(
          inspection,
          workflow,
          samplingSize.products[0].purchaseOrderProductId,
          {
            sampling: samplingSize,
          }
        );
        const totalLotSize = WorkflowUtils.getPerProductAqlAndSamplingTotalLotSize(perProductAqlAndSampling);
        const updatedWorkflow: IWorkflow = WorkflowUtils.updateTestsSamplingSize(workflow, totalLotSize);

        await this._updateWorkflow(id, {
          ...updatedWorkflow,
          perProductAqlAndSampling,
        });
      } else {
        const updatedWorkflow = WorkflowUtils.updateWorkflowSamplingSize(workflow, samplingSize);

        await this._updateWorkflow(id, {
          ...updatedWorkflow,
        });
      }
    } catch (error) {
      this._displayErrorNotification(error);
      throw error;
    }
  }

  public async updateWorkflowProductActualQuantities(id: InspectionId, productActualQuantities: IProductActualQuantities): Promise<void> {
    const dbWorkflow = await this.getWorkflow(id);
    const updatedWorkflow = WorkflowUtils.updateProductActualQuantities(dbWorkflow, productActualQuantities);

    try {
      await this._updateWorkflow(id, {
        ...updatedWorkflow,
      });
    } catch (error) {
      this._displayErrorNotification(error);
      throw error;
    }
  }

  public async updateSiteLocation(id: InspectionId, entityId: number): Promise<void> {
    const dbWorkflow = await this.getWorkflow(id);
    const updatedWorkflow = WorkflowUtils.updateSiteLocation(dbWorkflow, entityId);

    try {
      await this._updateWorkflow(id, {
        ...updatedWorkflow,
      });
    } catch (error) {
      this._displayErrorNotification(error);
      throw error;
    }
  }

  public async updateIsInspectionMarkedAsPending(id: InspectionId, isInspectionMarkedAsPending: boolean): Promise<void> {
    const dbWorkflow = await this.getWorkflow(id);
    const updatedWorkflow = WorkflowUtils.updateFindingSummaryReviewPendingState(dbWorkflow, isInspectionMarkedAsPending);

    try {
      await this._updateWorkflow(id, {
        ...updatedWorkflow,
      });
    } catch (error) {
      this._displayErrorNotification(error);
      throw error;
    }
  }

  public async updateWorkflowPickedCartons(
    id: InspectionId,
    pickedCartons: number,
    totalCartons: number,
    cartonCalculationMethod: CartonCalculationMethod
  ): Promise<void> {
    try {
      await this._updateWorkflow(id, {
        pickedCartons,
        totalCartons,
        cartonCalculationMethod,
      });
    } catch (error) {
      this._displayErrorNotification(error);
      throw error;
    }
  }

  public async updateGlobalRemark(id: InspectionId, globalRemark: InspectionComment): Promise<void> {
    try {
      await this._updateWorkflow(id, {
        globalRemark,
      });
    } catch (error) {
      this._displayErrorNotification(error);
      throw error;
    }
  }

  public async saveTestChecklistAnswer(id: InspectionId, { answer, answerId }: ISaveTestChecklistAnswerData): Promise<void> {
    const workflow = await this.getWorkflow(id);

    try {
      const element: IQpTestsChecklistElement<Answer> = WorkflowUtils.getTestById(workflow, answerId);

      if (element.type === EQpQuestionType.TABLE) {
        const tableValue: IQpTestsChecklistElementTableValue = element.value as IQpTestsChecklistElementTableValue;

        tableValue.rows.forEach((row: IQpTestsChecklistElementTableRows): void => {
          const rowValue: QpTestsChecklistElementTableColumnType = row.columns.find(
            (col: QpTestsChecklistElementTableColumnType): boolean => col.id === answerId
          );

          if (!isEmpty(rowValue)) {
            rowValue.value = answer as string;
          }
        });

        element.value = tableValue;
      } else {
        element.value = answer;
      }

      await this.upsertWorkflow(workflow);
    } catch (error) {
      this._displayErrorNotification(error, 'inspection.validate.error.answer-error');
      throw error;
    }
  }

  public async deleteTestChecklistTableRow(
    inspectionId: Readonly<InspectionId>,
    tableResponseId: Readonly<string>,
    answerId: Readonly<string>
  ): Promise<void> {
    const workflow: IWorkflow = await this.getWorkflow(inspectionId);

    try {
      const element: IQpTestsChecklistElementTable = WorkflowUtils.getTestById(workflow, tableResponseId) as IQpTestsChecklistElementTable;
      const rows: IQpTestsChecklistElementTableRows[] = element.value.rows.filter((row: IQpTestsChecklistElementTableRows): boolean => {
        return !row.columns.some((col: QpTestsChecklistElementTableColumnType): boolean => col.id === answerId);
      });

      element.value.rows = rows;

      await this.upsertWorkflow(workflow);
    } catch (error: unknown) {
      this._qpNotificationBarService.error('inspection.validate.error.answer-error');
      this._qpLoggerService.error(error);
    }
  }

  public async updateTestComment(id: InspectionId, answerId: string, comment: IChecklistComment): Promise<void> {
    const workflow = await this.getWorkflow(id);

    try {
      const element = WorkflowUtils.getTestById(workflow, answerId);

      element.comment = comment;
      await this.upsertWorkflow(workflow);
    } catch (error: unknown) {
      this._displayErrorNotification(error, 'inspection.validate.error.inspection-comment-upload');
    }
  }

  public async updateNotApplicable(id: InspectionId, answerId: string, notApplicable: boolean): Promise<void> {
    const workflow = await this.getWorkflow(id);

    try {
      const element = WorkflowUtils.getTestById(workflow, answerId);

      element.notApplicable = notApplicable;
      await this.upsertWorkflow(workflow);
    } catch (error: unknown) {
      this._displayErrorNotification(error, 'inspection.validate.error.inspection-not-applicable');
    }
  }

  public async updateDefectQuantity(id: InspectionId, defectId: string, update: UpdateDefectQuantityDataType): Promise<void> {
    let workflow = await this.getWorkflow(id);

    try {
      workflow = WorkflowUtils.saveDefect(workflow, defectId, update);
      await this.upsertWorkflow(workflow);
    } catch (error: unknown) {
      this._displayErrorNotification(error);
    }
  }

  public async updateDefectComment(id: InspectionId, defectId: string, update: UpdateDefectCommentDataType): Promise<void> {
    let workflow = await this.getWorkflow(id);

    try {
      workflow = WorkflowUtils.saveDefect(workflow, defectId, update);
      await this.upsertWorkflow(workflow);
    } catch (error: unknown) {
      this._displayErrorNotification(error);
    }
  }

  public async addDefect(id: InspectionId, defect: INewDefectInfo): Promise<IDefectsChecklistDefect | never> {
    let workflow = await this.getWorkflow(id);
    const createdDefect: IDefectsChecklistDefect = {
      ...defect,
      defectsFound: [],
      id: WorkflowUtils.calculateNewDefectId(workflow, defect),
      defectId: uuidv4(),
    };

    workflow = WorkflowUtils.addDefect(workflow, createdDefect);

    await this.upsertWorkflow(workflow);

    return createdDefect;
  }

  public async completeDefectsChecklist$(
    inspectionId: InspectionId,
    stepId: number,
    actionId: number,
    purchaseOrderProductId?: QimaOptionalType<number>
  ): Promise<void> {
    const workflow = await this.getWorkflow(inspectionId);

    try {
      const clonedWorkflowSteps: IWorkflowStep[] = cloneDeep(workflow.steps);

      clonedWorkflowSteps.forEach((step: IWorkflowStep): void => {
        if (Number(step.id) === stepId) {
          const actionPathId = `${stepId}-${actionId}`;

          step.actions.forEach((action: IWorkflowAction): void => {
            if (
              action.id === actionPathId &&
              !action.defectsChecklist.completions?.find(
                (completion): boolean =>
                  !!completion.isCompleted && (!purchaseOrderProductId || completion.purchaseOrderProductId === purchaseOrderProductId)
              )
            ) {
              action.defectsChecklist.completions = [
                ...(action.defectsChecklist.completions ?? []),
                { isCompleted: true, purchaseOrderProductId },
              ];
            }
          });
        }
      });

      const newWorkflow: IWorkflow = {
        ...workflow,
        steps: clonedWorkflowSteps,
      };

      await this._updateWorkflow(inspectionId, newWorkflow);
    } catch (error: unknown) {
      this._displayErrorNotification(error);
    }
  }

  public async updateCheck(id: InspectionId, check: WorkflowCheckType): Promise<void> {
    const workflow = await this.getWorkflow(id);

    try {
      const action = WorkflowUtils.getActionFromPath(workflow, check.actionId);
      const index = action.checks.findIndex((c): boolean => c.type === check.type);

      action.checks[index] = check;
      await this.upsertWorkflow(workflow);
    } catch (error: unknown) {
      this._displayErrorNotification(error, 'inspection.validate.error.save-check');
    }
  }

  public async saveProductPicture(id: InspectionId, action: ISaveProductPictureAction): Promise<void> {
    let workflow = await this.getWorkflow(id);

    try {
      const productPictureAction = WorkflowUtils.getActionFromPath(workflow, action.actionId);

      productPictureAction.documentId = action.documentId;

      workflow = WorkflowUtils.replaceAWorkflowAction(workflow, productPictureAction);
      await this.upsertWorkflow(workflow);
    } catch (error: unknown) {
      this._displayErrorNotification(error, 'inspection.validate.error.save-product-picture');
    }
  }

  public async saveMeasure(
    id: InspectionId,
    measurementId: string,
    measure: number,
    sectionName?: QimaOptionalType<string>
  ): Promise<void> {
    const workflow = await this.getWorkflow(id);
    const path: number[] = WorkflowUtils.getPath(measurementId);
    const productIndex = path[2];
    const variances = WorkflowUtils.getCategoryByTestId(
      workflow,
      measurementId,
      EWorkflowActionType.MEASURES_CHECKLIST,
      productIndex
    ) as IVariance[];

    variances[path[3]].measuresEntries
      .reduce((acc, entry): IMeasure[] => [...acc, ...entry.measureAnswers], [])
      .filter(
        (measureEntry: IMeasure): boolean =>
          measurementId === measureEntry.id && (isNil(sectionName) || measureEntry.section === sectionName)
      )
      .forEach((measureEntry: IMeasure): void => {
        measureEntry.measure = measure;
      });
    await this.upsertWorkflow(workflow);
  }

  public async saveCustomMeasurementProductSample(id: InspectionId, path: string, sectionName?: QimaOptionalType<string>): Promise<void> {
    const workflow = await this.getWorkflow(id);
    const inspection = await this.getInspection(id);
    const pathIds: number[] = WorkflowUtils.getPath(path);

    if (pathIds.length < 4) {
      throw new Error('Invalid measurement path');
    }

    const productIndex = pathIds[2];
    const variances = WorkflowUtils.getCategoryByTestId(
      workflow,
      path,
      EWorkflowActionType.MEASURES_CHECKLIST,
      productIndex
    ) as IVariance[];
    const iVariance = variances[pathIds[3]];
    const measuresSampleAnswer = ProductMeasurementsService.generateMeasureAnswer(iVariance, sectionName);

    iVariance.measuresEntries.push(measuresSampleAnswer);
    inspection.measurementsSamplingSize.sampleSize++;

    await this._updateWorkflow(id, workflow); // TODO: check if we have to use upsertWorkflow...
    await this.upsertInspection(inspection);
  }

  public async getFile(id: string): Promise<InspectionFile> {
    return await this._database.file.where('fileId').equals(id).first();
  }

  public async saveAQLReasonForChanges(inspectionId: InspectionId, reason: string, purchaseOrderProductId?: number): Promise<void> {
    const inspection = await this.getInspection(inspectionId);
    const workflow = await this.getWorkflow(inspectionId);

    try {
      if (inspection.isAqlPerProduct) {
        const perProductAqlAndSampling = WorkflowUtils.getUpdatedPerProductAqlAndSampling(inspection, workflow, purchaseOrderProductId, {
          aqlModificationReason: reason,
        });

        await this._updateWorkflow(inspectionId, {
          perProductAqlAndSampling,
        });
      } else {
        if (!isNil(workflow.samplingSize) && 'aqlModificationReason' in workflow.samplingSize) {
          workflow.samplingSize.aqlModificationReason = reason;
        }

        await this._updateWorkflow(inspectionId, workflow);
      }
    } catch (error) {
      this._displayErrorNotification(error);
      throw error;
    }
  }

  public async saveInspectionImagesOrder(
    inspectionId: InspectionId,
    imageIds: string[],
    entity?: string,
    entityId?: string,
    purchaseOrderProductId?: number
  ): Promise<void> {
    let workflow: QimaOptionalType<IWorkflow> = await this.getWorkflow(inspectionId);

    if (workflow) {
      if (entity && entityId) {
        if (entity === EQpImageElementType.ANSWERS) {
          workflow = WorkflowUtils.reorderTestsPictures(workflow, entityId, imageIds);
        } else if (entity === EQpImageElementType.DEFECTS) {
          workflow = WorkflowUtils.reorderDefectsPictures(workflow, entityId, imageIds, purchaseOrderProductId);
        }
      } else {
        workflow.globalImages = WorkflowUtils.reorderPictures(workflow.globalImages, imageIds);
      }

      try {
        await this._updateWorkflow(inspectionId, workflow);
      } catch (error) {
        this._displayErrorNotification(error);
        throw error;
      }
    }
  }

  public async saveInspectionFile(
    id: InspectionId,
    formData: FormData,
    entity?: string,
    entityId?: string,
    isThumbnail?: boolean
  ): Promise<string> {
    const imageBlob: Blob = formData.get('image') as Blob;
    const dataBlob: Blob = formData.get('data') as Blob;
    // Cannot use "dataBlob.text()" or "imageBlob.arrayBuffer()" because they are not supported by older devices
    const readPromise = new Promise<string>((resolve, reject): void => {
      try {
        const reader = new FileReader();

        reader.onloadend = (): void => {
          resolve(reader.result as string);
        };

        reader.readAsText(dataBlob);
      } catch (e) {
        reject(e);
      }
    });
    const readFilePromise = new Promise<ArrayBuffer>((resolve, reject): void => {
      try {
        const fileReader = new FileReader();

        fileReader.onloadend = (): void => {
          resolve(fileReader.result as ArrayBuffer);
        };

        fileReader.readAsArrayBuffer(imageBlob);
      } catch (e) {
        reject(e);
      }
    });
    const data = JSON.parse(await readPromise);
    const fileAsArrayBuffer = await readFilePromise;
    const dbFile = await this.getFile(data.imageId);
    const inspectionId = typeof id === 'number' ? id : 0;
    const inspectionUuid = typeof id === 'string' ? id : undefined;

    if (dbFile) {
      try {
        await this._database.file.update(dbFile.id, {
          inspectionId,
          inspectionUuid,
          fileId: data.imageId,
          file: fileAsArrayBuffer,
          data,
          entity,
          entityId,
          isThumbnail: isThumbnail ? 'true' : null,
        });
      } catch (error) {
        this._displayErrorNotification(error, 'inspection.image-edition.error');
        throw error;
      }
    } else {
      try {
        await this._database.file.add({
          inspectionId,
          inspectionUuid,
          fileId: data.imageId,
          file: fileAsArrayBuffer,
          data,
          entity,
          entityId,
          isThumbnail: isThumbnail ? 'true' : null,
        });
      } catch (error) {
        this._displayErrorNotification(error);

        // When inspector deletes unsynced images from his browser, we have constraint error when he comes back and need to ignore it (PL-18670)
        return data.imageId;
      }
    }

    await this.addWorkflowImageId(inspectionId || inspectionUuid, data.imageId, data, entity, entityId);

    return data.imageId;
  }

  public async deleteFile(fileId: string): Promise<void> {
    await this._database.file.where('fileId').equals(fileId).delete();
  }

  public async saveInspectionAttachment(
    id: InspectionId,
    formData: FormData,
    attachmentId: string,
    entity?: string,
    entityId?: string
  ): Promise<void> {
    const attachmentBlob: Blob = formData.get('attachment') as Blob;
    const attachmentFile: File = formData.get('attachment') as File;
    const readFilePromise = new Promise<ArrayBuffer>((resolve, reject): void => {
      try {
        const fileReader = new FileReader();

        fileReader.onloadend = (): void => {
          resolve(fileReader.result as ArrayBuffer);
        };

        fileReader.readAsArrayBuffer(attachmentBlob);
      } catch (e) {
        reject(e);
      }
    });
    const fileAsArrayBuffer = await readFilePromise;
    const inspectionId = typeof id === 'number' ? id : 0;
    const inspectionUuid = typeof id === 'string' ? id : undefined;

    try {
      await this._database.file.add({
        inspectionId,
        inspectionUuid,
        fileId: attachmentId,
        file: fileAsArrayBuffer,
        filename: attachmentFile.name,
        mimeType: attachmentFile.type,
        entity,
        entityId,
      });
    } catch (error) {
      this._displayErrorNotification(error);
      throw error;
    }

    const attachmentData = {
      attachmentId,
      filename: attachmentFile.name,
    };

    await this.addWorkflowAttachment(inspectionId || inspectionUuid, attachmentData, entityId, entity);
  }

  public async deleteInspectionAttachment(
    inspectionId: InspectionId,
    attachmentId: string,
    entityId?: string,
    entity?: string
  ): Promise<void> {
    await this.removeWorkflowAttachment(inspectionId, attachmentId, entityId, entity);
  }

  public async saveInspectionFileData(
    inspectionId: InspectionId,
    data: { caption?: string; isSpotlight?: boolean },
    imageId?: string
  ): Promise<void> {
    const dbFile = await this.getFile(imageId);

    if (dbFile) {
      try {
        await this._database.file.update(dbFile.id, {
          inspectionId,
          fileId: imageId,
          data: { ...(dbFile.data || {}), ...data },
        });
      } catch (error) {
        this._displayErrorNotification(error);
        throw error;
      }
    }
  }

  public async saveSignDocument(id: InspectionId, fileId: string, formData: FormData): Promise<void> {
    const imageBlob: Blob = formData.get('file') as Blob;
    const readFilePromise = new Promise<ArrayBuffer>((resolve, reject): void => {
      try {
        const fileReader = new FileReader();

        fileReader.onloadend = (): void => {
          resolve(fileReader.result as ArrayBuffer);
        };

        fileReader.readAsArrayBuffer(imageBlob);
      } catch (e) {
        reject(e);
      }
    });
    const fileAsArrayBuffer = await readFilePromise;
    const data: IChecklistDocumentData = {
      type: imageBlob.type,
      size: imageBlob.size,
      name: null,
    };
    const inspectionId = typeof id === 'number' ? id : 0;
    const inspectionUuid = typeof id === 'string' ? id : undefined;

    try {
      await this._database.file.add({
        inspectionId,
        inspectionUuid,
        fileId,
        file: fileAsArrayBuffer,
        data,
      });
    } catch (error) {
      this._displayErrorNotification(error);
      throw error;
    }
  }

  public async saveFile(fileId: string, fileAsArrayBuffer: ArrayBuffer, data): Promise<void> {
    const dbFile = await this.getFile(fileId);

    if (!dbFile) {
      await this._database.file.add({
        inspectionId: 0,
        fileId,
        file: fileAsArrayBuffer,
        data,
      });
    } else {
      await this._database.file.update(dbFile.id, {
        inspectionId: 0,
        fileId,
        file: fileAsArrayBuffer,
        data: { ...dbFile.data, data },
      });
    }
  }

  public async saveFileMetada(fileId: string, data): Promise<void> {
    const dbFile = await this.getFile(fileId);

    if (dbFile) {
      await this._database.file.update(dbFile.id, {
        data,
      });
    } else {
      await this._database.file.add({
        inspectionId: null,
        fileId,
        file: null,
        data,
      });
    }
  }

  public async deleteInspections(ids: InspectionId[]): Promise<void> {
    const numbersId: QpIdType[] = this._filterNumberIds(ids);
    const uuidsId: QpUuidType[] = this._filterUuids(ids);

    await this._database.inspection.where('id').anyOf(numbersId).delete();
    await this._database.inspection.where('inspectionUuid').anyOf(uuidsId).delete();
  }

  public async deleteWorkflows(ids: InspectionId[]): Promise<void> {
    const numbersId: QpIdType[] = this._filterNumberIds(ids);
    const uuidsId: QpUuidType[] = this._filterUuids(ids);

    await this._database.workflow.where('id').anyOf(numbersId).delete();
    await this._database.workflow.where('inspectionUuid').anyOf(uuidsId).delete();
  }

  public async deleteInspectionsFiles(ids: InspectionId[]): Promise<void> {
    const numbersId: QpIdType[] = this._filterNumberIds(ids);
    const uuidsId: QpUuidType[] = this._filterUuids(ids);

    await this._database.file.where('inspectionId').anyOf(numbersId).delete();
    await this._database.file.where('inspectionUuid').anyOf(uuidsId).delete();
  }

  public async deleteInspectionFile(
    inspectionId: Readonly<InspectionId>,
    imageId: Readonly<QpUuidType>,
    entity?: string,
    entityId?: string
  ): Promise<number> {
    await this.deleteWorkflowImageId(inspectionId, imageId, entity, entityId);

    return this._database.file
      .where('inspectionId')
      .anyOf(inspectionId)
      .and((file: Readonly<InspectionFile>): boolean => file.fileId === imageId)
      .delete();
  }

  public async deleteSyncedActionRequests(delay: number): Promise<void> {
    await this._database.actionRequest
      .where('isSynced')
      .equals(1)
      .modify((value, ref): void => {
        if (this._qpDateService.howManyDaysAgo(value.syncDate) >= delay) {
          delete ref.value;
        }
      });
  }

  public async addWorkflowAttachment(inspectionId: InspectionId, data: IAttachmentData, entityId: string, entity: string): Promise<void> {
    let workflow: QimaOptionalType<IWorkflow> = await this.getWorkflow(inspectionId);

    if (workflow) {
      if (entity === EQpImageElementType.ANSWERS) {
        workflow = WorkflowUtils.addTestsAttachment(workflow, entityId, data);
      } else if (entity === EQpImageElementType.SIMPLIFIED_MEASUREMENTS) {
        workflow = WorkflowUtils.addSimplifiedMeasurementAttachment(workflow, data);
      }

      try {
        await this._updateWorkflow(inspectionId, workflow);
      } catch (error) {
        this._displayErrorNotification(error);
        throw error;
      }
    }
  }

  public async removeWorkflowAttachment(inspectionId: InspectionId, attachmentId: string, entityId: string, entity: string): Promise<void> {
    let workflow: QimaOptionalType<IWorkflow> = await this.getWorkflow(inspectionId);

    if (workflow) {
      if (entity === EQpImageElementType.ANSWERS) {
        workflow = WorkflowUtils.deleteTestAttachment(workflow, entityId, attachmentId);
      } else if (entity === EQpImageElementType.SIMPLIFIED_MEASUREMENTS) {
        workflow = WorkflowUtils.deleteSimplifiedMeasurementAttachment(workflow, attachmentId);
      }

      try {
        await this._updateWorkflow(inspectionId, workflow);
      } catch (error) {
        this._displayErrorNotification(error);
        throw error;
      }
    }
  }

  public async addWorkflowImageId(
    inspectionId: InspectionId,
    imageId: string,
    data: IChecklistImageData,
    entity?: string,
    entityId?: string
  ): Promise<void> {
    let workflow: QimaOptionalType<IWorkflow> = await this.getWorkflow(inspectionId);

    if (workflow) {
      if (entity && entityId) {
        if (entity === EQpImageElementType.ANSWERS) {
          workflow = WorkflowUtils.addTestPicture(workflow, entityId, data.imageId, data);
        } else if (entity === EQpImageElementType.SCANS) {
          workflow = WorkflowUtils.addTestScan(workflow, entityId, data.imageId, data);
        } else if (entity === EQpImageElementType.DEFECTS) {
          workflow = WorkflowUtils.addDefectsPicture(workflow, entityId, data.imageId, data);
        }
      } else {
        if (data.parentImage) {
          workflow.globalImages = workflow.globalImages.filter((img): boolean => img.imageId !== data.parentImage);
        }

        workflow.globalImages.push({ ...data, imageId });
      }

      try {
        await this._updateWorkflow(inspectionId, workflow);
      } catch (error) {
        this._displayErrorNotification(error);
        throw error;
      }
    }
  }

  public async deleteWorkflowImageId(inspectionId: InspectionId, imageId: string, entity: string, entityId: string): Promise<void> {
    let workflow: QimaOptionalType<IWorkflow> = await this.getWorkflow(inspectionId);

    if (workflow) {
      if (entity && entityId) {
        if (entity === EQpImageElementType.ANSWERS) {
          workflow = WorkflowUtils.deleteTestsPicture(workflow, entityId, imageId);
        } else if (entity === EQpImageElementType.DEFECTS) {
          workflow = WorkflowUtils.deleteDefectsPicture(workflow, entityId, imageId);
        } else if (entity === EQpImageElementType.SCANS) {
          workflow = WorkflowUtils.deleteTestScan(workflow, entityId, imageId);
        } else if (entity === EQpImageElementType.SIMPLIFIED_MEASUREMENTS) {
          workflow = WorkflowUtils.deleteSimplifiedMeasurementPicture(workflow, entityId, imageId);
        } else if (entity === EQpImageElementType.STARTING) {
          workflow = WorkflowUtils.deleteStartingPicture(workflow, entityId, imageId);
        }
      } else {
        const globalImageIndex: number = workflow.globalImages.findIndex(
          (image: Readonly<IChecklistImageData>): boolean => image?.imageId === imageId
        );

        if (globalImageIndex >= 0) {
          workflow.globalImages.splice(globalImageIndex, 1);
        }
      }

      await this._updateWorkflow(inspectionId, workflow);
    }
  }

  public async completeStartingPictures$(inspectionId: InspectionId, pathId: string): Promise<void> {
    const workflow: IWorkflow = await this.getWorkflow(inspectionId);

    try {
      const clonedWorkflowSteps: IWorkflowStep[] = cloneDeep(workflow.steps);

      clonedWorkflowSteps.forEach((step: IWorkflowStep): void => {
        forEach(step.actions, (action: IWorkflowAction): void => {
          if (action.id === pathId) {
            action.validatedByInspector = true;
          }
        });
      });

      const newWorkflow: IWorkflow = {
        ...workflow,
        steps: clonedWorkflowSteps,
      };

      await this._updateWorkflow(inspectionId, newWorkflow);
    } catch (error: unknown) {
      this._displayErrorNotification(error);
    }
  }

  public async completeReferenceSample$(inspectionId: InspectionId, stepId: number, actionId: number): Promise<void> {
    const workflow: IWorkflow = await this.getWorkflow(inspectionId);

    try {
      const clonedWorkflowSteps: IWorkflowStep[] = cloneDeep(workflow.steps);

      clonedWorkflowSteps.forEach((step: IWorkflowStep): void => {
        if (parseInt(step.id, 10) === stepId) {
          const actionPathId = `${stepId}-${actionId}`;

          forEach(step.actions, (action: IWorkflowAction): void => {
            if (action.id === actionPathId) {
              action.referenceSample.isCompleted = true;
            }
          });
        }
      });

      const newWorkflow: IWorkflow = {
        ...workflow,
        steps: clonedWorkflowSteps,
      };

      await this._updateWorkflow(inspectionId, newWorkflow);
    } catch (error: unknown) {
      this._displayErrorNotification(error);
    }
  }

  public async completeSampleCollection$(inspectionId: InspectionId, stepId: number, actionId: number): Promise<void> {
    const workflow: IWorkflow = await this.getWorkflow(inspectionId);

    try {
      const clonedWorkflowSteps: IWorkflowStep[] = cloneDeep(workflow.steps);

      clonedWorkflowSteps.forEach((step: IWorkflowStep): void => {
        if (parseInt(step.id, 10) === stepId) {
          const actionPathId = `${stepId}-${actionId}`;

          forEach(step.actions, (action: IWorkflowAction): void => {
            if (action.id === actionPathId) {
              action.sampleCollection.isCompleted = true;
            }
          });
        }
      });

      const newWorkflow: IWorkflow = {
        ...workflow,
        steps: clonedWorkflowSteps,
      };

      await this._updateWorkflow(inspectionId, newWorkflow);
    } catch (error: unknown) {
      this._displayErrorNotification(error);
    }
  }

  public async saveWorkflowActionCustomFieldsValue(
    inspectionId: InspectionId,
    fieldId: number,
    answer: CustomFieldValueDTO
  ): Promise<void> {
    try {
      const workflow = await this.getWorkflow(inspectionId);
      const ispWorkflow = new IspWorkflow(workflow);
      const customFieldsAction: QimaOptionalType<IspWorkflowStepActionCustomFields> = ispWorkflow.getCustomFieldsAction();
      const fieldToUpdate: QimaOptionalType<CustomFieldInspectionAnswerPairDTO> = customFieldsAction?.customFields.find(
        (f): boolean => f.customField.id === fieldId
      );

      if (fieldToUpdate) {
        fieldToUpdate.answer.value = answer.value;
      } else {
        throw new Error('Invalid custom field path');
      }

      await this.upsertWorkflow(workflow);
    } catch (error: unknown) {
      this._displayErrorNotification(error, 'inspection.validate.error.answer-error');
      throw error;
    }
  }

  // Add these methods to the DatabaseService class

  public async saveSimplifiedMeasures(
    inspectionId: InspectionId,
    simplifiedMeasures: SimplifiedMeasurementChecklistMeasuresDTO
  ): Promise<void> {
    const workflow: QimaOptionalType<IWorkflow> = await this.getWorkflow(inspectionId);

    if (workflow) {
      const clonedWorkflowSteps: IWorkflowStep[] = cloneDeep(workflow.steps);

      clonedWorkflowSteps.forEach((step: IWorkflowStep): void => {
        step.actions.forEach((action: IWorkflowAction): void => {
          if (action.type === EWorkflowActionType.SIMPLIFIED_MEASUREMENTS_CHECKLIST) {
            action.simplifiedMeasurement.measurement = simplifiedMeasures;
          }
        });
      });

      const newWorkflow: IWorkflow = {
        ...workflow,
        steps: clonedWorkflowSteps,
      };

      try {
        await this._updateWorkflow(inspectionId, newWorkflow);
      } catch (error) {
        this._displayErrorNotification(error);
        throw error;
      }
    }
  }

  public async saveSimplifiedMeasuresMarkedAsNotApplicable(
    inspectionId: InspectionId,
    status: SimplifiedMeasurementChecklistStatusDTO
  ): Promise<void> {
    const workflow: QimaOptionalType<IWorkflow> = await this.getWorkflow(inspectionId);

    if (workflow) {
      const clonedWorkflowSteps: IWorkflowStep[] = cloneDeep(workflow.steps);

      clonedWorkflowSteps.forEach((step: IWorkflowStep): void => {
        step.actions.forEach((action: IWorkflowAction): void => {
          if (action.type === EWorkflowActionType.SIMPLIFIED_MEASUREMENTS_CHECKLIST) {
            action.simplifiedMeasurement.notApplicable = status.notApplicable;
          }
        });
      });

      const newWorkflow: IWorkflow = {
        ...workflow,
        steps: clonedWorkflowSteps,
      };

      try {
        await this._updateWorkflow(inspectionId, newWorkflow);
      } catch (error) {
        this._displayErrorNotification(error);
        throw error;
      }
    }
  }

  public async saveSimplifiedMeasurementComment(inspectionId: InspectionId, comment: string): Promise<void> {
    const workflow: QimaOptionalType<IWorkflow> = await this.getWorkflow(inspectionId);

    if (workflow) {
      const clonedWorkflowSteps: IWorkflowStep[] = cloneDeep(workflow.steps);

      clonedWorkflowSteps.forEach((step: IWorkflowStep): void => {
        step.actions.forEach((action: IWorkflowAction): void => {
          if (action.type === EWorkflowActionType.SIMPLIFIED_MEASUREMENTS_CHECKLIST) {
            action.simplifiedMeasurement.comment = comment;
          }
        });
      });

      const newWorkflow: IWorkflow = {
        ...workflow,
        steps: clonedWorkflowSteps,
      };

      try {
        await this._updateWorkflow(inspectionId, newWorkflow);
      } catch (error) {
        this._displayErrorNotification(error);
        throw error;
      }
    }
  }

  public async setInspectionAsAborted(inspectionId: InspectionId, abortInspectionDTO: AbortInspectionDTO): Promise<void> {
    try {
      const workflow: QimaOptionalType<IWorkflow> = await this.getWorkflow(inspectionId);
      const inspection = await this.getInspection(inspectionId);

      if (workflow && inspection) {
        const newWorkflow: IWorkflow = {
          ...workflow,
          isAborted: abortInspectionDTO.isAborted,
        };

        await this._updateWorkflow(inspectionId, newWorkflow);
      }
    } catch (error) {
      this._displayErrorNotification(error);
      throw error;
    }
  }

  private async _getInspectionById(id: Readonly<QpIdType>): Promise<IInspectionConsultation | undefined> {
    if (isNil(this._database)) {
      throw new Error('Database is not initialized');
    }

    return await this._database?.inspection.where('id').equals(id).first();
  }

  private async _getInspectionByInspectonUuid(inspectionUuid: Readonly<QpUuidType>): Promise<IInspectionConsultation | undefined> {
    if (isNil(this._database)) {
      throw new Error('Database is not initialized');
    }

    return await this._database?.inspection.where('inspectionUuid').equals(inspectionUuid).first();
  }

  private async _updateInspection(id: InspectionId, inspection: Partial<IInspectionConsultation>): Promise<number> {
    if (isNil(this._database)) {
      throw new Error('Database is not initialized');
    }

    const request =
      typeof id === 'number'
        ? this._database?.inspection.where('id').equals(id)
        : this._database?.inspection.where('inspectionUuid').equals(id);

    return await request.modify(inspection);
  }

  private async _getWorkflowById(id: Readonly<number>): Promise<QimaOptionalType<IWorkflow>> {
    if (isNil(this._database)) {
      throw new Error('Database is not initialized');
    }

    return await this._database.workflow.where('id').equals(id).first();
  }

  private async _getWorkflowByInspectonUUID(inspectionUuid: Readonly<QpUuidType>): Promise<QimaOptionalType<IWorkflow>> {
    if (isNil(this._database)) {
      throw new Error('Database is not initialized');
    }

    return await this._database.workflow.where('inspectionUuid').equals(inspectionUuid).first();
  }

  private async _updateWorkflow(id: InspectionId, workflow: Partial<IWorkflow>): Promise<number> {
    if (isNil(this._database)) {
      throw new Error('Database is not initialized');
    }

    const request =
      typeof id === 'number'
        ? this._database?.workflow.where('id').equals(id)
        : this._database?.workflow.where('inspectionUuid').equals(id);

    return await request.modify(workflow);
  }

  private _filterUuids(ids: InspectionId[]): QpUuidType[] {
    return ids.filter((id: InspectionId): boolean => typeof id === 'string') as QpUuidType[];
  }

  private _filterNumberIds(ids: InspectionId[]): QpIdType[] {
    return ids.filter((id: InspectionId): boolean => typeof id === 'number') as QpIdType[];
  }
}

import { InjectionToken } from '@angular/core';
export const QIMA_DATE_CONFIG_TOKEN = new InjectionToken('The default values used by the date features', {
  factory() {
    /**
     * The fallback values should be identical as the fallback [provider]{@link QimaDateModule#forRoot} values
     */
    return {
      format: 'L'
    };
  }
});

export const Abs = 'Abs';
export const Acos = 'Acos';
export const Acosh = 'Acosh';
export const Add = 'Add';
export const AddN = 'AddN';
export const All = 'All';
export const Any = 'Any';
export const ArgMax = 'ArgMax';
export const ArgMin = 'ArgMin';
export const Asin = 'Asin';
export const Asinh = 'Asinh';
export const Atan = 'Atan';
export const Atanh = 'Atanh';
export const Atan2 = 'Atan2';
export const AvgPool = 'AvgPool';
export const AvgPoolGrad = 'AvgPoolGrad';
export const AvgPool3D = 'AvgPool3D';
export const AvgPool3DGrad = 'AvgPool3DGrad';
export const BatchMatMul = 'BatchMatMul';
export const BatchToSpaceND = 'BatchToSpaceND';
export const Bincount = 'Bincount';
export const BroadcastTo = 'BroadcastTo';
export const Cast = 'Cast';
export const Ceil = 'Ceil';
export const ClipByValue = 'ClipByValue';
export const Complex = 'Complex';
export const ComplexAbs = 'ComplexAbs';
export const Concat = 'Concat';
export const Conv2D = 'Conv2D';
export const Conv2DBackpropFilter = 'Conv2DBackpropFilter';
export const Conv2DBackpropInput = 'Conv2DBackpropInput';
export const Conv3D = 'Conv3D';
export const Conv3DBackpropFilterV2 = 'Conv3DBackpropFilterV2';
export const Conv3DBackpropInputV2 = 'Conv3DBackpropInputV2';
export const Cos = 'Cos';
export const Cosh = 'Cosh';
export const Cumsum = 'Cumsum';
export const CropAndResize = 'CropAndResize';
export const DenseBincount = 'DenseBincount';
export const DepthToSpace = 'DepthToSpace';
export const DepthwiseConv2dNative = 'DepthwiseConv2dNative';
export const DepthwiseConv2dNativeBackpropFilter = 'DepthwiseConv2dNativeBackpropFilter';
export const DepthwiseConv2dNativeBackpropInput = 'DepthwiseConv2dNativeBackpropInput';
export const Diag = 'Diag';
export const Dilation2D = 'Dilation2D';
export const Dilation2DBackpropInput = 'Dilation2DBackpropInput';
export const Dilation2DBackpropFilter = 'Dilation2DBackpropFilter';
export const RealDiv = 'RealDiv';
export const Einsum = 'Einsum';
export const Elu = 'Elu';
export const EluGrad = 'EluGrad';
export const Erf = 'Erf';
export const Equal = 'Equal';
export const Exp = 'Exp';
export const ExpandDims = 'ExpandDims';
export const Expm1 = 'Expm1';
export const FFT = 'FFT';
export const Fill = 'Fill';
export const FlipLeftRight = 'FlipLeftRight';
export const Floor = 'Floor';
export const FloorDiv = 'FloorDiv';
export const FusedBatchNorm = 'FusedBatchNorm';
export const GatherV2 = 'GatherV2';
export const GatherNd = 'GatherNd';
export const Greater = 'Greater';
export const GreaterEqual = 'GreaterEqual';
export const Identity = 'Identity';
export const IFFT = 'IFFT';
export const Imag = 'Imag';
export const IsFinite = 'IsFinite';
export const IsInf = 'IsInf';
export const IsNan = 'IsNan';
export const LeakyRelu = 'LeakyRelu';
export const Less = 'Less';
export const LessEqual = 'LessEqual';
export const LinSpace = 'LinSpace';
export const Log = 'Log';
export const Log1p = 'Log1p';
export const LogicalAnd = 'LogicalAnd';
export const LogicalNot = 'LogicalNot';
export const LogicalOr = 'LogicalOr';
export const LogSoftmax = 'LogSoftmax';
export const LRN = 'LRN';
export const LRNGrad = 'LRNGrad';
export const Max = 'Max';
export const Maximum = 'Maximum';
export const MaxPool = 'MaxPool';
export const MaxPoolGrad = 'MaxPoolGrad';
export const MaxPool3D = 'MaxPool3D';
export const MaxPool3DGrad = 'MaxPool3DGrad';
export const MaxPoolWithArgmax = 'MaxPoolWithArgmax';
export const Mean = 'Mean';
export const Min = 'Min';
export const Minimum = 'Minimum';
export const MirrorPad = 'MirrorPad';
export const Mod = 'Mod';
export const Multinomial = 'Multinomial';
export const Multiply = 'Multiply';
export const Neg = 'Neg';
export const NotEqual = 'NotEqual';
export const NonMaxSuppressionV3 = 'NonMaxSuppressionV3';
export const NonMaxSuppressionV4 = 'NonMaxSuppressionV4';
export const NonMaxSuppressionV5 = 'NonMaxSuppressionV5';
export const OnesLike = 'OnesLike';
export const OneHot = 'OneHot';
export const Pack = 'Pack';
export const PadV2 = 'PadV2';
export const Pool = 'Pool';
export const Pow = 'Pow';
export const Prelu = 'Prelu';
export const Prod = 'Prod';
export const Range = 'Range';
export const Real = 'Real';
export const Reciprocal = 'Reciprocal';
export const Relu = 'Relu';
export const Reshape = 'Reshape';
export const ResizeNearestNeighbor = 'ResizeNearestNeighbor';
export const ResizeNearestNeighborGrad = 'ResizeNearestNeighborGrad';
export const ResizeBilinear = 'ResizeBilinear';
export const ResizeBilinearGrad = 'ResizeBilinearGrad';
export const Relu6 = 'Relu6';
export const Reverse = 'Reverse';
export const Round = 'Round';
export const Rsqrt = 'Rsqrt';
export const ScatterNd = 'ScatterNd';
export const Select = 'Select';
export const Selu = 'Selu';
export const Slice = 'Slice';
export const Sin = 'Sin';
export const Sinh = 'Sinh';
export const Sign = 'Sign';
export const Sigmoid = 'Sigmoid';
export const Softplus = 'Softplus';
export const Sqrt = 'Sqrt';
export const Sum = 'Sum';
export const SpaceToBatchND = 'SpaceToBatchND';
export const SplitV = 'SplitV';
export const Softmax = 'Softmax';
export const SparseReshape = 'SparseReshape';
export const SparseToDense = 'SparseToDense';
export const SquaredDifference = 'SquaredDifference';
export const Square = 'Square';
export const StridedSlice = 'StridedSlice';
export const Sub = 'Sub';
export const Tan = 'Tan';
export const Tanh = 'Tanh';
export const Tile = 'Tile';
export const TopK = 'TopK';
export const Transform = 'Transform';
export const Transpose = 'Transpose';
export const Unique = 'Unique';
export const Unpack = 'Unpack';
export const UnsortedSegmentSum = 'UnsortedSegmentSum';
export const ZerosLike = 'ZerosLike';
/**
 * TensorFlow.js-only kernels
 */
export const Step = 'Step';
export const FromPixels = 'FromPixels';
export const RotateWithOffset = 'RotateWithOffset';
export const _FusedMatMul = '_FusedMatMul';
export const FusedConv2D = 'FusedConv2D';
export const FusedDepthwiseConv2D = 'FusedDepthwiseConv2D';

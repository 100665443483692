// @ts-strict-ignore
import { QpAql } from '@library/classes/qp-aql/qp-aql';
import { SimplifiedMeasurementChecklistMeasuresDTO } from '@library/dto/checklist/measure/simplified/simplified-measurement-checklist-measures.dto';
import { SimplifiedMeasurementChecklistStatusDTO } from '@library/dto/checklist/measure/simplified/simplified-measurement-checklist-status.dto';
import { CompleteDefectsChecklistDTO } from '@library/dto/complete-defects-checklist.dto';
import { AbortInspectionDTO } from '@library/dto/inspection/abort-inspection.dto';
import { CreateInspectionDTO } from '@library/dto/inspection/create-inspection.dto';
import { CustomFieldValueDTO } from '@library/dto/workflow/custom-fields.dto';
import { WorkflowTemplateGetDTO } from '@library/dto/workflow/workflow-template.dto';
import { CartonCalculationMethod } from '@library/dto-enums/carton-calculation-method.dto-enum';
import { COMPLETION_STATUSES, InspectionStatus } from '@library/dto-enums/inspection-status.dto-enum';
import { qpAssert } from '@library/functions/checks/qp-assert';
import { qpGetIgnoredInterceptorStatusCodes } from '@library/functions/qp-get-ignored-interceptor-status-codes';
import { EQpHttpStatusCode } from '@library/models/qp-http.models';
import { EQpImageElementType } from '@library/models/qp-image.models';
import { QpLoggerService } from '@library/services/qp-logger/qp-logger.service';
import { NetworkStatusService } from '@library/services/qp-network-status/qp-network-status.service';
import { QpQueryParamsService } from '@library/services/qp-query-params/qp-query-params.service';
import { SERVER_API_URL } from '@one/app/app.constants';
import { loadWorkflowSuccess } from '@one/app/pages/isp/pages/inspection/pages/id/shared/services/store/isp-inspection-id-workflow-store.actions';
import WorkflowUtils from '@one/app/pages/isp/pages/inspection/pages/id/shared/services/workflow.utils';
import { InspectionConsultationMapper } from '@one/app/pages/isp/shared/mappers/inspection-consultation.mapper';
import { InspectionWorkflowMapper } from '@one/app/pages/isp/shared/mappers/inspection-workflow.mapper';
import { InspectionId } from '@one/app/pages/isp/shared/models/isp-inspection.models';
import { InspectionComment } from '@one/app/shared/classes/inspection/inspection-comment';
import { InspectionConsultation } from '@one/app/shared/classes/inspection-consultation/inspection-consultation';
import { EHttpMethod } from '@one/app/shared/models/action-request.models';
import { AqlDefectsType } from '@one/app/shared/models/defects/aql-defects.models';
import { EHttpQimaEndpoints, HTTP_QIMA_ENDPOINT_NAME, IHttpGetSamplingSizesQueryParams } from '@one/app/shared/models/http/http.models';
import {
  IChecklistComment,
  IChecklistDocumentData,
  IChecklistImageData,
  IInspectionConsultation,
  InspectionStatusDTO,
} from '@one/app/shared/models/inspection-consultation/inspection-consultation.models';
import {
  ESamplingSizeTypes,
  ISamplingSize,
  IWorkflowSamplingSize,
  WorkflowSamplingSizeType,
} from '@one/app/shared/models/sampling/sampling-size.models';
import {
  INewDefectInfo,
  IProductActualQuantities,
  IWorkflow,
  IWorkflowCheck,
  IWorkflowInspectionFindingsSummaryReview,
  WorkflowCheckType,
} from '@one/app/shared/models/workflow/workflow.models';
import { AccountService } from '@one/app/shared/services/account/account.service';
import { ActionsQueueService } from '@one/app/shared/services/actions-queue/actions-queue.service';
import { CartonsService } from '@one/app/shared/services/cartons/cartons.service';
import { DatabaseDataService } from '@one/app/shared/services/database/database-data.service';
import {
  ISaveProductPictureAction,
  ISaveTestChecklistAnswerData,
  UpdateDefectCommentDataType,
  UpdateDefectQuantityDataType,
} from '@one/app/shared/services/database/database-inspection.models';
import { DatabaseService } from '@one/app/shared/services/database/database-inspection.service';
import {
  IAddCustomMeasurementProductSampleBody,
  ISaveMeasureData,
} from '@one/app/shared/services/measurements/product-measurements.models';
import { IStoreState } from '@one/app/store/store.models';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpEventType,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { QimaOptionalType } from '@qima/ngx-qima';
import { fromBase64, toBase64 } from 'js-base64';
import { isNil, parseInt } from 'lodash/index';
import { forkJoin, from, Observable, of, switchMap, throwError } from 'rxjs';
import { catchError, flatMap, map, mapTo, tap } from 'rxjs/operators';

export const INTERCEPTOR_SKIP_HEADER = 'X-Skip-Interceptor';

/**
 * @description
 * Not provided in root on purpose
 */
@Injectable()
export class InspectionInterceptor implements HttpInterceptor {
  public static readonly resourceUrl = `${SERVER_API_URL}api/inspections`;
  public static readonly productResourceUrl = `${SERVER_API_URL}api/products`;
  public static readonly cleanResourceUrl = InspectionInterceptor.resourceUrl.replace('/', '\\/');
  public static readonly cleanProductResourceUrl = InspectionInterceptor.productResourceUrl.replace('/', '\\/');
  public static POST_INSPECTION_REGEX = RegExp(`${InspectionInterceptor.resourceUrl}$`);

  public static INSPECTION_ID_REGEX_STRING = `((?<id>\\d+)|(?<uuid>[a-f\\d]{8}-([a-f\\d]{4}-){3}[a-f\\d]{12}))`;

  // URL regex for the GET resource on inspection
  public static GET_INSPECTION_REGEX = RegExp(`^${InspectionInterceptor.resourceUrl}/${InspectionInterceptor.INSPECTION_ID_REGEX_STRING}$`);

  // URL regex for the PATCH inspection status resource on inspection
  public static PATCH_INSPECTION_STATUS_REGEX = RegExp(
    `^${InspectionInterceptor.resourceUrl}\\/${InspectionInterceptor.INSPECTION_ID_REGEX_STRING}\\/status$`
  );

  public static GET_WORKFLOW_REGEX = RegExp(
    `${InspectionInterceptor.resourceUrl}/${InspectionInterceptor.INSPECTION_ID_REGEX_STRING}/workflow$`
  );

  public static PATCH_WORKFLOW_REGEX = InspectionInterceptor.GET_WORKFLOW_REGEX;

  public static PUT_WORKFLOW_CHECK_REGEX = RegExp(
    `${InspectionInterceptor.resourceUrl}\\/${InspectionInterceptor.INSPECTION_ID_REGEX_STRING}\\/workflow\\/checks\\/(?<check>[A-Z\\_]+)`
  );

  public static SITE_LOCATION_REGEX = RegExp(
    `${InspectionInterceptor.resourceUrl}\\/${InspectionInterceptor.INSPECTION_ID_REGEX_STRING}\\/workflow/site`
  );

  public static DELETE_TEST_CHECKLIST_TABLE_ROW_REGEX = RegExp(
    `${InspectionInterceptor.resourceUrl}\\/${InspectionInterceptor.INSPECTION_ID_REGEX_STRING}\\/workflow\\/table-response\\/(?<tableResponseId>[0-9\\-]+)\\/answer\\/(?<answerId>[0-9\\-]+)`
  );

  public static COMMENT_TEST_REGEX = RegExp(
    `${InspectionInterceptor.resourceUrl}\\/${InspectionInterceptor.INSPECTION_ID_REGEX_STRING}\\/answers\\/(?<answerId>[0-9\\-]+)\\/comment`
  );

  public static MARK_AS_NOT_APPLICABLE_REGEX = RegExp(
    `${InspectionInterceptor.resourceUrl}\\/${InspectionInterceptor.INSPECTION_ID_REGEX_STRING}\\/answers\\/(?<answerId>[0-9\\-]+)\\/status`
  );

  public static PATCH_SAMPLING_SIZE_REGEX = RegExp(
    `^${InspectionInterceptor.resourceUrl}\\/${InspectionInterceptor.INSPECTION_ID_REGEX_STRING}\\/sampling-sizes$`
  );

  public static PUT_ACTUAL_QUANTITIES_REGEX = RegExp(
    `${InspectionInterceptor.resourceUrl}\\/${InspectionInterceptor.INSPECTION_ID_REGEX_STRING}\\/workflow/actual-quantity$`
  );

  public static GET_CARTONS_TO_PICK = RegExp(`${InspectionInterceptor.resourceUrl}\\/cartons\\/(?<totalCartons>\\d+)`);
  public static PATCH_CARTONS_TO_PICK = RegExp(
    `${InspectionInterceptor.resourceUrl}\\/${InspectionInterceptor.INSPECTION_ID_REGEX_STRING}\\/cartons`
  );

  public static PATCH_GLOBAL_REMARKS = RegExp(
    `${InspectionInterceptor.resourceUrl}\\/${InspectionInterceptor.INSPECTION_ID_REGEX_STRING}\\/comment`
  );

  public static POST_GLOBAL_IMAGE_REGEX = RegExp(
    `${InspectionInterceptor.resourceUrl}\\/${InspectionInterceptor.INSPECTION_ID_REGEX_STRING}\\/images`
  );

  public static GET_GLOBAL_IMAGE_REGEX = RegExp(
    `${InspectionInterceptor.resourceUrl}\\/${InspectionInterceptor.INSPECTION_ID_REGEX_STRING}\\/images\\/PHOTO\\/(?<imageId>[a-z0-9\\-]+)`
  );

  public static DELETE_ACTION_IMAGE_REGEX = RegExp(
    `${InspectionInterceptor.resourceUrl}\\/${InspectionInterceptor.INSPECTION_ID_REGEX_STRING}\\/(?<element>answers|defects|scans|starting|simplified_measurement)\\/(?<elementId>[0-9\\-]+)\\/images`
  );

  public static DELETE_GLOBAL_IMAGE_REGEX = RegExp(
    `${InspectionInterceptor.resourceUrl}\\/${InspectionInterceptor.INSPECTION_ID_REGEX_STRING}\\/images$`
  );

  public static GET_ENTITY_IMAGE_REGEX = RegExp(
    `${InspectionInterceptor.resourceUrl}\\/${InspectionInterceptor.INSPECTION_ID_REGEX_STRING}\\/(?<entity>[a-z_]+)\\/(?<entityId>[0-9\\-]+)\\/images\\/(?<imageId>[a-z0-9\\-]+)`
  );

  public static POST_ENTITY_IMAGE_REGEX = RegExp(
    `${InspectionInterceptor.resourceUrl}\\/${InspectionInterceptor.INSPECTION_ID_REGEX_STRING}\\/(?<entity>[a-z_]+)\\/(?<entityId>[0-9\\-]+)\\/images[^\\/]*$`
  );

  public static PATCH_IMAGES_REGEX = RegExp(
    `${InspectionInterceptor.resourceUrl}\\/${InspectionInterceptor.INSPECTION_ID_REGEX_STRING}\\/(?<entity>[a-z_]+)\\/(?<entityId>[0-9\\-]+)\\/images/bulk$`
  );

  public static PATCH_GLOBAL_IMAGES_REGEX = RegExp(
    `${InspectionInterceptor.resourceUrl}\\/${InspectionInterceptor.INSPECTION_ID_REGEX_STRING}\\/images/bulk$`
  );

  public static PATCH_IMAGE_REGEX = RegExp(
    `${InspectionInterceptor.resourceUrl}\\/${InspectionInterceptor.INSPECTION_ID_REGEX_STRING}\\/(?<entity>[a-z_]+)\\/(?<entityId>[0-9\\-]+)\\/images\\/(?<imageId>[a-z0-9\\-]+)`
  );

  public static PATCH_GLOBAL_IMAGE_REGEX = RegExp(
    `${InspectionInterceptor.resourceUrl}\\/${InspectionInterceptor.INSPECTION_ID_REGEX_STRING}\\/images\\/(?<imageId>[a-z0-9\\-]+)`
  );

  public static PUT_ENTITY_IMAGES_ORDER_REGEX = RegExp(
    `${InspectionInterceptor.resourceUrl}\\/${InspectionInterceptor.INSPECTION_ID_REGEX_STRING}\\/(?<entity>[a-z_]+)\\/(?<entityId>[0-9\\-]+)\\/images\\/order`
  );

  public static PUT_GLOBAL_IMAGES_ORDER_REGEX = RegExp(
    `${InspectionInterceptor.resourceUrl}\\/${InspectionInterceptor.INSPECTION_ID_REGEX_STRING}\\/images\\/order`
  );

  public static PUT_PRODUCT_PICTURE_REGEX = RegExp(
    `${InspectionInterceptor.cleanResourceUrl}\\/${InspectionInterceptor.INSPECTION_ID_REGEX_STRING}\\/product-picture`
  );

  public static POST_ATTACHMENT_REGEX = RegExp(
    `${InspectionInterceptor.resourceUrl}\\/${InspectionInterceptor.INSPECTION_ID_REGEX_STRING}\\/(?<entity>[a-z_]+)\\/(?<entityId>[0-9\\-]+)\\/attachments[^\\/]*$`
  );

  public static DELETE_ATTACHMENT_REGEX = RegExp(
    `${InspectionInterceptor.resourceUrl}\\/${InspectionInterceptor.INSPECTION_ID_REGEX_STRING}\\/(?<entity>[a-z_]+)\\/(?<entityId>[0-9\\-]+)\\/attachments\\/(?<attachmentId>[a-z0-9\\-]+)`
  );

  public static readonly POST_SIMPLIFIED_MEASUREMENT_ATTACHMENTS_REGEX = RegExp(
    `${InspectionInterceptor.resourceUrl}\\/${InspectionInterceptor.INSPECTION_ID_REGEX_STRING}\\/simplified-measurement\\/attachments`
  );

  public static readonly DELETE_SIMPLIFIED_MEASUREMENT_ATTACHMENTS_REGEX = RegExp(
    `${InspectionInterceptor.resourceUrl}\\/${InspectionInterceptor.INSPECTION_ID_REGEX_STRING}\\/simplified-measurement\\/attachments\\/(?<attachmentId>[0-9\\-]+)`
  );

  public static PUT_DEFECT_QUANTITY = RegExp(
    `${InspectionInterceptor.resourceUrl}\\/${InspectionInterceptor.INSPECTION_ID_REGEX_STRING}\\/defects\\/(?<defectId>[0-9\\-]+)$`
  );

  public static PATCH_DEFECT_COMMENT = RegExp(
    `${InspectionInterceptor.resourceUrl}\\/${InspectionInterceptor.INSPECTION_ID_REGEX_STRING}\\/defects\\/(?<defectId>[0-9\\-]+)\\/comment`
  );

  public static POST_DEFECT = RegExp(
    `${InspectionInterceptor.resourceUrl}\\/${InspectionInterceptor.INSPECTION_ID_REGEX_STRING}\\/defects$`
  );

  public static PUT_COMPLETE_DEFECTS_CHECKLIST = RegExp(
    `${InspectionInterceptor.resourceUrl}\\/${InspectionInterceptor.INSPECTION_ID_REGEX_STRING}\\/complete-defects-checklist/steps\\/(?<stepId>[0-9\\-]+)\\/actions\\/(?<actionId>[0-9\\-]+)`
  );

  public static GET_DOCUMENT_REGEX = RegExp(
    `${InspectionInterceptor.cleanResourceUrl}\\/${InspectionInterceptor.INSPECTION_ID_REGEX_STRING}\\/documents\\/(?<documentId>[a-z0-9\\-]+)`
  );

  public static GET_PHOTO_REGEX = RegExp(
    `${InspectionInterceptor.cleanProductResourceUrl}\\/${InspectionInterceptor.INSPECTION_ID_REGEX_STRING}\\/photos\\/(?<photoId>[a-z0-9\\-]+)`
  );

  public static POST_DOCUMENT_REGEX = RegExp(
    `${InspectionInterceptor.cleanResourceUrl}\\/${InspectionInterceptor.INSPECTION_ID_REGEX_STRING}\\/documents`
  );

  public static GET_DOCUMENT_METADATA_REGEX = RegExp(
    `${InspectionInterceptor.cleanResourceUrl}\\/${InspectionInterceptor.INSPECTION_ID_REGEX_STRING}\\/documents\\/(?<documentId>[a-z0-9\\-]+)/metadata`
  );

  public static PUT_COMPLETE_REFERENCE_SAMPLE = RegExp(
    `${InspectionInterceptor.resourceUrl}\\/${InspectionInterceptor.INSPECTION_ID_REGEX_STRING}\\/complete-reference-sample/steps\\/(?<stepId>[0-9\\-]+)\\/actions\\/(?<actionId>[0-9\\-]+)`
  );

  public static PUT_COMPLETE_STARTING_PICTURES = RegExp(
    `${InspectionInterceptor.resourceUrl}\\/${InspectionInterceptor.INSPECTION_ID_REGEX_STRING}\\/starting-pictures\\/(?<pathId>[0-9\\-]+)\\/validation`
  );

  public static PUT_COMPLETE_SAMPLE_COLLECTION = RegExp(
    `${InspectionInterceptor.resourceUrl}\\/${InspectionInterceptor.INSPECTION_ID_REGEX_STRING}\\/complete-sample-collection/steps\\/(?<stepId>[0-9\\-]+)\\/actions\\/(?<actionId>[0-9\\-]+)`
  );

  public static readonly PATCH_ABORT_INSPECTION = RegExp(
    `${InspectionInterceptor.resourceUrl}\\/${InspectionInterceptor.INSPECTION_ID_REGEX_STRING}\\/abort`
  );

  private static readonly PUT_AQL_DEFECTS = RegExp(
    `${InspectionInterceptor.resourceUrl}\\/${InspectionInterceptor.INSPECTION_ID_REGEX_STRING}\\/aql`
  );

  private static readonly PUT_FINDINGS_SUMMARY_REVIEW = RegExp(
    `${InspectionInterceptor.resourceUrl}\\/${InspectionInterceptor.INSPECTION_ID_REGEX_STRING}\\/findings-summary-review`
  );

  private static readonly PATCH_INSPECTION_RESULT = RegExp(
    `${InspectionInterceptor.resourceUrl}\\/${InspectionInterceptor.INSPECTION_ID_REGEX_STRING}\\/result`
  );

  private static readonly PUT_SIMPLIFIED_MEASURES_REGEX = RegExp(
    `${InspectionInterceptor.resourceUrl}\\/${InspectionInterceptor.INSPECTION_ID_REGEX_STRING}\\/simplified-measurement\\/measurement`
  );

  private static readonly PUT_SIMPLIFIED_MEASURES_STATUS_REGEX = RegExp(
    `${InspectionInterceptor.resourceUrl}\\/${InspectionInterceptor.INSPECTION_ID_REGEX_STRING}\\/simplified-measurement\\/status`
  );

  private static readonly PUT_SIMPLIFIED_MEASUREMENT_COMMENT_REGEX = RegExp(
    `${InspectionInterceptor.resourceUrl}\\/${InspectionInterceptor.INSPECTION_ID_REGEX_STRING}\\/simplified-measurement\\/comment`
  );

  private readonly PUT_MEASURE = RegExp(
    `${InspectionInterceptor.cleanResourceUrl}\\/${InspectionInterceptor.INSPECTION_ID_REGEX_STRING}\\/measures\\/(?<measureId>[0-9\\-]+)`
  );

  private readonly POST_CUSTOM_MEASUREMENT_PRODUCT_SAMPLE = RegExp(
    `${InspectionInterceptor.cleanResourceUrl}\\/${InspectionInterceptor.INSPECTION_ID_REGEX_STRING}\\/measures\\/(?<varianceId>[0-9\\-]+)`
  );

  private readonly PATCH_CUSTOM_FIELD_REGEX = RegExp(
    `${InspectionInterceptor.resourceUrl}\\/${InspectionInterceptor.INSPECTION_ID_REGEX_STRING}\\/custom-fields\\/(?<fieldId>[0-9\\-]+)`
  );

  private readonly PUT_AQL_REASON_FOR_CHANGES = RegExp(
    `${InspectionInterceptor.resourceUrl}\\/${InspectionInterceptor.INSPECTION_ID_REGEX_STRING}\\/aql\\/reason`
  );

  public constructor(
    private readonly _accountService: AccountService,
    private readonly _databaseService: DatabaseService,
    private readonly _actionsQueueService: ActionsQueueService,
    private readonly _cartonsService: CartonsService,
    private readonly _store: Store<IStoreState>,
    private readonly _networkStatusService: NetworkStatusService,
    private readonly _qpLoggerService: QpLoggerService,
    private readonly _qpQueryParamsService: QpQueryParamsService,
    private readonly _inspectionConsultationMapper: InspectionConsultationMapper,
    private readonly _inspectionWorkflowMapper: InspectionWorkflowMapper,
    private readonly _databaseDataService: DatabaseDataService
  ) {}

  /**
   * @todo replace every single request.url to request.urlWithParams
   * @param {HttpRequest<unknown>} request the request
   * @param {HttpHandler} next the next handler
   * @returns {Observable<HttpEvent<any>>} the observable
   */
  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!this._accountService.isInspector() || request.headers.has(INTERCEPTOR_SKIP_HEADER)) {
      return next.handle(request);
    }

    if (request.method === 'POST' && InspectionInterceptor.POST_INSPECTION_REGEX.test(request.url)) {
      // In case of online inspection creation, we don't want to have specific behavior in the interceptor
      if (this._networkStatusService.isOnline) {
        return next.handle(request);
      }

      this._actionsQueueService.addAction(request);
      const createInspectionDTO = request.body as CreateInspectionDTO;

      return from(
        this._databaseService.upsertInspection(this._inspectionConsultationMapper.fromCreateInspectionDTO(createInspectionDTO))
      ).pipe(
        switchMap((inspection: IInspectionConsultation): Promise<HttpResponse<InspectionConsultation>> => {
          qpAssert(!isNil(createInspectionDTO.snapshotContent), '🚨 Snapshot content is required to create an inspection in offline mode');
          qpAssert(!isNil(createInspectionDTO.inspectionUuid), '🚨 Inspection uuid is required to create an inspection in offline mode');

          return this._databaseDataService
            .getWorkflowTemplateById(inspection.workflowDetails.id)
            .then((workflowTemplate: WorkflowTemplateGetDTO): Promise<IWorkflow> => {
              return this._databaseService.upsertWorkflow(
                this._inspectionWorkflowMapper.fromWorkflowSnapshotDTO(
                  createInspectionDTO.inspectionUuid,
                  createInspectionDTO.snapshotContent.workflow,
                  workflowTemplate.settings?.sumRatingConfig ?? undefined
                )
              );
            })
            .then(
              (_: IWorkflow): HttpResponse<InspectionConsultation> =>
                new HttpResponse({
                  status: EQpHttpStatusCode.OK,
                  body: InspectionConsultation.createFromInterface(inspection),
                })
            );
        })
      );
    } else if (request.method === 'GET' && InspectionInterceptor.GET_INSPECTION_REGEX.test(request.url)) {
      const groups = InspectionInterceptor.GET_INSPECTION_REGEX.exec(request.url).groups;
      const id: InspectionId = groups.id ? +groups.id : groups.uuid;

      return from(this._databaseService.hasPendingRequests(id)).pipe(
        switchMap(
          (hasPendingRequest: boolean): Observable<HttpEvent<unknown> | HttpResponse<unknown>> =>
            this.fetchData$(
              request,
              next,
              (httpResponse): Promise<IInspectionConsultation> =>
                this._databaseService.upsertInspection({
                  id: +groups.id || 0,
                  inspectionUuid: groups.uuid ?? undefined,
                  ...(httpResponse as HttpResponse<IInspectionConsultation>).body,
                }),
              (): Promise<{ body: IInspectionConsultation }> =>
                this._databaseService.getInspection(id).then((inspection): { body: IInspectionConsultation } => {
                  return { body: inspection };
                }),
              !hasPendingRequest
            )
        )
      );
    } else if (this._isUpdateInspectionStatusRequest(request)) {
      const groups = InspectionInterceptor.PATCH_INSPECTION_STATUS_REGEX.exec(request.url).groups;
      const id: InspectionId = groups.id ? +groups.id : groups.uuid;

      this._databaseService.updateInspectionStatus(id, request.body.status);

      if (![InspectionStatus.STARTED, ...COMPLETION_STATUSES].includes(request.body.status)) {
        return next.handle(request);
      }

      this._actionsQueueService.addAction(request);

      return of(
        new HttpResponse({
          status: EQpHttpStatusCode.OK,
        })
      );
    } else if (request.headers.get(HTTP_QIMA_ENDPOINT_NAME) === EHttpQimaEndpoints.GET_SAMPLING_SIZES) {
      return of(this.getSamplingSize(request as HttpRequest<void>));
    } else if (request.method === 'GET' && InspectionInterceptor.GET_WORKFLOW_REGEX.test(request.url)) {
      const groups = InspectionInterceptor.GET_WORKFLOW_REGEX.exec(request.url).groups;
      const id: InspectionId = groups.id ? +groups.id : groups.uuid;

      return from(this._databaseService.hasPendingRequests(id)).pipe(
        tap((hasPendingRequest: boolean): void => {
          this._qpLoggerService.info(
            `Inspection interceptor => The fetch workflow will be retrieved using 'isAPIFirst' = ${!hasPendingRequest}`
          );
        }),
        switchMap(
          (hasPendingRequest: boolean): Observable<HttpEvent<unknown> | HttpResponse<unknown>> =>
            this.fetchData$(
              request,
              next,
              (httpResponse): Promise<QimaOptionalType<IWorkflow>> => {
                // On backend response we force the workflow dispatch to prevent unsync during the offline migration
                const httpResponseBody = (httpResponse as HttpResponse<QimaOptionalType<IWorkflow>>).body;
                const workflow: QimaOptionalType<IWorkflow> = httpResponseBody
                  ? WorkflowUtils.addSectionToWorkflowMeasurementChecklist({
                      id: groups.id ? +groups.id : 0,
                      inspectionUuid: groups.uuid ?? undefined,
                      ...httpResponseBody,
                    })
                  : undefined;

                if (workflow) {
                  this._store.dispatch(loadWorkflowSuccess({ workflow }));

                  return this._databaseService.upsertWorkflow(workflow);
                }

                return Promise.resolve(null);
              },
              (): Promise<{ body: QimaOptionalType<IWorkflow> }> =>
                this._databaseService.getWorkflow(id).then((workflow): { body: IWorkflow } => {
                  return { body: workflow };
                }),
              // Workflow is fetched from the API only when no requests are still waiting to be synced, otherwise the database is considered the source of truth
              !hasPendingRequest
            )
        )
      );
    } else if (this._isUpdateInspectionSamplingSizeRequest(request)) {
      const groups = InspectionInterceptor.PATCH_SAMPLING_SIZE_REGEX.exec(request.url).groups;
      const id: InspectionId = groups.id ? +groups.id : groups.uuid;

      this._actionsQueueService.addAction(request);

      return from(
        this._databaseService.updateWorkflowSamplingSize(id, request.body as IWorkflowSamplingSize).then(
          (): HttpResponse<unknown> =>
            new HttpResponse({
              status: EQpHttpStatusCode.NO_CONTENT,
            })
        )
      );
    } else if (this._isUpdateInspectionActualQuantitiesRequest(request)) {
      const groups = InspectionInterceptor.PUT_ACTUAL_QUANTITIES_REGEX.exec(request.url).groups;
      const id: InspectionId = groups.id ? +groups.id : groups.uuid;

      this._actionsQueueService.addAction(request);

      return from(
        this._databaseService.updateWorkflowProductActualQuantities(id, request.body as IProductActualQuantities).then(
          (): HttpResponse<unknown> =>
            new HttpResponse({
              status: EQpHttpStatusCode.NO_CONTENT,
            })
        )
      );
    } else if (this._isSelectInspectionSiteRequest(request)) {
      const groups = InspectionInterceptor.SITE_LOCATION_REGEX.exec(request.url).groups;
      const id: InspectionId = groups.id ? +groups.id : groups.uuid;
      const { entityId } = request.body;

      this._databaseService.updateSiteLocation(id, entityId);
      this._actionsQueueService.addAction(request);

      return of(
        new HttpResponse({
          status: EQpHttpStatusCode.NO_CONTENT,
        })
      );
    } else if (this._isPatchInspectionResultRequest(request)) {
      const groups = InspectionInterceptor.PATCH_INSPECTION_RESULT.exec(request.url).groups;
      const id: InspectionId = groups.id ? +groups.id : groups.uuid;
      const { pending } = request.body;

      void this._databaseService.updateIsInspectionMarkedAsPending(id, pending);
      this._actionsQueueService.addAction(request);

      return of(
        new HttpResponse({
          status: EQpHttpStatusCode.NO_CONTENT,
        })
      );
    } else if (this._isSaveProductPictureToApiRequest(request)) {
      const groups = InspectionInterceptor.PUT_PRODUCT_PICTURE_REGEX.exec(request.url).groups;
      const id: InspectionId = groups.id ? +groups.id : groups.uuid;

      this._databaseService.saveProductPicture(id, request.body);
      this._actionsQueueService.addAction(request);

      return of(
        new HttpResponse({
          status: EQpHttpStatusCode.NO_CONTENT,
        })
      );
    } else if (request.method === 'GET' && InspectionInterceptor.GET_CARTONS_TO_PICK.test(request.url)) {
      const match = InspectionInterceptor.GET_CARTONS_TO_PICK.exec(request.url);
      const cartonsToPick = this._cartonsService.calculateCartonsToPickUsingSquareRootRule(+match.groups.totalCartons);

      return of(
        new HttpResponse({
          status: EQpHttpStatusCode.OK,
          body: cartonsToPick,
        })
      );
    } else if (this._isSaveCartonsQuantityRequest(request)) {
      const groups = InspectionInterceptor.PATCH_CARTONS_TO_PICK.exec(request.url).groups;
      const id: InspectionId = groups.id ? +groups.id : groups.uuid;
      const { pickedCartons, totalCartons, cartonCalculationMethod } = request.body;

      this._databaseService.updateWorkflowPickedCartons(id, pickedCartons, totalCartons, cartonCalculationMethod);
      this._actionsQueueService.addAction(request);

      return of(
        new HttpResponse({
          status: EQpHttpStatusCode.NO_CONTENT,
        })
      );
    } else if (this._isUpdateInspectionCommentRequest(request)) {
      const groups = InspectionInterceptor.PATCH_GLOBAL_REMARKS.exec(request.url).groups;
      const id: InspectionId = groups.id ? +groups.id : groups.uuid;

      this._databaseService.updateGlobalRemark(id, request.body as InspectionComment);
      this._actionsQueueService.addAction(request);

      return of(
        new HttpResponse({
          status: EQpHttpStatusCode.NO_CONTENT,
        })
      );
    } else if (this._isUpdateAnswerCommentToApiRequest(request)) {
      const groups = InspectionInterceptor.COMMENT_TEST_REGEX.exec(request.url).groups;
      const id: InspectionId = groups.id ? +groups.id : groups.uuid;
      const { answerId } = InspectionInterceptor.COMMENT_TEST_REGEX.exec(request.url).groups;

      this._databaseService.updateTestComment(id, answerId, request.body);
      this._actionsQueueService.addAction(request);

      return of(
        new HttpResponse({
          status: EQpHttpStatusCode.NO_CONTENT,
        })
      );
    } else if (this._isMarkAsNotApplicableToApiRequest(request)) {
      const { answerId } = InspectionInterceptor.MARK_AS_NOT_APPLICABLE_REGEX.exec(request.url).groups;
      const groups = InspectionInterceptor.MARK_AS_NOT_APPLICABLE_REGEX.exec(request.url).groups;
      const id: InspectionId = groups.id ? +groups.id : groups.uuid;

      this._actionsQueueService.addAction(request);

      return from(
        this._databaseService.updateNotApplicable(id, answerId, request.body.notApplicable).then(
          (): HttpResponse<unknown> =>
            new HttpResponse({
              status: EQpHttpStatusCode.NO_CONTENT,
            })
        )
      );
    } else if (this._isSaveCheckToAPIRequest(request)) {
      const groups = InspectionInterceptor.PUT_WORKFLOW_CHECK_REGEX.exec(request.url).groups;
      const id: InspectionId = groups.id ? +groups.id : groups.uuid;

      this._databaseService.updateCheck(id, request.body as WorkflowCheckType);
      this._actionsQueueService.addAction(request);

      return of(
        new HttpResponse({
          status: EQpHttpStatusCode.NO_CONTENT,
        })
      );
    } else if (this._isSaveAnswerRequest(request)) {
      const groups = InspectionInterceptor.PATCH_WORKFLOW_REGEX.exec(request.url).groups;
      const id: InspectionId = groups.id ? +groups.id : groups.uuid;

      this._actionsQueueService.addAction(request);

      return from(
        this._databaseService.saveTestChecklistAnswer(id, request.body).then(
          (): HttpResponse<unknown> =>
            new HttpResponse({
              status: EQpHttpStatusCode.NO_CONTENT,
            })
        )
      );
    } else if (this._isSaveCustomFieldRequest(request)) {
      const groups = this.PATCH_CUSTOM_FIELD_REGEX.exec(request.url).groups;
      const id: InspectionId = groups.id ? +groups.id : groups.uuid;
      const fieldId = groups.fieldId;

      this._actionsQueueService.addAction(request);

      return from(
        this._databaseService.saveWorkflowActionCustomFieldsValue(id, +fieldId, request.body).then(
          (): HttpResponse<unknown> =>
            new HttpResponse({
              status: EQpHttpStatusCode.NO_CONTENT,
            })
        )
      );
    } else if (request.headers.get(HTTP_QIMA_ENDPOINT_NAME) === EHttpQimaEndpoints.DELETE_TEST_CHECKLIST_TABLE_ROW) {
      const groups = InspectionInterceptor.DELETE_TEST_CHECKLIST_TABLE_ROW_REGEX.exec(request.url).groups;
      const id: InspectionId = groups.id ? +groups.id : groups.uuid;
      const { tableResponseId, answerId } = groups;

      this._databaseService.deleteTestChecklistTableRow(id, tableResponseId, answerId);
      this._actionsQueueService.addAction(request);

      return of(
        new HttpResponse({
          status: EQpHttpStatusCode.NO_CONTENT,
        })
      );
    } else if (this._isUpdateDefectRequest(request)) {
      const groups = InspectionInterceptor.PUT_DEFECT_QUANTITY.exec(request.url).groups;
      const id: InspectionId = groups.id ? +groups.id : groups.uuid;
      const { defectId } = groups;

      this._databaseService.updateDefectQuantity(id, defectId, request.body);
      this._actionsQueueService.addAction(request);

      return of(
        new HttpResponse({
          status: EQpHttpStatusCode.NO_CONTENT,
        })
      );
    } else if (this._isUpdateDefectCommentRequest(request)) {
      const groups = InspectionInterceptor.PATCH_DEFECT_COMMENT.exec(request.url).groups;
      const id: InspectionId = groups.id ? +groups.id : groups.uuid;
      const { defectId } = groups;

      this._databaseService.updateDefectComment(id, defectId, request.body);
      this._actionsQueueService.addAction(request);

      return of(
        new HttpResponse({
          status: EQpHttpStatusCode.NO_CONTENT,
        })
      );
    } else if (this._isCreateDefectRequest(request)) {
      const groups = InspectionInterceptor.POST_DEFECT.exec(request.url).groups;
      const id: InspectionId = groups.id ? +groups.id : groups.uuid;

      this._actionsQueueService.addAction(request);

      return from(
        this._databaseService.addDefect(id, request.body).then(
          (defect): HttpResponse<unknown> =>
            new HttpResponse({
              status: EQpHttpStatusCode.OK,
              body: defect,
            })
        )
      );
    } else if (this._isCompleteDefectsChecklistRequest(request)) {
      const groups = InspectionInterceptor.PUT_COMPLETE_DEFECTS_CHECKLIST.exec(request.url).groups;
      const id: InspectionId = groups.id ? +groups.id : groups.uuid;
      const stepId: number = +groups.stepId;
      const actionId: number = +groups.actionId;
      const { purchaseOrderProductId } = request.body;

      this._actionsQueueService.addAction(request);

      return from(
        this._databaseService.completeDefectsChecklist$(id, stepId, actionId, purchaseOrderProductId).then(
          (): HttpResponse<unknown> =>
            new HttpResponse({
              status: EQpHttpStatusCode.NO_CONTENT,
            })
        )
      );
    } else if (this._isPutMeasurementRequest(request)) {
      const groups = this.PUT_MEASURE.exec(request.url).groups;
      const id: InspectionId = groups.id ? +groups.id : groups.uuid;
      const { measureId } = this.PUT_MEASURE.exec(request.url).groups;
      const { measure, section } = request.body;

      this._databaseService.saveMeasure(id, measureId, measure, section);
      this._actionsQueueService.addAction(request);

      return of(
        new HttpResponse({
          status: EQpHttpStatusCode.NO_CONTENT,
        })
      );
    } else if (this._isPostMeasurementRequest(request)) {
      const { id, varianceId } = this.POST_CUSTOM_MEASUREMENT_PRODUCT_SAMPLE.exec(request.url).groups;
      const { section } = request.body;

      this._databaseService.saveCustomMeasurementProductSample(parseInt(id), varianceId, section);
      this._actionsQueueService.addAction(request);

      return of(
        new HttpResponse({
          status: EQpHttpStatusCode.NO_CONTENT,
        })
      );
    } else if (this._isDeleteAttachmentRequest(request)) {
      const groups = InspectionInterceptor.DELETE_ATTACHMENT_REGEX.exec(request.url).groups;
      const id: InspectionId = groups.id ? +groups.id : groups.uuid;
      const { entityId, attachmentId, entity } = groups;

      void this._databaseService.deleteInspectionAttachment(id, attachmentId, entityId, entity);
      this._actionsQueueService.addAction(request);

      return of(
        new HttpResponse({
          status: EQpHttpStatusCode.NO_CONTENT,
        })
      );
    } else if (this._isDeleteSimplifiedMeasurementAttachmentRequest(request)) {
      const groups = InspectionInterceptor.DELETE_SIMPLIFIED_MEASUREMENT_ATTACHMENTS_REGEX.exec(request.url).groups;
      const id: InspectionId = groups.id ? +groups.id : groups.uuid;
      const { entityId, attachmentId } = groups;

      void this._databaseService.deleteInspectionAttachment(id, attachmentId, entityId, EQpImageElementType.SIMPLIFIED_MEASUREMENTS);
      this._actionsQueueService.addAction(request);

      return of(
        new HttpResponse({
          status: EQpHttpStatusCode.NO_CONTENT,
        })
      );
    } else if (this._isPostAttachmentRequest(request)) {
      const groups = InspectionInterceptor.POST_ATTACHMENT_REGEX.exec(request.url).groups;
      const id: InspectionId = groups.id ? +groups.id : groups.uuid;
      const { entity, entityId } = groups;
      const attachmentId = request.params.get('id');

      return from(
        this._databaseService
          .saveInspectionAttachment(id, request.body, attachmentId, entity, entityId)
          .then((): Promise<HttpRequest<QimaOptionalType<FormData>>> => this.cleanFileFormData(request))
          .then((cleanRequest): HttpResponse<unknown> => {
            // ⚠️ we need to save File before add request to prevent no file during the sync
            this._actionsQueueService.addAction(cleanRequest);

            const headers: HttpHeaders = new HttpHeaders().append('location', attachmentId);

            return new HttpResponse({
              status: EQpHttpStatusCode.NO_CONTENT,
              headers,
            });
          })
      );
    } else if (this._isPostSimplifiedMeasurementAttachmentRequest(request)) {
      const groups = InspectionInterceptor.POST_SIMPLIFIED_MEASUREMENT_ATTACHMENTS_REGEX.exec(request.url).groups;
      const id: InspectionId = groups.id ? +groups.id : groups.uuid;
      const attachmentId = request.params.get('id');

      return from(
        this._databaseService
          .saveInspectionAttachment(id, request.body, attachmentId, EQpImageElementType.SIMPLIFIED_MEASUREMENTS)
          .then((): Promise<HttpRequest<QimaOptionalType<FormData>>> => this.cleanFileFormData(request))
          .then((cleanRequest): HttpResponse<unknown> => {
            // ⚠️ we need to save File before add request to prevent no file during the sync
            this._actionsQueueService.addAction(cleanRequest);

            const headers: HttpHeaders = new HttpHeaders().append('location', attachmentId);

            return new HttpResponse({
              status: EQpHttpStatusCode.NO_CONTENT,
              headers,
            });
          })
      );
    } else if (this._isPutAQLReasonForChanges(request)) {
      const groups = this.PUT_AQL_REASON_FOR_CHANGES.exec(request.url).groups;
      const id: InspectionId = groups.id ? +groups.id : groups.uuid;

      return from(
        this._databaseService
          .saveAQLReasonForChanges(id, request.body.reason, request.body.purchaseOrderProductId)
          .then((): HttpResponse<unknown> => {
            this._actionsQueueService.addAction(request);

            return new HttpResponse({
              status: EQpHttpStatusCode.NO_CONTENT,
            });
          })
      );
    } else if (this._isPutEntityImagesOrderRequest(request)) {
      const groups = InspectionInterceptor.PUT_ENTITY_IMAGES_ORDER_REGEX.exec(request.url).groups;
      const id: InspectionId = groups.id ? +groups.id : groups.uuid;
      const { entity, entityId } = groups;

      return from(
        this._databaseService
          .saveInspectionImagesOrder(id, request.body.imageIds, entity, entityId, request.body.purchaseOrderProductId)
          .then((): HttpResponse<unknown> => {
            this._actionsQueueService.addAction(request);

            return new HttpResponse({
              status: EQpHttpStatusCode.NO_CONTENT,
            });
          })
      );
    } else if (this._isPutGlobalImagesOrderRequest(request)) {
      const groups = InspectionInterceptor.PUT_GLOBAL_IMAGES_ORDER_REGEX.exec(request.url).groups;
      const id: InspectionId = groups.id ? +groups.id : groups.uuid;

      return from(
        this._databaseService.saveInspectionImagesOrder(id, request.body.imageIds).then((): HttpResponse<unknown> => {
          this._actionsQueueService.addAction(request);

          return new HttpResponse({
            status: EQpHttpStatusCode.NO_CONTENT,
          });
        })
      );
    } else if (this._isPatchImagesRequest(request)) {
      const groups = InspectionInterceptor.PATCH_IMAGES_REGEX.exec(request.url).groups;
      const id: InspectionId = groups.id ? +groups.id : groups.uuid;
      const { imageIds, data } = request.body;
      const { caption, isSpotlight } = data;

      return from(
        forkJoin(
          imageIds.map(
            (imageId): Observable<void> => from(this._databaseService.saveInspectionFileData(id, { caption, isSpotlight }, imageId))
          )
        ).pipe(
          tap((): void => this._actionsQueueService.addAction(request)),
          map(
            (): HttpResponse<unknown> =>
              new HttpResponse({
                status: EQpHttpStatusCode.NO_CONTENT,
              })
          )
        )
      );
    } else if (this._isPatchGlobalImagesRequest(request)) {
      const groups = InspectionInterceptor.PATCH_GLOBAL_IMAGES_REGEX.exec(request.url).groups;
      const id: InspectionId = groups.id ? +groups.id : groups.uuid;
      const { imageIds, data } = request.body;
      const { caption, isSpotlight } = data;

      return from(
        forkJoin(
          imageIds.map(
            (imageId): Observable<void> => from(this._databaseService.saveInspectionFileData(id, { caption, isSpotlight }, imageId))
          )
        ).pipe(
          tap((): void => this._actionsQueueService.addAction(request)),
          map(
            (): HttpResponse<unknown> =>
              new HttpResponse({
                status: EQpHttpStatusCode.NO_CONTENT,
              })
          )
        )
      );
    } else if (this._isPatchImageRequest(request)) {
      const groups = InspectionInterceptor.PATCH_IMAGE_REGEX.exec(request.url).groups;
      const id: InspectionId = groups.id ? +groups.id : groups.uuid;
      const imageId = groups.imageId;
      const { caption, isSpotlight } = request.body;

      void this._databaseService.saveInspectionFileData(id, { caption, isSpotlight }, imageId);
      this._actionsQueueService.addAction(request);

      return of(
        new HttpResponse({
          status: EQpHttpStatusCode.NO_CONTENT,
        })
      );
    } else if (this._isPatchGlobalImageRequest(request)) {
      const groups = InspectionInterceptor.PATCH_GLOBAL_IMAGE_REGEX.exec(request.url).groups;
      const id: InspectionId = groups.id ? +groups.id : groups.uuid;
      const imageId = groups.imageId;
      const { caption, isSpotlight } = request.body;

      void this._databaseService.saveInspectionFileData(id, { caption, isSpotlight }, imageId);
      this._actionsQueueService.addAction(request);

      return of(
        new HttpResponse({
          status: EQpHttpStatusCode.NO_CONTENT,
        })
      );
    } else if (this._isPostEntityImageRequest(request)) {
      const groups = InspectionInterceptor.POST_ENTITY_IMAGE_REGEX.exec(request.url).groups;
      const id: InspectionId = groups.id ? +groups.id : groups.uuid;
      const { entity, entityId } = groups;

      return from(
        this._databaseService.saveInspectionFile(id, request.body, entity, entityId).then((imageId): HttpResponse<unknown> => {
          // ⚠️ we need to save File before add request to prevent no file during the sync
          void this.cleanFileFormData(request).then((cleanRequest): void => {
            this._actionsQueueService.addAction(cleanRequest);
          });

          const headers: HttpHeaders = new HttpHeaders().append('location', imageId);

          return new HttpResponse({
            status: EQpHttpStatusCode.NO_CONTENT,
            headers,
          });
        })
      );
    } else if (this._isPostGlobalImageRequest(request)) {
      const groups = InspectionInterceptor.POST_GLOBAL_IMAGE_REGEX.exec(request.url).groups;
      const id: InspectionId = groups.id ? +groups.id : groups.uuid;

      return from(
        this._databaseService.saveInspectionFile(id, request.body).then((imageId): HttpResponse<unknown> => {
          // ⚠️ we need to save File before add request to prevent no file during the sync
          void this.cleanFileFormData(request).then((cleanRequest): void => {
            this._actionsQueueService.addAction(cleanRequest);
          });

          const headers: HttpHeaders = new HttpHeaders().append('location', imageId);

          return new HttpResponse({
            status: EQpHttpStatusCode.NO_CONTENT,
            headers,
          });
        })
      );
    } else if (
      request.method === 'GET' &&
      (InspectionInterceptor.GET_GLOBAL_IMAGE_REGEX.test(request.url) || InspectionInterceptor.GET_ENTITY_IMAGE_REGEX.test(request.url))
    ) {
      const canUseStoredThumbnail = !!request.params.get('thumbnail');
      let id: InspectionId;
      let imageId;
      let entity;
      let entityId;

      if (InspectionInterceptor.GET_GLOBAL_IMAGE_REGEX.test(request.url)) {
        const groups = InspectionInterceptor.GET_GLOBAL_IMAGE_REGEX.exec(request.url).groups;

        id = groups.id ? +groups.id : groups.uuid;
        imageId = InspectionInterceptor.GET_GLOBAL_IMAGE_REGEX.exec(request.url).groups.imageId;
      } else if (InspectionInterceptor.GET_ENTITY_IMAGE_REGEX.test(request.url)) {
        const groups = InspectionInterceptor.GET_ENTITY_IMAGE_REGEX.exec(request.url).groups;

        id = groups.id ? +groups.id : groups.uuid;
        imageId = InspectionInterceptor.GET_ENTITY_IMAGE_REGEX.exec(request.url).groups.imageId;
        entity = InspectionInterceptor.GET_ENTITY_IMAGE_REGEX.exec(request.url).groups.entity;
        entityId = InspectionInterceptor.GET_ENTITY_IMAGE_REGEX.exec(request.url).groups.entityId;
      }

      return this.fetchData$(
        request,
        next,
        (httpResponse: HttpResponse<BlobPart>): Promise<string> => {
          const bodyAsFormData = new FormData();

          bodyAsFormData.append('image', new Blob([httpResponse.body]));
          bodyAsFormData.append('data', new Blob([fromBase64(httpResponse.headers.get('data'))], { type: 'application/json' }));

          return this._databaseService.saveInspectionFile(id, bodyAsFormData, entity, entityId, canUseStoredThumbnail);
        },
        (): Promise<{ body: ArrayBuffer; headers: HttpHeaders }> =>
          this._databaseService.getFile(imageId).then((dbFile): { body: ArrayBuffer; headers: HttpHeaders } => {
            // if stored file is a thumbnail and we requires the full image, we want to refetch the image
            if (!dbFile || (!canUseStoredThumbnail && dbFile.isThumbnail)) {
              return { body: undefined, headers: undefined };
            }

            const headers: HttpHeaders = new HttpHeaders()
              .append('data', toBase64(JSON.stringify(dbFile.data)))
              .append('content-type', dbFile.data.type);

            return {
              body: dbFile.file,
              headers,
            };
          }),
        false
      );
    } else if (request.method === 'GET' && InspectionInterceptor.GET_DOCUMENT_METADATA_REGEX.test(request.url)) {
      const { documentId } = InspectionInterceptor.GET_DOCUMENT_METADATA_REGEX.exec(request.url).groups;

      return this.fetchData$(
        request,
        next,
        (httpResponse: HttpResponse<{ 'filename': string; 'Content-Type': string; 'Content-Length': string }>): Promise<void> =>
          this._databaseService.saveFileMetada(documentId, {
            type: httpResponse.body['Content-Type'],
            name: httpResponse.body.filename,
            size: httpResponse.body['Content-Length'],
          }),
        (): Promise<{ body: unknown }> =>
          this._databaseService.getFile(documentId).then((dbFile): { body: unknown } => {
            if (!dbFile) {
              return { body: undefined };
            }

            const documentData = dbFile.data as IChecklistDocumentData;

            return {
              body: {
                'filename': documentData.name,
                'content-type': documentData.type,
                'content-length': documentData.size,
              },
            };
          })
      );
    } else if (request.method === 'GET' && InspectionInterceptor.GET_DOCUMENT_REGEX.test(request.url)) {
      const { documentId } = InspectionInterceptor.GET_DOCUMENT_REGEX.exec(request.url).groups;

      return this.fetchData$(
        request,
        next,
        (httpResponse: HttpResponse<ArrayBuffer>): Promise<void> =>
          this._databaseService.saveFile(documentId, httpResponse.body, {
            type: httpResponse.headers.get('content-type'),
          }),
        (): Promise<{ body: ArrayBuffer; headers: HttpHeaders }> =>
          this._databaseService.getFile(documentId).then((dbFile): { body: ArrayBuffer; headers: HttpHeaders } => {
            if (!dbFile) {
              return { body: undefined, headers: undefined };
            }

            // dbFile.data['data']?.type added this to check for the backward compatibility when content-type is not coming from metadata endpoint
            const headers: HttpHeaders = new HttpHeaders().append('content-type', dbFile.data.type ?? dbFile.data['data']?.type);

            return {
              body: dbFile.file,
              headers,
            };
          })
      );
    } else if (request.method === 'GET' && InspectionInterceptor.GET_PHOTO_REGEX.test(request.url)) {
      const { photoId } = InspectionInterceptor.GET_PHOTO_REGEX.exec(request.url).groups;
      const thumbnail = request.params.get('thumbnail') || '';

      return this.fetchData$(
        request,
        next,
        (httpResponse: HttpResponse<ArrayBuffer>): Promise<void> =>
          this._databaseService.saveFile(`${photoId}${thumbnail}`, httpResponse.body, {
            type: httpResponse.headers.get('content-type'),
          }),
        (): Promise<{ body: ArrayBuffer; headers: HttpHeaders }> =>
          this._databaseService.getFile(`${photoId}${thumbnail}`).then((dbFile): { body: ArrayBuffer; headers: HttpHeaders } => {
            if (!dbFile) {
              return { body: undefined, headers: undefined };
            }

            const headers: HttpHeaders = new HttpHeaders().append('content-type', dbFile.data.type);

            return {
              body: dbFile.file,
              headers,
            };
          }),
        false
      );
    } else if (this._isPostDocumentsRequest(request)) {
      const groups = InspectionInterceptor.POST_DOCUMENT_REGEX.exec(request.url).groups;
      const id: InspectionId = groups.id ? +groups.id : groups.uuid;
      const fileId = request.url.split('?id=')[1];

      return from(
        this._databaseService.saveSignDocument(id, fileId, request.body).then((): HttpResponse<unknown> => {
          // ⚠️ we need to save File before add request to prevent no file during the sync
          void this.cleanFileFormData(request).then((cleanRequest): void => {
            this._actionsQueueService.addAction(cleanRequest);
          });

          const headers: HttpHeaders = new HttpHeaders().append('location', fileId);

          return new HttpResponse({
            status: EQpHttpStatusCode.CREATED,
            headers,
          });
        })
      );
    } else if (request.method === 'PUT' && InspectionInterceptor.PUT_AQL_DEFECTS.test(request.url)) {
      const groups = InspectionInterceptor.PUT_AQL_DEFECTS.exec(request.url).groups;
      const id: InspectionId = groups.id ? +groups.id : groups.uuid;
      const aql = <AqlDefectsType>request.body;

      void this._databaseService.updateInspectionAql(id, aql);
      this._actionsQueueService.addAction(request);

      return of(
        new HttpResponse({
          status: EQpHttpStatusCode.NO_CONTENT,
        })
      );
    } else if (request.method === 'PUT' && InspectionInterceptor.PUT_FINDINGS_SUMMARY_REVIEW.test(request.url)) {
      const groups = InspectionInterceptor.PUT_FINDINGS_SUMMARY_REVIEW.exec(request.url).groups;
      const id: InspectionId = groups.id ? +groups.id : groups.uuid;
      const findingsSummaryReview: IWorkflowInspectionFindingsSummaryReview = {
        isConfirmed: true,
      };

      void this._databaseService.saveInspectionFindingsSummaryReview(id, findingsSummaryReview);

      this._actionsQueueService.addAction(request);

      return of(
        new HttpResponse({
          status: EQpHttpStatusCode.NO_CONTENT,
        })
      );
    } else if (this._isDeleteInspectionImageRequest(request)) {
      const groups = InspectionInterceptor.DELETE_ACTION_IMAGE_REGEX.exec(request.url).groups;
      const id: InspectionId = groups.id ? +groups.id : groups.uuid;
      const { element, elementId } = groups;

      this._actionsQueueService.addAction(request);
      void this._databaseService.deleteInspectionFile(id, request.body.imageId, element, elementId);

      return of(
        new HttpResponse({
          status: EQpHttpStatusCode.NO_CONTENT,
        })
      );
    } else if (this._isDeleteImageRequest(request)) {
      const groups = InspectionInterceptor.DELETE_GLOBAL_IMAGE_REGEX.exec(request.url).groups;
      const id: InspectionId = groups.id ? +groups.id : groups.uuid;

      this._actionsQueueService.addAction(request);
      void this._databaseService.deleteInspectionFile(id, request.body.imageId);

      return of(
        new HttpResponse({
          status: EQpHttpStatusCode.NO_CONTENT,
        })
      );
    } else if (request.method === 'PUT' && InspectionInterceptor.PUT_COMPLETE_STARTING_PICTURES.test(request.url)) {
      const groups = InspectionInterceptor.PUT_COMPLETE_STARTING_PICTURES.exec(request.url).groups;
      const id: InspectionId = groups.id ? +groups.id : groups.uuid;
      const pathId: string = groups.pathId;

      this._actionsQueueService.addAction(request);
      void this._databaseService.completeStartingPictures$(id, pathId);

      return of(
        new HttpResponse({
          status: EQpHttpStatusCode.NO_CONTENT,
        })
      );
    } else if (request.method === 'PUT' && InspectionInterceptor.PUT_COMPLETE_REFERENCE_SAMPLE.test(request.url)) {
      const groups = InspectionInterceptor.PUT_COMPLETE_REFERENCE_SAMPLE.exec(request.url).groups;
      const id: InspectionId = groups.id ? +groups.id : groups.uuid;
      const stepId: number = +groups.stepId;
      const actionId: number = +groups.actionId;

      this._actionsQueueService.addAction(request);
      void this._databaseService.completeReferenceSample$(id, stepId, actionId);

      return of(
        new HttpResponse({
          status: EQpHttpStatusCode.NO_CONTENT,
        })
      );
    } else if (request.method === 'PUT' && InspectionInterceptor.PUT_COMPLETE_SAMPLE_COLLECTION.test(request.url)) {
      const groups = InspectionInterceptor.PUT_COMPLETE_SAMPLE_COLLECTION.exec(request.url).groups;
      const id: InspectionId = groups.id ? +groups.id : groups.uuid;
      const stepId: number = +groups.stepId;
      const actionId: number = +groups.actionId;

      this._actionsQueueService.addAction(request);
      void this._databaseService.completeSampleCollection$(id, stepId, actionId);

      return of(
        new HttpResponse({
          status: EQpHttpStatusCode.NO_CONTENT,
        })
      );
    } else if (this._isPutSimplifiedMeasuresRequest(request)) {
      const groups = InspectionInterceptor.PUT_SIMPLIFIED_MEASURES_REGEX.exec(request.url).groups;
      const id: InspectionId = groups.id ? +groups.id : groups.id;
      const simplifiedMeasures = request.body;

      void this._databaseService.saveSimplifiedMeasures(id, simplifiedMeasures);
      this._actionsQueueService.addAction(request);

      return of(new HttpResponse({ status: EQpHttpStatusCode.NO_CONTENT }));
    } else if (this._isPutSimplifiedMeasuresStatusRequest(request)) {
      const groups = InspectionInterceptor.PUT_SIMPLIFIED_MEASURES_STATUS_REGEX.exec(request.url).groups;
      const id: InspectionId = groups.id ? +groups.id : groups.id;
      const status = request.body as SimplifiedMeasurementChecklistStatusDTO;

      void this._databaseService.saveSimplifiedMeasuresMarkedAsNotApplicable(id, status);
      this._actionsQueueService.addAction(request);

      return of(new HttpResponse({ status: EQpHttpStatusCode.NO_CONTENT }));
    } else if (this._isPutSimplifiedMeasurementCommentRequest(request)) {
      const groups = InspectionInterceptor.PUT_SIMPLIFIED_MEASUREMENT_COMMENT_REGEX.exec(request.url).groups;
      const id: InspectionId = groups.id ? +groups.id : groups.id;
      const comment = request.body.comment;

      void this._databaseService.saveSimplifiedMeasurementComment(id, comment);
      this._actionsQueueService.addAction(request);

      return of(new HttpResponse({ status: EQpHttpStatusCode.NO_CONTENT }));
    } else if (this._isAbortInspectionRequest(request)) {
      const groups = InspectionInterceptor.PATCH_ABORT_INSPECTION.exec(request.url).groups;
      const id: InspectionId = groups.id ? +groups.id : groups.uuid;
      const abortInspectionDTO = request.body as AbortInspectionDTO;

      this._actionsQueueService.addAction(request);
      void this._databaseService.setInspectionAsAborted(id, abortInspectionDTO);

      return of(
        new HttpResponse({
          status: EQpHttpStatusCode.NO_CONTENT,
        })
      );
    }

    return next.handle(request);
  }

  /**
   * @description To prevent duplicate file storage and formdata or not allowed in storage
   * @param action
   * @returns Promise<HttpRequest<any>>
   */
  public async cleanFileFormData(action: HttpRequest<QimaOptionalType<FormData>>): Promise<HttpRequest<QimaOptionalType<FormData>>> {
    let body = null;

    if (action.body?.get('data')) {
      const dataBlob: Blob = action.body.get('data') as Blob;
      const readPromise = new Promise<string>((resolve, reject): void => {
        try {
          const reader = new FileReader();

          reader.onloadend = (): void => {
            resolve(reader.result as string);
          };

          reader.readAsText(dataBlob);
        } catch (e) {
          reject(e);
        }
      });

      body = await readPromise;
    }

    return new HttpRequest(action.method, action.url, body, {
      headers: action.headers,
      params: action.params,
      responseType: action.responseType,
    });
  }

  // This method will fetch the data with the "API first strategy"
  public fetchData$<T, G = unknown>(
    request: HttpRequest<G>,
    next: HttpHandler,
    storeInDB: (httpEvent: HttpEvent<T>) => Promise<unknown>,
    retrieveFromDB: () => Promise<{ body: T; headers? }>,
    isAPIFirst = true
  ): Observable<HttpEvent<T> | HttpResponse<T>> {
    const isOnline = this._networkStatusService.isOnline;

    return (isOnline && isAPIFirst ? next.handle(request) : of(new HttpResponse<T>({ status: EQpHttpStatusCode.BAD_REQUEST }))).pipe(
      // Catch any error and if the API call fails (w/ a 404 for example), just return the failed response
      // otherwise return a Bad Request response.
      catchError(
        (error: HttpErrorResponse | HttpResponse<T>): Observable<HttpEvent<T> | HttpResponse<T>> =>
          this.ignoreSomeInterceptorStatus$(error, request)
      ),

      // Store in DB if the API was called and it returned a valid (OK) response
      flatMap((httpEvent: HttpEvent<T>): Observable<HttpEvent<T>> => {
        return httpEvent.type === HttpEventType.Response && httpEvent.ok
          ? from(storeInDB(httpEvent)).pipe(mapTo(httpEvent))
          : of(httpEvent);
      }),

      flatMap(
        (httpEvent: HttpEvent<T>): Observable<HttpEvent<T> | HttpResponse<T>> =>
          from(retrieveFromDB()).pipe(
            flatMap((data?: { body: T; headers?: HttpHeaders }): Observable<HttpEvent<T> | HttpResponse<T>> => {
              if (!isNil(data?.body)) {
                return of(
                  new HttpResponse({
                    body: data.body,
                    status: EQpHttpStatusCode.OK,
                    url: request.url,
                    headers: data.headers,
                  })
                );
              } else if (isOnline && !isAPIFirst) {
                // if the data is not in the DB and if we prefer offline first (isAPIFirst) -> we try to get the data from the API
                this._qpLoggerService.info(`Inspection interceptor => not able to retrieve this call from db => ${request.url}`);

                return next.handle(request).pipe(
                  catchError(
                    (error: HttpErrorResponse | HttpResponse<T>): Observable<HttpEvent<T> | HttpResponse<T>> =>
                      this.ignoreSomeInterceptorStatus$(error, request)
                  ),
                  // Still have to store in DB if we called the API after all!
                  switchMap(
                    (httpEvent: HttpEvent<T>): Observable<HttpEvent<T>> =>
                      httpEvent.type === HttpEventType.Response && httpEvent.ok
                        ? from(storeInDB(httpEvent)).pipe(mapTo(httpEvent))
                        : of(httpEvent)
                  )
                );
              }

              this._qpLoggerService.warn(
                `Inspection interceptor => not able to retrieve this call from db (in offline 📵) => ${request.url}`
              );

              return of(httpEvent);
            })
          )
      )
    );
  }

  /**
   * @description
   * Catch all HTTP errors to let the interceptor navigate in case of 403/404/410/500
   * @template T the data given to the catchError function
   * @param {HttpErrorResponse | HttpResponse<T>} error the error catch by the catchError
   * @param {HttpRequest<any>} request the request of the interceptor
   * @returns {Observable<HttpEvent<T> | HttpResponse<T>>} The catchError return
   */
  public ignoreSomeInterceptorStatus$<T, G = unknown>(
    error: HttpErrorResponse | HttpResponse<T>,
    request: HttpRequest<G>
  ): Observable<HttpEvent<T> | HttpResponse<T>> {
    // @todo maybe we need to let through all the errors?
    if (error instanceof HttpErrorResponse) {
      this._qpLoggerService.error(`Inspection interceptor => caught error ${error.status}`);

      const ignoredInterceptorStatusCodes: EQpHttpStatusCode[] = qpGetIgnoredInterceptorStatusCodes(request);

      if (
        (error.status === EQpHttpStatusCode.NOT_FOUND && !ignoredInterceptorStatusCodes.includes(EQpHttpStatusCode.NOT_FOUND)) ||
        [EQpHttpStatusCode.FORBIDDEN, EQpHttpStatusCode.INTERNAL_SERVER_ERROR, EQpHttpStatusCode.GONE].includes(error.status)
      ) {
        return throwError(error);
      }

      this._qpLoggerService.info(`Inspection interceptor => error ${error.status} was unhandled temporarily to avoid bad UX`);
    }

    // Else handle other kind of errors as a success
    // ⚠️ It could lead to optional data received in the HTTP calls
    if (error instanceof HttpResponse) {
      return of(error as HttpResponse<T>);
    }

    return of(new HttpResponse<T>({ status: EQpHttpStatusCode.BAD_REQUEST }));
  }

  public getSamplingSize(request: HttpRequest<void>): HttpResponse<ISamplingSize> {
    /**
     * @todo should parse the numbers
     * @todo BUT right now, the {@link AqlValue} will be trim and so values like {@link AqlValue#AQL_0_1} will no longer be mapped correctly
     * @type {IHttpGetSamplingSizesQueryParams}
     */
    const queryParams: IHttpGetSamplingSizesQueryParams = this._qpQueryParamsService.getQueryParams(request.urlWithParams, {
      parseNumbers: false,
    }) as unknown as IHttpGetSamplingSizesQueryParams;
    const { samplingSizes } = new QpAql({
      inspectionLevel: queryParams.inspectionLevel,
      quantity: parseInt(queryParams.lotSize, 10),
      minorAQL: queryParams.minor,
      criticalAQL: queryParams.critical,
      majorAQL: queryParams.major,
    });

    return new HttpResponse<ISamplingSize>({
      status: EQpHttpStatusCode.OK,
      body: {
        inspectionLevel: samplingSizes.inspectionLevel,
        lotSize: samplingSizes.quantity,
        codeLetter: samplingSizes.codeLetter,
        criticalSampleSize: samplingSizes.critical.sampleSize,
        majorSampleSize: samplingSizes.major.sampleSize,
        minorSampleSize: samplingSizes.minor.sampleSize,
        type: ESamplingSizeTypes.AQL_SAMPLE_SIZE,
      },
    });
  }

  private _isUpdateInspectionStatusRequest(request: HttpRequest<unknown>): request is HttpRequest<InspectionStatusDTO> {
    return request.method === EHttpMethod.PATCH && InspectionInterceptor.PATCH_INSPECTION_STATUS_REGEX.test(request.url);
  }

  private _isUpdateInspectionSamplingSizeRequest(request: HttpRequest<unknown>): request is HttpRequest<WorkflowSamplingSizeType> {
    return request.method === EHttpMethod.PATCH && InspectionInterceptor.PATCH_SAMPLING_SIZE_REGEX.test(request.url);
  }

  private _isUpdateInspectionActualQuantitiesRequest(request: HttpRequest<unknown>): request is HttpRequest<IProductActualQuantities> {
    return request.method === EHttpMethod.PUT && InspectionInterceptor.PUT_ACTUAL_QUANTITIES_REGEX.test(request.url);
  }

  private _isSelectInspectionSiteRequest(request: HttpRequest<unknown>): request is HttpRequest<{ entityId: number }> {
    return request.method === EHttpMethod.PUT && InspectionInterceptor.SITE_LOCATION_REGEX.test(request.url);
  }

  private _isPatchInspectionResultRequest(request: HttpRequest<unknown>): request is HttpRequest<{ pending: boolean }> {
    return request.method === 'PATCH' && InspectionInterceptor.PATCH_INSPECTION_RESULT.test(request.url);
  }

  private _isSaveProductPictureToApiRequest(request: HttpRequest<unknown>): request is HttpRequest<ISaveProductPictureAction> {
    return request.method === EHttpMethod.PUT && InspectionInterceptor.PUT_PRODUCT_PICTURE_REGEX.test(request.url);
  }

  private _isSaveCartonsQuantityRequest(
    request: HttpRequest<unknown>
  ): request is HttpRequest<{ pickedCartons: number; totalCartons: number; cartonCalculationMethod: CartonCalculationMethod }> {
    return request.method === EHttpMethod.PATCH && InspectionInterceptor.PATCH_CARTONS_TO_PICK.test(request.url);
  }

  private _isUpdateInspectionCommentRequest(request: HttpRequest<unknown>): request is HttpRequest<IChecklistComment> {
    return request.method === EHttpMethod.PATCH && InspectionInterceptor.PATCH_GLOBAL_REMARKS.test(request.url);
  }

  private _isUpdateAnswerCommentToApiRequest(request: HttpRequest<unknown>): request is HttpRequest<IChecklistComment> {
    return request.method === EHttpMethod.PATCH && InspectionInterceptor.COMMENT_TEST_REGEX.test(request.url);
  }

  private _isMarkAsNotApplicableToApiRequest(request: HttpRequest<unknown>): request is HttpRequest<{ notApplicable: boolean }> {
    return request.method === EHttpMethod.PUT && InspectionInterceptor.MARK_AS_NOT_APPLICABLE_REGEX.test(request.url);
  }

  private _isSaveCheckToAPIRequest(request: HttpRequest<unknown>): request is HttpRequest<IWorkflowCheck> {
    return request.method === EHttpMethod.PUT && InspectionInterceptor.PUT_WORKFLOW_CHECK_REGEX.test(request.url);
  }

  private _isSaveAnswerRequest(request: HttpRequest<unknown>): request is HttpRequest<ISaveTestChecklistAnswerData> {
    return request.method === EHttpMethod.PATCH && InspectionInterceptor.PATCH_WORKFLOW_REGEX.test(request.url);
  }

  private _isSaveCustomFieldRequest(request: HttpRequest<unknown>): request is HttpRequest<CustomFieldValueDTO> {
    return request.method === EHttpMethod.PATCH && this.PATCH_CUSTOM_FIELD_REGEX.test(request.url);
  }

  private _isUpdateDefectRequest(request: HttpRequest<unknown>): request is HttpRequest<UpdateDefectQuantityDataType> {
    return request.method === EHttpMethod.PUT && InspectionInterceptor.PUT_DEFECT_QUANTITY.test(request.url);
  }

  private _isUpdateDefectCommentRequest(request: HttpRequest<unknown>): request is HttpRequest<UpdateDefectCommentDataType> {
    return request.method === EHttpMethod.PATCH && InspectionInterceptor.PATCH_DEFECT_COMMENT.test(request.url);
  }

  private _isCompleteDefectsChecklistRequest(request: HttpRequest<unknown>): request is HttpRequest<CompleteDefectsChecklistDTO> {
    return request.method === 'PUT' && InspectionInterceptor.PUT_COMPLETE_DEFECTS_CHECKLIST.test(request.url);
  }

  private _isCreateDefectRequest(request: HttpRequest<unknown>): request is HttpRequest<INewDefectInfo> {
    return request.method === EHttpMethod.POST && InspectionInterceptor.POST_DEFECT.test(request.url);
  }

  private _isPutMeasurementRequest(request: HttpRequest<unknown>): request is HttpRequest<ISaveMeasureData> {
    return request.method === EHttpMethod.PUT && this.PUT_MEASURE.test(request.url);
  }

  private _isPostMeasurementRequest(request: HttpRequest<unknown>): request is HttpRequest<IAddCustomMeasurementProductSampleBody> {
    return request.method === 'POST' && this.POST_CUSTOM_MEASUREMENT_PRODUCT_SAMPLE.test(request.url);
  }

  private _isPostEntityImageRequest(request: HttpRequest<unknown>): request is HttpRequest<FormData> {
    return request.method === EHttpMethod.POST && InspectionInterceptor.POST_ENTITY_IMAGE_REGEX.test(request.url);
  }

  private _isPatchImageRequest(request: HttpRequest<unknown>): request is HttpRequest<{ caption?: string; isSpotlight?: boolean }> {
    return request.method === EHttpMethod.PATCH && InspectionInterceptor.PATCH_IMAGE_REGEX.test(request.url);
  }

  private _isPatchGlobalImageRequest(request: HttpRequest<unknown>): request is HttpRequest<{ caption?: string; isSpotlight?: boolean }> {
    return request.method === EHttpMethod.PATCH && InspectionInterceptor.PATCH_GLOBAL_IMAGE_REGEX.test(request.url);
  }

  private _isPutEntityImagesOrderRequest(
    request: HttpRequest<unknown>
  ): request is HttpRequest<{ imageIds: string[]; purchaseOrderProductId?: number }> {
    return request.method === EHttpMethod.PUT && InspectionInterceptor.PUT_ENTITY_IMAGES_ORDER_REGEX.test(request.url);
  }

  private _isPutGlobalImagesOrderRequest(request: HttpRequest<unknown>): request is HttpRequest<{ imageIds: string[] }> {
    return request.method === EHttpMethod.PUT && InspectionInterceptor.PUT_GLOBAL_IMAGES_ORDER_REGEX.test(request.url);
  }

  private _isPatchImagesRequest(
    request: HttpRequest<unknown>
  ): request is HttpRequest<{ imageIds: string[]; data: { caption?: string; isSpotlight?: boolean }; purchaseOrderProductId?: number }> {
    return request.method === EHttpMethod.PATCH && InspectionInterceptor.PATCH_IMAGES_REGEX.test(request.url);
  }

  private _isPatchGlobalImagesRequest(
    request: HttpRequest<unknown>
  ): request is HttpRequest<{ imageIds: string[]; data: { caption?: string; isSpotlight?: boolean }; purchaseOrderProductId?: number }> {
    return request.method === EHttpMethod.PATCH && InspectionInterceptor.PATCH_GLOBAL_IMAGES_REGEX.test(request.url);
  }

  private _isPostGlobalImageRequest(request: HttpRequest<unknown>): request is HttpRequest<FormData> {
    return request.method === EHttpMethod.POST && InspectionInterceptor.POST_GLOBAL_IMAGE_REGEX.test(request.url);
  }

  private _isPostAttachmentRequest(request: HttpRequest<unknown>): request is HttpRequest<FormData> {
    return request.method === EHttpMethod.POST && InspectionInterceptor.POST_ATTACHMENT_REGEX.test(request.url);
  }

  private _isDeleteAttachmentRequest(request: HttpRequest<unknown>): request is HttpRequest<FormData> {
    return request.method === EHttpMethod.DELETE && InspectionInterceptor.DELETE_ATTACHMENT_REGEX.test(request.url);
  }

  private _isPostSimplifiedMeasurementAttachmentRequest(request: HttpRequest<unknown>): request is HttpRequest<FormData> {
    return request.method === EHttpMethod.POST && InspectionInterceptor.POST_SIMPLIFIED_MEASUREMENT_ATTACHMENTS_REGEX.test(request.url);
  }

  private _isDeleteSimplifiedMeasurementAttachmentRequest(request: HttpRequest<unknown>): request is HttpRequest<FormData> {
    return request.method === EHttpMethod.DELETE && InspectionInterceptor.DELETE_SIMPLIFIED_MEASUREMENT_ATTACHMENTS_REGEX.test(request.url);
  }

  private _isPostDocumentsRequest(request: HttpRequest<unknown>): request is HttpRequest<FormData> {
    return request.method === EHttpMethod.POST && InspectionInterceptor.POST_DOCUMENT_REGEX.test(request.url);
  }

  private _isDeleteInspectionImageRequest(request: HttpRequest<unknown>): request is HttpRequest<IChecklistImageData> {
    return request.method === EHttpMethod.DELETE && InspectionInterceptor.DELETE_ACTION_IMAGE_REGEX.test(request.url);
  }

  private _isDeleteImageRequest(request: HttpRequest<unknown>): request is HttpRequest<IChecklistImageData> {
    return request.method === EHttpMethod.DELETE && InspectionInterceptor.DELETE_GLOBAL_IMAGE_REGEX.test(request.url);
  }

  private _isPutAQLReasonForChanges(
    request: HttpRequest<unknown>
  ): request is HttpRequest<{ reason: string; purchaseOrderProductId?: QimaOptionalType<number> }> {
    return request.method === EHttpMethod.PUT && this.PUT_AQL_REASON_FOR_CHANGES.test(request.url);
  }

  private _isPutSimplifiedMeasuresRequest(
    request: HttpRequest<unknown>
  ): request is HttpRequest<SimplifiedMeasurementChecklistMeasuresDTO> {
    return request.method === EHttpMethod.PUT && InspectionInterceptor.PUT_SIMPLIFIED_MEASURES_REGEX.test(request.url);
  }

  private _isPutSimplifiedMeasuresStatusRequest(
    request: HttpRequest<unknown>
  ): request is HttpRequest<SimplifiedMeasurementChecklistStatusDTO> {
    return request.method === EHttpMethod.PUT && InspectionInterceptor.PUT_SIMPLIFIED_MEASURES_STATUS_REGEX.test(request.url);
  }

  private _isPutSimplifiedMeasurementCommentRequest(request: HttpRequest<unknown>): request is HttpRequest<{ comment: string }> {
    return request.method === EHttpMethod.PUT && InspectionInterceptor.PUT_SIMPLIFIED_MEASUREMENT_COMMENT_REGEX.test(request.url);
  }

  private _isAbortInspectionRequest(request: HttpRequest<unknown>): boolean {
    return request.method === 'PATCH' && InspectionInterceptor.PATCH_ABORT_INSPECTION.test(request.url);
  }
}

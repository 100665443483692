import { QpLoggerService } from '@library/services/qp-logger/qp-logger.service';
import { Injectable } from '@angular/core';
import { SwUpdate, VersionEvent, VersionReadyEvent } from '@angular/service-worker';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs';

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class SwLogUpdateService {
  public constructor(
    private readonly _swUpdate: SwUpdate,
    private readonly _qpLoggerService: QpLoggerService
  ) {}

  public init(): void {
    this._swUpdate.versionUpdates
      .pipe(
        filter((event: VersionEvent): event is VersionReadyEvent => event.type === 'VERSION_READY'),
        untilDestroyed(this)
      )
      .subscribe({
        next: (event): void => {
          this._qpLoggerService.info('📣 Current version is', event.currentVersion);
          this._qpLoggerService.info('📣 Available version is', event.latestVersion);
        },
        error: (error: Error): void => {
          this._qpLoggerService.error(error);
        },
      });

    this._swUpdate
      .activateUpdate()
      .then((isUpdateActivated: boolean): void => {
        if (isUpdateActivated) {
          this._qpLoggerService.info('📣 Update activated successfully');
        } else {
          this._qpLoggerService.info('📣 No new update activated');
        }
      })
      .catch((error: Error): void => {
        this._qpLoggerService.error(error);
      });
  }
}

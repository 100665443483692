import { EQimaInputBorder } from '../../abstract-input/enums/qima-input-border.enum';
import { EQimaInputSize } from '../../abstract-input/enums/qima-input-size.enum';
import { EQimaInputNumberPosition } from '../enums/qima-input-number-position.enum';
import { InjectionToken } from '@angular/core';
const INPUT_NUMBER_STEP_DEFAULT = 1;
/**
 * @description
 * The token consumed by the {@link QimaInputNumberComponent} to define the default values of its inputs
 * @type {InjectionToken<IQimaInputNumberConfig>}
 */
export const QIMA_INPUT_NUMBER_CONFIG_TOKEN = new InjectionToken('The default values used by the inputs of the input number component', {
  factory() {
    /**
     * The fallback values should be identical as the fallback [provider]{@link QimaInputNumberModule#forRoot} values
     */
    return {
      border: EQimaInputBorder.FULL,
      hasStepperButtons: false,
      hasValidators: false,
      isDisabled: false,
      // eslint-disable-next-line no-restricted-globals
      maximum: Number.MAX_SAFE_INTEGER,
      minimum: 0,
      placeholder: '',
      position: EQimaInputNumberPosition.LEFT,
      shouldAutoFocus: false,
      hasClearButton: true,
      size: EQimaInputSize.MEDIUM,
      step: INPUT_NUMBER_STEP_DEFAULT,
      inputmode: undefined
    };
  }
});

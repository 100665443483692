import { EQimaButtonBorderRadiusStyle } from '../../button/enums/qima-button-border-radius-style.enum';
import { EQimaButtonSize } from '../../button/enums/qima-button-size.enum';
import { EQimaButtonIconStyle } from '../enums/qima-button-icon-style.enum';
import { InjectionToken } from '@angular/core';
/**
 * @description
 * The token consumed by the {@link QimaButtonIconComponent} to define the default values of its inputs
 * @type {InjectionToken<IQimaButtonIconConfig>}
 */
export const QIMA_BUTTON_ICON_CONFIG_TOKEN = new InjectionToken('The default values used by the inputs of the button icon component', {
  factory() {
    /**
     * The fallback values should be identical as the fallback [provider]{@link QimaButtonIconModule#forRoot} values
     */
    return {
      isDisabled: false,
      style: EQimaButtonIconStyle.PRIMARY,
      size: EQimaButtonSize.SMALL,
      borderRadiusStyle: EQimaButtonBorderRadiusStyle.ALL_SIDES,
      shouldAutoFocus: false,
      isLoading: false
    };
  }
});

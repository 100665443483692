// @ts-strict-ignore
import { IQpPageQuery } from '@library/classes/qp-page/qp-page.models';
import { PurchaseOrderViewDTO } from '@library/dto/purchase-order-view.dto';
import { qpEnumValuesToArray } from '@library/functions/enums/qp-enum-values-to-array';
import { QpDateService } from '@library/services/qp-date/qp-date.service';
import { SERVER_API_URL } from '@one/app/app.constants';
import {
  EBrdPurchaseOrderStatus,
  IPurchaseOrderInfo,
  IPurchaseOrderPage,
  PurchaseOrderPage,
} from '@one/app/pages/brd/pages/product-po/pages/po/models/purchase-orders.model';
import {
  IPurchaseOrder,
  IPurchaseOrderDetail,
  IPurchaseOrderEntities,
  IPurchaseOrderPost,
  IPurchaseOrderPut,
} from '@one/app/shared/models/purchase-orders/purchase-orders.models';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PurchaseOrderService {
  private readonly _resourceUrl = `${SERVER_API_URL}api/purchase-orders`;

  public constructor(
    protected _http: HttpClient,
    private readonly _qpDateService: QpDateService
  ) {}

  public createPurchaseOrder$(purchaseOrder: IPurchaseOrderPost): Observable<HttpResponse<IPurchaseOrder>> {
    return this._http.post<IPurchaseOrder>(this._resourceUrl, purchaseOrder, { observe: 'response' });
  }

  public getPurchaseOrder$(id: number): Observable<PurchaseOrderViewDTO> {
    const params: HttpParams = new HttpParams().set('usage', 'VIEW');

    return this._http.get<PurchaseOrderViewDTO>(`${this._resourceUrl}/${id}`, { params });
  }

  public getPurchaseOrderByUrl$(url: string): Observable<PurchaseOrderViewDTO> {
    /**
     * BE could return e.g. '/api/purchase-orders/12043' instead of 'api/purchase-orders/12043'
     */
    const finalUrl: string = url.substring(1);
    const params: HttpParams = new HttpParams().set('usage', 'VIEW');

    return this._http.get<PurchaseOrderViewDTO>(`${SERVER_API_URL}${finalUrl}`, { params, observe: 'body' });
  }

  public updatePurchaseOrder$(purchaseOrder: IPurchaseOrderPut): Observable<IPurchaseOrder> {
    return this._http.put<IPurchaseOrder>(`${this._resourceUrl}/${purchaseOrder.id}`, purchaseOrder, { observe: 'body' });
  }

  public cancelPurchaseOrder$(id: Readonly<number>): Observable<IPurchaseOrder> {
    return this._http.patch<IPurchaseOrder>(
      `${this._resourceUrl}/${id}`,
      {
        status: EBrdPurchaseOrderStatus.CANCELLED,
      },
      { observe: 'body' }
    );
  }

  public deletePurchaseOrder$(id: Readonly<number>): Observable<IPurchaseOrder> {
    return this._http.request<IPurchaseOrder>('delete', `${this._resourceUrl}/${id}`, {
      body: {
        deletionTimezone: this._qpDateService.getTimezone(),
        deletionDate: this._qpDateService.getDate(),
      },
    });
  }

  public getUninspectedPurchaseOrderAsPage$(pageQuery: IQpPageQuery): Observable<IPurchaseOrderPage> {
    let { page } = pageQuery;

    if (page !== undefined) {
      page += 1;
    }

    return this._http
      .get<IPurchaseOrderInfo[]>(this._resourceUrl, {
        params: {
          usage: 'UNINSPECTED',
          page: String(pageQuery.page),
          size: String(pageQuery.size),
          sort: 'reference,desc',
          filter: `status,IN,[${qpEnumValuesToArray(EBrdPurchaseOrderStatus)
            .filter((currentStatus: EBrdPurchaseOrderStatus): boolean => currentStatus !== EBrdPurchaseOrderStatus.CANCELLED)
            .toString()}]`,
        },
        observe: 'response',
      })
      .pipe(
        map((res: HttpResponse<IPurchaseOrderInfo[]>): PurchaseOrderPage => {
          const poRows: IPurchaseOrderInfo[] = res.body;
          const totalItems: number = parseInt(res.headers.get('X-Total-Count'), 10);

          return new PurchaseOrderPage(poRows, page, totalItems, pageQuery.size);
        })
      );
  }

  public getPurchaseOrderAsPage$(pageQuery: IQpPageQuery): Observable<IPurchaseOrderPage> {
    let { page } = pageQuery;

    if (page !== undefined) {
      page += 1;
    }

    return this._http
      .get<IPurchaseOrderInfo[]>(this._resourceUrl, {
        params: {
          page: String(pageQuery.page),
          size: String(pageQuery.size),
          sort: 'reference,desc',
        },
        observe: 'response',
      })
      .pipe(
        map((res): PurchaseOrderPage => {
          const poRows: IPurchaseOrderInfo[] = res.body;
          const totalItems: number = parseInt(res.headers.get('X-Total-Count'), 10);

          return new PurchaseOrderPage(poRows, page, totalItems, pageQuery.size);
        })
      );
  }

  public getPurchaseOrderForEdit$(id: number): Observable<IPurchaseOrder> {
    return this._http.get<IPurchaseOrder>(`${this._resourceUrl}/${id}`, {
      params: { usage: 'EDIT' },
    });
  }

  public getPurchaseOrderForView$(id: number): Observable<IPurchaseOrderDetail> {
    return this._http.get<IPurchaseOrderDetail>(`${this._resourceUrl}/${id}`, {
      params: { usage: 'VIEW' },
    });
  }

  public getPurchaseOrderEntities$(purchaseOrderId: number): Observable<IPurchaseOrderEntities> {
    return this._http.get<IPurchaseOrderEntities>(`${this._resourceUrl}/${purchaseOrderId}/entities`);
  }
}

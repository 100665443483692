{
  "name": "frontend",
  "version": "2.92.0",
  "description": "Description for QIMABrandFront",
  "private": true,
  "license": "UNLICENSED",
  "_sideEffects-comment": "It helps webpack to tree-shake more but at false it will break the local build of the app...",
  "sideEffects": true,
  "engines": {
    "node": "22.12.0",
    "npm": "10.9.0"
  },
  "scripts": {
    "artifact-registry-login": "sh ./authQimaOne.sh",
    "ng": "cross-env NODE_OPTIONS=--max_old_space_size=8192 ng",
    "start": "npm run ng -- serve --project=one -c dev --hmr",
    "start:one:localhost": "npm run ng -- serve --project=one -c localhost",
    "start:admin": "npm run ng -- serve --project=admin -c dev --hmr",
    "build-nearley-grammar": "node build-nearley-grammar.js",
    "build:one": "npm run ng -- build --configuration production --project=one",
    "build:admin": "npm run ng -- build --configuration production --project=admin",
    "build:one:stats": "npm run ng -- build --configuration production --stats-json --project=one",
    "build:pdf-report:stats": "npm run ng -- build --configuration production --stats-json --project=pdf-report",
    "generate-certificate:one": "openssl req -new -x509 -newkey rsa:2048 -sha256 -nodes -keyout projects/one/ssl/one.key -days 666 -out projects/one/ssl/one.crt -config projects/one/ssl/certificate.cnf",
    "run-jest": "cross-env NODE_OPTIONS=--max_old_space_size=3500 npx --package jest@29 --yes jest",
    "test:all": "npm run run-jest -- --logHeapUsage",
    "test:all:coverage": "npm run run-jest -- --coverage --logHeapUsage",
    "test:all:fast": "npm run run-jest -- --maxWorkers=50%",
    "test:admin": "npm run run-jest -- --logHeapUsage --config projects/admin/src/test/javascript/jest.config.js",
    "test:admin:coverage": "npm run run-jest -- --coverage --logHeapUsage --config projects/admin/src/test/javascript/jest.config.js",
    "test:admin:ci": "npm run run-jest -- --ci --runInBand --coverage --config projects/admin/src/test/javascript/jest.config.js --silent $(circleci tests glob \"projects/admin/**/*.spec.ts\" | circleci tests split --split-by=timings)",
    "test:admin:fast": "npm run run-jest -- --maxWorkers=50% --config projects/admin/src/test/javascript/jest.config.js",
    "test:one": "npm run run-jest -- --logHeapUsage --config projects/one/src/test/javascript/jest.config.js",
    "test:one:coverage": "npm run run-jest -- --coverage --logHeapUsage --config projects/one/src/test/javascript/jest.config.js",
    "test:one:ci": "npm run run-jest -- --ci --runInBand --coverage --silent --config projects/one/src/test/javascript/jest.config.js $(circleci tests glob \"projects/one/**/*.spec.ts\" | circleci tests split --split-by=timings)",
    "test:one:fast": "npm run run-jest -- --maxWorkers=50% --config projects/one/src/test/javascript/jest.config.js",
    "test:library": "npm run run-jest -- --logHeapUsage --config projects/library/test/javascript/jest.config.js",
    "test:library:coverage": "npm run run-jest -- --coverage --logHeapUsage --config projects/library/test/javascript/jest.config.js",
    "test:library:ci": "npm run run-jest -- --config projects/library/test/javascript/jest.config.js --ci --runInBand --coverage --silent $(circleci tests glob \"projects/library/**/*.spec.ts\" | circleci tests split --split-by=timings)",
    "test:library:fast": "npm run run-jest -- --maxWorkers=50% --config projects/library/test/javascript/jest.config.js",
    "start:pdf-report": "npm run ng -- serve --project=pdf-report --hmr",
    "build:pdf-report": "npm run ng -- build --configuration production --project=pdf-report",
    "test:pdf-report": "npm run run-jest -- --logHeapUsage --config projects/pdf-report/src/test/javascript/jest.config.js",
    "test:pdf-report:coverage": "npm run run-jest -- --coverage --logHeapUsage --config projects/pdf-report/src/test/javascript/jest.config.js",
    "test:pdf-report:ci": "npm run run-jest -- --ci --runInBand --coverage --silent --config projects/pdf-report/src/test/javascript/jest.config.js",
    "test:pdf-report:fast": "npm run run-jest -- --maxWorkers=50% --config projects/pdf-report/src/test/javascript/jest.config.js",
    "test:watch": "npm run run-jest -- --watch",
    "eslint": "cross-env NODE_OPTIONS=--max_old_space_size=3500 eslint",
    "lint:code": "npm run eslint -- --quiet --fix --fix-type suggestion,layout,problem --cache --cache-location .eslintcache \"**/*.{js,ts,html}\" --format stylish",
    "lint:code:timings": "cross-env TIMING=1 npm run lint:code",
    "lint:code:hook": "cross-env TIMING=1 npm run eslint -- --quiet --fix --fix-type suggestion,layout,problem --cache --cache-location .eslintcache --format stylish",
    "lint:code:ci": "npm run eslint -- --quiet --cache --cache-location .eslintcache $(circleci tests glob \"projects/**/*.{js,ts,html}\" | circleci tests split --split-by=timings) --format stylish",
    "lint:code:admin": "npm run lint:code -- --ignore-pattern projects/library --ignore-pattern projects/one --ignore-pattern projects/pdf-report",
    "lint:code:admin:ci": "npm run lint:code:ci -- --ignore-pattern projects/library --ignore-pattern projects/one --ignore-pattern projects/pdf-report",
    "lint:code:library": "npm run lint:code -- --ignore-pattern projects/admin --ignore-pattern projects/one --ignore-pattern projects/pdf-report",
    "lint:code:library:ci": "npm run lint:code:ci -- --ignore-pattern projects/admin --ignore-pattern projects/one --ignore-pattern projects/pdf-report",
    "lint:code:one": "npm run lint:code -- --ignore-pattern projects/admin --ignore-pattern projects/library --ignore-pattern projects/pdf-report",
    "lint:code:one:ci": "npm run lint:code:ci -- --ignore-pattern projects/admin --ignore-pattern projects/library --ignore-pattern projects/pdf-report",
    "lint:code:pdf-report": "npm run lint:code -- --ignore-pattern projects/library --ignore-pattern projects/one --ignore-pattern projects/admin",
    "lint:code:pdf-report:ci": "npm run lint:code:ci -- --ignore-pattern projects/library --ignore-pattern projects/one --ignore-pattern projects/admin",
    "lint:i18n": "find projects -regex \".*/*/src/assets/i18n/.*json\" -print0 | xargs -n 1 -0 -I {} npm run lint:i18n-file -- {}",
    "lint:i18n-file": "jsonlint --sort-keys --in-place --quiet",
    "lint:all": "npm run concurrently -- \"npm:lint:i18n\" \"npm:lint:code\" \"npm:lint:other\" \"npm:lint:style\" --prefix-colors green,cyan,magenta,red",
    "lint:all:ci": "npm run concurrently -- \"npm:lint:i18n\" \"npm:lint:code:ci\" \"npm:lint:other:ci\" \"npm:lint:style:ci\" --prefix-colors green,cyan,magenta,red",
    "lint:other": "prettier --write --ignore-unknown \"**/*.{md,mdx,json,yml,webmanifest}\"",
    "lint:other:ci": "prettier --check --ignore-unknown \"**/*.{md,mdx,json,yml,webmanifest}\"",
    "lint:other:hook": "prettier --write --ignore-unknown",
    "stylelint": "stylelint --config-basedir node_modules --color --custom-syntax postcss-scss",
    "lint:style": "npm run stylelint -- \"**/*.{css,scss}\" --cache --fix --quiet",
    "lint:style:hook": "npm run stylelint -- --cache --fix --quiet",
    "lint:style:ci": "npm run stylelint -- \"**/*.{css,scss}\" --cache --quiet",
    "tsc-stricts:all": "npm run concurrently -- \"npm:tsc-stricts:one\" \"npm:tsc-stricts:admin\" \"npm:tsc-stricts:library\" \"npm:tsc-stricts:pdf-report\"",
    "tsc-stricts:one": "tsc-strict --p projects/one",
    "tsc-stricts:admin": "tsc-strict --p projects/admin",
    "tsc-stricts:library": "tsc-strict --p projects/library",
    "tsc-stricts:pdf-report": "tsc-strict --p projects/pdf-report",
    "maildev": "npx maildev -s 2525",
    "docs:json": "cross-env NODE_OPTIONS=--max_old_space_size=3500 compodoc --config .compodocrc.json",
    "storybook": "cross-env STORYBOOK_ANGULAR_PROJECT=one npm run ng -- run one:storybook",
    "storybook:without-docs": "cross-env STORYBOOK_ANGULAR_PROJECT=one npm run ng -- run one:storybook-without-docs",
    "build-storybook": "cross-env STORYBOOK_ANGULAR_PROJECT=one npm run ng -- run one:build-storybook",
    "build-storybook:without-docs": "cross-env STORYBOOK_ANGULAR_PROJECT=one npm run ng -- run one:build-storybook-without-docs",
    "concurrently": "cross-env NODE_OPTIONS=--max_old_space_size=3500 concurrently --kill-others-on-fail --prefix \"[{name}][{time}]\" --timestamp-format \"HH:mm:ss\""
  },
  "jestSonar": {
    "reportPath": "build/test-results/jest",
    "reportFile": "TESTS-results-sonar.xml"
  },
  "dependencies": {
    "@angular/animations": "17.3.12",
    "@angular/cdk": "17.3.10",
    "@angular/cdk-experimental": "17.3.10",
    "@angular/common": "17.3.12",
    "@angular/compiler": "17.3.12",
    "@angular/core": "17.3.12",
    "@angular/forms": "17.3.12",
    "@angular/localize": "17.3.12",
    "@angular/platform-browser": "17.3.12",
    "@angular/platform-browser-dynamic": "17.3.12",
    "@angular/router": "17.3.12",
    "@angular/service-worker": "17.3.12",
    "@ngneat/reactive-forms": "5.0.2",
    "@ngneat/until-destroy": "9.2.3",
    "@ngrx/component-store": "17.2.0",
    "@ngrx/effects": "17.2.0",
    "@ngrx/router-store": "17.2.0",
    "@ngrx/store": "17.2.0",
    "@ngrx/store-devtools": "17.2.0",
    "@ngx-translate/core": "14.0.0",
    "@ngx-translate/http-loader": "7.0.0",
    "@qima/ngx-qima": "17.0.1",
    "@qimaone/speech-commands": "1.2.2",
    "angular-oauth2-oidc": "15.0.1",
    "angular-svg-icon": "17.0.0",
    "autolinker": "4.0.2",
    "autosize": "5.0.2",
    "awesome-phonenumber": "2.27.0",
    "blueimp-canvas-to-blob": "3.29.0",
    "blueimp-load-image": "5.16.0",
    "browser-image-compression": "2.0.2",
    "country-flags-svg": "1.1.9",
    "dayjs": "1.11.13",
    "dexie": "3.2.7",
    "fabric": "6.5.3",
    "fracty": "1.0.12",
    "hammerjs": "2.0.8",
    "image-quality": "github:asiainspection/image-quality#v2.0.3",
    "immer": "9.0.21",
    "js-base64": "3.7.7",
    "jwt-decode": "4.0.0",
    "libphonenumber-js": "1.10.64",
    "localforage": "1.10.0",
    "lodash-es": "4.17.21",
    "logrocket": "2.1.3",
    "masonry-layout": "4.2.2",
    "mixpanel-browser": "2.49.0",
    "nearley": "2.20.1",
    "ng-zorro-antd": "17.4.1",
    "ngrx-store-localstorage": "17.0.0",
    "ngx-cookie": "6.0.1",
    "ngx-infinite-scroll": "17.0.1",
    "ngx-masonry": "14.0.1",
    "ngx-webstorage": "13.0.1",
    "papaparse": "5.4.1",
    "piexifjs": "1.0.6",
    "qrcode": "1.5.4",
    "query-string": "6.14.1",
    "rxjs": "7.8.1",
    "scandit-web-datacapture-barcode": "6.23.4",
    "scandit-web-datacapture-core": "6.23.4",
    "tslib": "2.6.3",
    "uuid": "9.0.1",
    "zone.js": "0.14.10"
  },
  "devDependencies": {
    "@angular-builders/jest": "17.0.3",
    "@angular-devkit/build-angular": "17.3.11",
    "@angular-eslint/builder": "17.5.3",
    "@angular-eslint/eslint-plugin": "17.5.3",
    "@angular-eslint/eslint-plugin-template": "17.5.3",
    "@angular-eslint/schematics": "17.5.3",
    "@angular-eslint/template-parser": "17.5.3",
    "@angular/cli": "17.3.11",
    "@angular/compiler-cli": "17.3.12",
    "@angular/language-service": "17.3.12",
    "@babel/core": "7.24.9",
    "@compodoc/compodoc": "1.1.26",
    "@mdx-js/react": "2.1.5",
    "@ngneat/spectator": "15.0.1",
    "@signal-noise/stylelint-scales": "2.0.3",
    "@storybook/addon-actions": "7.6.20",
    "@storybook/addon-docs": "7.6.20",
    "@storybook/addon-essentials": "7.6.20",
    "@storybook/addon-links": "7.6.20",
    "@storybook/angular": "7.6.20",
    "@storybook/builder-webpack5": "7.6.20",
    "@storybook/manager-webpack5": "6.5.16",
    "@types/apple-mapkit-js-browser": "5.65.7",
    "@types/autosize": "4.0.3",
    "@types/blueimp-load-image": "5.16.6",
    "@types/fabric": "3.6.9",
    "@types/faker": "4.1.12",
    "@types/hammerjs": "2.0.46",
    "@types/jest": "29.5.14",
    "@types/jest-axe": "3.5.9",
    "@types/lodash-es": "4.17.12",
    "@types/maildev": "0.0.7",
    "@types/mdx": "2.0.13",
    "@types/mixpanel-browser": "2.49.1",
    "@types/nearley": "2.11.5",
    "@types/node": "14.14.45",
    "@types/papaparse": "5.3.15",
    "@types/uuid": "8.3.4",
    "@typescript-eslint/eslint-plugin": "5.62.0",
    "@typescript-eslint/parser": "5.62.0",
    "concurrently": "6.5.1",
    "cross-env": "7.0.3",
    "eslint": "8.57.1",
    "eslint-config-google": "0.14.0",
    "eslint-config-prettier": "9.1.0",
    "eslint-import-resolver-typescript": "3.6.3",
    "eslint-plugin-import": "2.29.1",
    "eslint-plugin-jest": "25.7.0",
    "eslint-plugin-jsdoc": "37.9.7",
    "eslint-plugin-prefer-arrow": "1.2.3",
    "eslint-plugin-prettier": "5.2.1",
    "eslint-plugin-progress": "0.0.1",
    "eslint-plugin-rxjs": "5.0.3",
    "eslint-plugin-rxjs-angular": "2.0.1",
    "faker": "4.1.0",
    "jest": "29.7.0",
    "jest-axe": "7.0.1",
    "jest-canvas-mock": "2.5.2",
    "jest-environment-jsdom": "29.7.0",
    "jest-extended": "3.2.4",
    "jest-junit": "13.2.0",
    "jest-marbles": "3.0.6",
    "jest-preset-angular": "14.2.4",
    "jest-sonar-reporter": "2.0.0",
    "jsonlint-mod": "github:asiainspection/jsonlint-mod#v1.6.0-qima",
    "lint-staged": "10.5.4",
    "maildev": "2.1.0",
    "mapkit-typescript": "5.18.2",
    "postcss-scss": "4.0.9",
    "prettier": "3.4.2",
    "raw-loader": "4.0.2",
    "react": "16.14.0",
    "react-dom": "16.14.0",
    "reflect-metadata": "0.1.14",
    "stylelint": "16.12.0",
    "stylelint-config-standard": "36.0.1",
    "stylelint-config-standard-scss": "14.0.0",
    "stylelint-order": "6.0.4",
    "stylelint-prettier": "5.0.2",
    "stylelint-scss": "6.10.0",
    "terser": "4.8.1",
    "to-string-loader": "1.2.0",
    "ts-jest": "29.2.5",
    "ts-node": "10.9.2",
    "tsutils": "3.21.0",
    "typescript": "5.4.5",
    "typescript-strict-plugin": "2.3.2"
  },
  "overrides": {
    "@storybook/addon-actions": "7.6.20"
  }
}

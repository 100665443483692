import { EQpProfile } from '@library/models/qp-profile.models';
import { AccountService } from '@one/app/shared/services/account/account.service';
import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'one-home',
  template: '',
  standalone: true,
})
export class HomeComponent {
  public constructor(
    private readonly _accountService: AccountService,
    private readonly _router: Router
  ) {
    void this.checkAuthority();
  }

  public async checkAuthority(): Promise<void> {
    const brandId = this._accountService.getBrandId();
    const inspectorsProfiles = [EQpProfile.ROLE_INSPECTOR, EQpProfile.ROLE_SERVICE_PROVIDER_INSPECTOR];

    if (this._accountService.hasAnyAuthority(inspectorsProfiles)) {
      this._navigateTo('/isp');
    } else if ((await this._accountService.hasAuthority(EQpProfile.ROLE_SUPERVISOR)) && brandId) {
      this._navigateTo('/brd');
    } else if (await this._accountService.hasAuthority(EQpProfile.ROLE_SUPERVISOR)) {
      this._navigateTo('/select-brand');
    } else if (this._accountService.hasAnyAuthority([EQpProfile.ROLE_BRAND, EQpProfile.ROLE_ENTITY])) {
      this._navigateTo('/brd');
    } else if (this._accountService.hasAnyAuthority([EQpProfile.ROLE_SERVICE_PROVIDER])) {
      this._navigateTo('/sp');
    } else {
      this._navigateTo('/404');
    }
  }

  private _navigateTo(path: string): void {
    void this._router.navigate([path]);
  }
}

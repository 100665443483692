/**
 * @license
 * Copyright 2018 Google LLC. All Rights Reserved.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 * =============================================================================
 */
// tslint:disable-next-line: no-imports-from-dist
import * as tfOps from '@tensorflow/tfjs-core/dist/ops/ops_for_converter';
import { getParamValue } from './utils';
export const executeOp = (node, tensorMap, context) => {
  switch (node.op) {
    case 'Fill':
      {
        const shape = getParamValue('shape', node, tensorMap, context);
        const dtype = getParamValue('dtype', node, tensorMap, context);
        const value = getParamValue('value', node, tensorMap, context);
        return [tfOps.fill(shape, value, dtype)];
      }
    case 'LinSpace':
      {
        const start = getParamValue('start', node, tensorMap, context);
        const stop = getParamValue('stop', node, tensorMap, context);
        const num = getParamValue('num', node, tensorMap, context);
        return [tfOps.linspace(start, stop, num)];
      }
    case 'Multinomial':
      {
        const logits = getParamValue('logits', node, tensorMap, context);
        const numSamples = getParamValue('numSamples', node, tensorMap, context);
        const seed = getParamValue('seed', node, tensorMap, context);
        return [tfOps.multinomial(logits, numSamples, seed)];
      }
    case 'OneHot':
      {
        const indices = getParamValue('indices', node, tensorMap, context);
        const depth = getParamValue('depth', node, tensorMap, context);
        const onValue = getParamValue('onValue', node, tensorMap, context);
        const offValue = getParamValue('offValue', node, tensorMap, context);
        return [tfOps.oneHot(indices, depth, onValue, offValue)];
      }
    case 'Ones':
      {
        return [tfOps.ones(getParamValue('shape', node, tensorMap, context), getParamValue('dtype', node, tensorMap, context))];
      }
    case 'OnesLike':
      {
        return [tfOps.onesLike(getParamValue('x', node, tensorMap, context))];
      }
    case 'RandomUniform':
      {
        return [tfOps.randomUniform(
        // tslint:disable-next-line:no-any
        getParamValue('shape', node, tensorMap, context), getParamValue('minval', node, tensorMap, context), getParamValue('maxval', node, tensorMap, context), getParamValue('dtype', node, tensorMap, context))];
      }
    case 'Range':
      {
        const start = getParamValue('start', node, tensorMap, context);
        const stop = getParamValue('stop', node, tensorMap, context);
        const step = getParamValue('step', node, tensorMap, context);
        return [tfOps.range(start, stop, step, getParamValue('dtype', node, tensorMap, context))];
      }
    case 'TruncatedNormal':
      {
        const shape = getParamValue('shape', node, tensorMap, context);
        const mean = getParamValue('mean', node, tensorMap, context);
        const stdDev = getParamValue('stdDev', node, tensorMap, context);
        const seed = getParamValue('seed', node, tensorMap, context);
        return [tfOps.truncatedNormal(shape, mean, stdDev, getParamValue('dtype', node, tensorMap, context), seed)];
      }
    case 'Zeros':
      {
        return [tfOps.zeros(getParamValue('shape', node, tensorMap, context), getParamValue('dtype', node, tensorMap, context))];
      }
    case 'ZerosLike':
      {
        return [tfOps.zerosLike(getParamValue('x', node, tensorMap, context))];
      }
    default:
      throw TypeError(`Node type ${node.op} is not implemented`);
  }
};
export const CATEGORY = 'creation';

import { InjectionToken } from '@angular/core';
class QimaDefaultDateService {
  format() {
    return 'Wrong date formatting';
  }
  getToday() {
    return 'Today!';
  }
  isValid() {
    return false;
  }
  isEqual() {
    return false;
  }
  getLastDateInWeek() {
    return 'The last date in the current week';
  }
  getFirstDateInWeek() {
    return 'The first date in the current week';
  }
  getDatesInMonth() {
    return [];
  }
  getMonthName() {
    return 'Month name!';
  }
  getWeekdayNames() {
    return [];
  }
  getNextMonthDate() {
    return 'Date of next month!';
  }
  getPreviousMonthDate() {
    return 'Date of previous month!';
  }
  getYear() {
    return 0;
  }
  isDateAfterMaximum() {
    return true;
  }
  isDateBeforeMinimum() {
    return true;
  }
}
/**
 * @description
 * The token consumed by the {@link QimaDateService} to handle dates
 * @type {InjectionToken<QimaDateFactory>}
 */
export const QIMA_DATE_FACTORY_TOKEN = new InjectionToken('The default factory used by the date service', {
  factory() {
    return new QimaDefaultDateService();
  }
});

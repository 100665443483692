// @ts-strict-ignore
import { EYesNoNa } from '@one/app/pages/isp/pages/inspection/pages/id/pages/categories/pages/id/pages/test/pages/id/components/tests-checklist-element/tests-checklist-element-yes-no/models/isp-inspection-id-categories-id-test-id-yes-no-na.models';
import { IAnswer } from '@one/app/shared/models/answer/yes-no-answer.models';
import { QimaOptionalType } from '@qima/ngx-qima';

export class YesNoAnswerModel implements IAnswer {
  public static newEmpty(): YesNoAnswerModel {
    return new YesNoAnswerModel(null, null);
  }

  private constructor(
    public value: QimaOptionalType<boolean | EYesNoNa>,
    public failureCount: number
  ) {}
}

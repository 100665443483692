import { EBrdInspectionStepTitle, EBrdInspectionStep } from '@one/app/pages/brd/pages/inspection/shared/models/step.models';
import { GoogleTagManagerService } from '@one/app/shared/services/third-party/analytics-manager/google-tag-manager/services/google-tag-manager.service';
import { IDataLayerBrdBookingInspectionEvent } from '@one/app/shared/services/third-party/analytics-manager/models/booking-inspection.models';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class GoogleTagManagerBookingInspectionService {
  private readonly _currentStepTitle: { [key in EBrdInspectionStep]: () => EBrdInspectionStepTitle } = {
    [EBrdInspectionStep.ProductTab](): EBrdInspectionStepTitle {
      return EBrdInspectionStepTitle.PRODUCT_TAB;
    },
    [EBrdInspectionStep.SiteTab](): EBrdInspectionStepTitle {
      return EBrdInspectionStepTitle.SITE_TAB;
    },
    [EBrdInspectionStep.InspectionTab](): EBrdInspectionStepTitle {
      return EBrdInspectionStepTitle.INSPECTION_TAB;
    },
    [EBrdInspectionStep.InspectorTab](): EBrdInspectionStepTitle {
      return EBrdInspectionStepTitle.INSPECTOR_TAB;
    },
    [EBrdInspectionStep.WorkflowTab](): EBrdInspectionStepTitle {
      return EBrdInspectionStepTitle.WORKFLOW_TAB;
    },
    [EBrdInspectionStep.SummaryTab](): EBrdInspectionStepTitle {
      return EBrdInspectionStepTitle.SUMMARY_TAB;
    },
  };

  public constructor(private readonly _googleTagManagerService: GoogleTagManagerService) {}

  public getBookingCurrentStepTitle(currentStep: EBrdInspectionStep): EBrdInspectionStepTitle {
    return this._currentStepTitle[currentStep]();
  }

  public sendBookingGoogleTagManagerEvent(event: Readonly<IDataLayerBrdBookingInspectionEvent>): void {
    this._googleTagManagerService.sendEvent({
      event: 'schedule_inspection',
      ...event,
    });
  }
}

import { InspectionStatus } from '@library/dto-enums/inspection-status.dto-enum';
import { IQpPosition } from '@library/models/qp-position';
import { InspectionId } from '@one/app/pages/isp/shared/models/isp-inspection.models';
import { AqlDefectsType } from '@one/app/shared/models/defects/aql-defects.models';
import { IInspectionConsultation } from '@one/app/shared/models/inspection-consultation/inspection-consultation.models';
import { IWorkflowSamplingSize, WorkflowSamplingSizeType } from '@one/app/shared/models/sampling/sampling-size.models';
import { createAction, props } from '@ngrx/store';
import { QimaOptionalType } from '@qima/ngx-qima';

export const UPDATE_INSPECTION_DATE = '[Inspection] Update inspection date';

export const UPDATE_INSPECTION_STATUS = '[Inspection] Status update';

export const UPDATE_INSPECTION_STATUS_WITHOUT_BACKEND_CALL = '[Inspection] Status update without backend call';

export const UPDATED_INSPECTION_STATUS_SUCCESS = '[Inspection] Started Success';

export const LOAD_INSPECTION = '[Inspection] Load Inspection';

export const LOAD_INSPECTION_SUCCESS = '[Inspection] Load Inspection Success';

export const LOAD_INSPECTION_ERROR_FORBIDDEN = '[Inspection] Load Inspection Error Forbidden';

export const CLEAR_INSPECTION = '[Inspection] Clear Inspection';

export const MARK_INSPECTION_AS_ABORTED = '[Inspection] Mark Inspection As Aborted';

export const MARK_INSPECTION_AS_ABORTED_SUCCESS = '[Inspection] Mark Inspection As Aborted Success';

export const UPDATE_SAMPLING_SIZES = '[Aql] Update Sampling Sizes';

export const UPDATE_SAMPLING_SIZES_SUCCESS = '[Aql] Update Sampling Sizes Success';

export const UPDATE_AQL_DEFECTS = '[Aql] Update Aql Defects';

export const UPDATE_AQL_DEFECTS_SUCCESS = '[Aql] Update Aql Defects Success';

export const UPDATE_AQL_REASON_FOR_CHANGES = '[Aql] Update Aql Reason For Changes';

export const UPDATE_AQL_REASON_FOR_CHANGES_SUCCESS = '[Aql] Update Aql Reason For Changes Success';

export const updateInspectionStatus = createAction(
  UPDATE_INSPECTION_STATUS,
  props<{
    id: InspectionId;
    inspectionUuid?: string;
    position: QimaOptionalType<IQpPosition>;
    status: InspectionStatus;
  }>()
);

export const updateInspectionStatusWithoutBackendCall = createAction(
  UPDATE_INSPECTION_STATUS_WITHOUT_BACKEND_CALL,
  props<{
    id: InspectionId;
    inspectionUuid?: string;
    position: QimaOptionalType<IQpPosition>;
    status: InspectionStatus;
  }>()
);

export const updateInspectionDate = createAction(UPDATE_INSPECTION_DATE, props<{ inspectionDate: Date }>());

export const updatedSuccessInspectionStatus = createAction(UPDATED_INSPECTION_STATUS_SUCCESS, props<{ status: InspectionStatus }>());

export const loadInspection = createAction(LOAD_INSPECTION, props<ILoadInspectionActionProps>());

export const loadInspectionSuccess = createAction(LOAD_INSPECTION_SUCCESS, props<{ inspection: IInspectionConsultation }>());

export const loadInspectionErrorForbidden = createAction(LOAD_INSPECTION_ERROR_FORBIDDEN);

export const clearInspection = createAction(CLEAR_INSPECTION);

export const updateSamplingSizes = createAction(
  UPDATE_SAMPLING_SIZES,
  props<{ samplingSize: WorkflowSamplingSizeType; purchaseOrderProductId?: QimaOptionalType<number> }>()
);

export const updateSamplingSizesSuccess = createAction(
  UPDATE_SAMPLING_SIZES_SUCCESS,
  props<{ samplingSize: IWorkflowSamplingSize; purchaseOrderProductId?: QimaOptionalType<number> }>()
);

export const updateAqlDefects = createAction(
  UPDATE_AQL_DEFECTS,
  props<{ aqlDefects: AqlDefectsType; purchaseOrderProductId?: QimaOptionalType<number> }>()
);

export const updateAqlDefectsSuccess = createAction(
  UPDATE_AQL_DEFECTS_SUCCESS,
  props<{ aqlDefects: AqlDefectsType; purchaseOrderProductId?: QimaOptionalType<number> }>()
);

export const updateAqlReasonForChanges = createAction(
  UPDATE_AQL_REASON_FOR_CHANGES,
  props<{ reason: string; purchaseOrderProductId?: QimaOptionalType<number> }>()
);

export const updateAqlReasonForChangesSuccess = createAction(
  UPDATE_AQL_REASON_FOR_CHANGES_SUCCESS,
  props<{ reason: string; purchaseOrderProductId?: QimaOptionalType<number> }>()
);

export const markInspectionAsAborted = createAction(MARK_INSPECTION_AS_ABORTED, props<{ isAborted: boolean }>());

export const markInspectionAsAbortedSuccess = createAction(MARK_INSPECTION_AS_ABORTED_SUCCESS, props<{ isAborted: boolean }>());

export interface IUpdateFindingsSummaryReviewAction {
  type: '[Workflow - Findings summary review] Confirm success';
}

export interface ILoadInspectionActionProps {
  id: InspectionId;
}

export interface ILoadInspectionErrorForbiddenAction {
  type: '[Inspection] Load Inspection Error Forbidden';
}

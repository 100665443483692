import { qimaGetSearchRegexp } from './qima-get-search-regexp';
import { replace, isEmpty } from 'lodash/index';
/**
 * @description
 * Wrap in an em the pieces of texts matched by the search
 * @param {Readonly<string>} label The label to search into
 * @param {Readonly<string>} search The text to search into the label
 * @returns {string} The label potentially wrapped in em(s)
 */
export function qimaSearch(label, search) {
  if (isEmpty(search)) {
    return label;
  }
  return replace(label, qimaGetSearchRegexp(search), '<em>$&</em>');
}

import { qimaGetSearchRegexp } from './qima-get-search-regexp';
import { isNil, isEmpty } from 'lodash/index';
/**
 * @description
 * From a given label, search the given text
 * @param {Readonly<string>} label The label to search into
 * @param {Readonly<string>} search The text to search into the label
 * @returns {boolean} Returns true when the search is matching something in the label
 */
export function qimaIsSearchMatching(label, search) {
  if (isEmpty(search)) {
    return true;
  }
  return !isNil(qimaGetSearchRegexp(search).exec(label));
}

import { IQpPosition, IQpPositionError } from '@library/models/qp-position';
import { QpLoggerService } from '@library/services/qp-logger/qp-logger.service';
import { EGeolocationPositionError } from '@one/app/pages/isp/shared/services/isp-coordinates.models';
import { Injectable } from '@angular/core';
import { from, Observable, throwError } from 'rxjs';

const GET_POSITION_TIMEOUT_IN_MS: number = 6000;

@Injectable({
  providedIn: 'root',
})
export class IspCoordinatesService {
  public constructor(private readonly _logger: QpLoggerService) {}

  public getPosition$(): Observable<IQpPosition> {
    if (navigator.geolocation) {
      return from(
        this._fetchPosition(false).catch((error: IQpPositionError): never => {
          this._logger.error('Impossible to retrieve the Geolocation even with API enabled', JSON.stringify(error));
          this._logger.error(error.message, error.code, error.POSITION_UNAVAILABLE, error.TIMEOUT, error.PERMISSION_DENIED);

          switch (error.code) {
            case error.PERMISSION_DENIED:
              throw new Error(EGeolocationPositionError.PERMISSION_DENIED);

            case error.POSITION_UNAVAILABLE:
              throw new Error(EGeolocationPositionError.POSITION_UNAVAILABLE);

            case error.TIMEOUT:
              throw new Error(EGeolocationPositionError.TIMEOUT);

            default:
              throw new Error(EGeolocationPositionError.UNKNOWN_ERROR);
          }
        })
      );
    }

    this._logger.warn('The Geolocation API is not enabled');

    return throwError((): Error => new Error(EGeolocationPositionError.API_UNAVAILABLE));
  }

  private _fetchPosition(shouldEnableHighAccuracy: Readonly<boolean>): Promise<IQpPosition> {
    return new Promise<IQpPosition>((resolve, reject): void => {
      navigator.geolocation.getCurrentPosition(
        (position: GeolocationPosition): void => resolve(position),
        (error: IQpPositionError): void => reject(error),
        {
          timeout: GET_POSITION_TIMEOUT_IN_MS,
          enableHighAccuracy: shouldEnableHighAccuracy,
        }
      );
    });
  }
}

/**
 * @description
 * Add a backslash prefix on some characters of the search
 * This is useful to avoid breaking the RegExp when searching meta-characters
 * @param {Readonly<string>} search The text used as a search
 * @returns {string} The search text escaped.
 */
function qimaGetEscapedSearch(search) {
  return search.replace(/./g, character => {
    return character.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&');
  });
}
/**
 * @param {Readonly<string>} search The search to perform
 * @returns {RegExp} The regexp that should be executed to find the passed search.
 */
export function qimaGetSearchRegexp(search) {
  return new RegExp(`${qimaGetEscapedSearch(search)}(?!([^<]+)?<)`, 'gi');
}

import { EQimaIceCubeSize } from '../../ice-cube/enums/qima-ice-cube-size.enum';
import { EQimaIceCubeStyle } from '../../ice-cube/enums/qima-ice-cube-style.enum';
import { InjectionToken } from '@angular/core';
/**
 * @description
 * The token consumed by the {@link QimaIceCubeIconComponent} to define the default values of its inputs
 * @type {InjectionToken<IQimaIceCubeIconConfig>}
 */
export const QIMA_ICE_CUBE_ICON_CONFIG_TOKEN = new InjectionToken('The default values used by the inputs of the ice cube icon component', {
  factory() {
    /**
     * The fallback values should be identical as the fallback [provider]{@link QimaIceCubeIconModule#forRoot} values
     */
    return {
      style: EQimaIceCubeStyle.RED,
      size: EQimaIceCubeSize.MEDIUM
    };
  }
});

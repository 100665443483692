import { IQpDotMenuAction } from '@library/components/qp-dot-menu/qp-dot-menu.models';
import { IBrdConsultationCustomFields } from '@one/app/pages/brd/shared/models/brd-product-consult.model';
import { IESInspectionConsultationProduct } from '@one/app/pages/brd/shared/models/es-inspection-consultation.model';

export interface IPurchaseOrderInfo {
  id: number;
  reference: string;
  products: IESInspectionConsultationProduct[];
  productsQuantity: number;
  orderDate: string;
  shipmentDate: string;
  entityName: string;
  brandName: string;
  createdDate: Date;
  lastModifiedDate: Date;
  status: EBrdPurchaseOrderStatus;
  tier1Name: string;
  tier1Id: number;
  actions?: IQpDotMenuAction[];
  tier2Name?: string;
  destinationId?: number;
  destinationName?: string;
  containMultipleShipments?: boolean;
  customFields: IBrdConsultationCustomFields[];
}

export interface IPurchaseOrderPage {
  poRows: IPurchaseOrderInfo[];
  page: number;
  totalItems: number;
  pageSize: number;
}

export enum EBrdPurchaseOrderStatus {
  CREATED = 'CREATED',
  CANCELLED = 'CANCELLED',
  USED = 'USED',
}

export class PurchaseOrderPage implements IPurchaseOrderPage {
  public static create(page: { poRows: IPurchaseOrderInfo[]; page: number; totalItems: number; pageSize: number }): IPurchaseOrderPage {
    return new PurchaseOrderPage(page.poRows, page.page, page.totalItems, page.pageSize);
  }

  public constructor(
    public poRows: IPurchaseOrderInfo[],
    public page: number,
    public totalItems: number,
    public pageSize: number
  ) {}
}

// @ts-strict-ignore
import { CustomFieldWithValueDTO } from '@library/dto/customfield/custom-field-with-value.dto';
import { CreateAqlDefectsDTO } from '@library/dto/inspection/create-inspection.dto';
import { SamplingSizeDTO } from '@library/dto/sampling-size.dto';
import { InspectionMethod } from '@library/dto-enums/inspection-method.dto-enum';
import { InspectionStatus } from '@library/dto-enums/inspection-status.dto-enum';
import { PictureSource } from '@library/dto-enums/picture-source.dto-enum';
import { qpEnumValuesToArray } from '@library/functions/enums/qp-enum-values-to-array';
import { IQpSimpleAddress, IQpSimpleAddressDto, QpSimpleAddress } from '@library/models/address/qp-address.model';
import { EAuditWorkflowTemplateStepName } from '@one/app/pages/brd/pages/workflow/shared/models/brd-workflow-audit-template.models';
import { IMeasurementChecklistProduct } from '@one/app/pages/isp/shared/models/variances/inspection-variances.models';
import { AqlDefectsType } from '@one/app/shared/models/defects/aql-defects.models';
import { IInspectionAdditionalInformation } from '@one/app/shared/models/inspection-consultation/inspection-consultation.models';
import { IMeasurementSamplingSize, ISamplingSize, SamplingSize } from '@one/app/shared/models/sampling/sampling-size.models';
import { IWorkflowCheckTemplate } from '@one/app/shared/models/workflow/workflow.models';
import {
  EMeasurementOverallResultCalculationMethod,
  EWorkflowMeasurementProof,
  EWorkflowTemplateStepActionInspectionPreparationCalculationMethod,
  EWorkflowTemplateStepName,
  IWorkflowTemplateStep,
  IWorkflowTemplateStepAction,
  IWorkflowTemplateStepActionDocument,
  WorkflowAnyStepActionTypeType,
} from '@one/app/shared/models/workflows/workflows-templates.models';
import { QimaOptionalType, QimaUuidType } from '@qima/ngx-qima';

export interface IBrdInspectionEdition {
  id: number;
  inspectionUuid?: string; // Set when creating an inspection offline
  createdOffline: boolean;
  version: number;
  notifyEntity: boolean;
  plannedDate: Date | string;
  entityId: number;
  delegatedById?: number;
  inspectorId?: number;
  products: IInspectionProduct[];
  workflowId?: number;
  inspectionTypeId: number;
  producedRatio: number;
  packedRatio: number;
  updateDate?: Date;
  updateTimezone?: string;
  isDefectsPerProduct: boolean;
  isAqlPerProduct: boolean;
  aqlDefects: AqlDefectsType;
  samplingSize: SamplingSize;
  measurementsSamplingSize?: IMeasurementSamplingSize;
  workflow?: IInspectionEditionWorkflow;
  draftInspectionId: string;
  referenceSample?: IBrdInspectionReferenceSample;
  sampleCollection?: ISampleCollection;
  method: InspectionMethod;
  status?: InspectionStatus;
  supplierRequest?: boolean;
  responsibleEntityId?: number;
  productionSiteEntityId?: number;
  brandId?: number;
  additionalInformation?: IInspectionAdditionalInformation;
  customFieldsWithValues?: CustomFieldWithValueDTO[];
}

export interface IInspectionEditionDto {
  id: number;
  inspectionUuid?: string; // Set when creating an inspection offline
  createdOffline: boolean;
  version: number;
  notifyEntity: boolean;
  plannedDate: Date;
  entityId: number;
  inspectorId?: number;
  products: IInspectionProduct[];
  workflowId: number;
  inspectionTypeId: number;
  producedRatio: number;
  packedRatio: number;
  updateDate?: Date;
  updateTimezone?: string;
  isDefectsPerProduct: boolean;
  isAqlPerProduct: boolean;
  aqlDefects: AqlDefectsType;
  samplingSize: ISamplingSize;
  measurementsSamplingSize?: IMeasurementSamplingSize;
  workflow?: IInspectionEditionWorkflow;
  draftInspectionId: string;
  referenceSample?: IBrdInspectionReferenceSample;
  sampleCollection?: ISampleCollectionDto;
  method: InspectionMethod;
  status?: InspectionStatus;
  supplierRequest?: boolean;
  delegatedById?: number;
  responsibleEntityId?: number;
  brandId?: number;
  additionalInformation?: IInspectionAdditionalInformation;
  customFieldsWithValues?: CustomFieldWithValueDTO[];
}

export enum ReferenceSampleFetchingMode {
  SEND_TO_INSPECTION_SITE = 'SEND_TO_INSPECTION_SITE',
  ALREADY_AT_INSPECTION_SITE = 'ALREADY_AT_INSPECTION_SITE',
}

export interface IInspectionProduct {
  productQuantity: number;
  productId: number;
  purchaseOrderId?: number;
  purchaseOrderProductId?: number;
  purchaseOrderReference?: string;
  brandId?: number;
  unit?: QimaOptionalType<string>;
  shipmentDate?: QimaOptionalType<Date>;
  identifierValue?: string;
  producedQuantity?: number;
  packedQuantity?: number;
  purchaseOrderDestinationId?: number;
  aqlDefects?: CreateAqlDefectsDTO; // required truthy isAqlPerProduct to exist
  samplingSize?: SamplingSizeDTO; // required truthy isAqlPerProduct to exist
}

export interface IBrdInspectionReferenceSample {
  fetchingMode: ReferenceSampleFetchingMode;
  trackingNumber?: QimaOptionalType<string>;
  carrierId: string;
  carrierName: string;
  isToPickup: boolean;
  instructions?: QimaOptionalType<string>;
  isCompleted: boolean;
}

export interface ISampleCollection {
  address: IQpSimpleAddress;
  volume: number;
  unit: string;
  percentageOfDefectiveSamplesVolume: number;
  percentageOfValidSamplesVolume: number;
  instructions?: string;
}

export interface ISampleCollectionDto {
  address: IQpSimpleAddressDto;
  volume: number;
  unit: string;
  percentageOfDefectiveSamplesVolume: number;
  percentageOfValidSamplesVolume: number;
  instructions?: string;
}

export class SampleCollection implements ISampleCollectionDto {
  public static create(sampleCollectionDto: ISampleCollectionDto): ISampleCollection {
    return {
      volume: sampleCollectionDto.volume,
      unit: sampleCollectionDto.unit,
      percentageOfDefectiveSamplesVolume: sampleCollectionDto.percentageOfDefectiveSamplesVolume,
      percentageOfValidSamplesVolume: sampleCollectionDto.percentageOfValidSamplesVolume,
      instructions: sampleCollectionDto.instructions,
      address: QpSimpleAddress.create(sampleCollectionDto.address),
    };
  }

  public address: IQpSimpleAddressDto;
  public volume: number;
  public unit: string;
  public percentageOfDefectiveSamplesVolume: number;
  public percentageOfValidSamplesVolume: number;
  public instructions?: string;
}

/**
 * @description
 * Warning: this type can be a duplicate of a better workflow now (dunno it's hard to tell)
 * It currently contains only what's the code needs at this improvement date so feel free to kick it or update it to include more props
 * if needed
 */
export interface IInspectionEditionWorkflow {
  workflowTemplateId: number;
  content?: {
    steps: IInspectionEditionWorkflowStep[];
  };
}

export interface IInspectionEditionWorkflowStep {
  id?: string;
  name: EWorkflowTemplateStepName;
  actions?: IInspectionEditionWorkflowStepAction[];
}

export class InspectionEditionWorkflowStep implements IInspectionEditionWorkflowStep {
  public static from(step: IWorkflowTemplateStep): IInspectionEditionWorkflowStep | never {
    if (InspectionEditionWorkflowStep._isWorklowStepName(step.name)) {
      return new InspectionEditionWorkflowStep(step.id, step.name, InspectionEditionWorkflowStepAction.create(step.actions));
    }

    throw new Error(`The step doesn't belong to inspection workflow`);
  }

  private static _isWorklowStepName(name: EWorkflowTemplateStepName | EAuditWorkflowTemplateStepName): name is EWorkflowTemplateStepName {
    return qpEnumValuesToArray(EWorkflowTemplateStepName).includes(name);
  }

  private constructor(
    public id: string,
    public name: EWorkflowTemplateStepName,
    public actions: IInspectionEditionWorkflowStepAction[]
  ) {}
}

export interface IInspectionEditionWorkflowStepAction {
  actionName?: string;
  type: WorkflowAnyStepActionTypeType;
  id?: string;
  uuid?: QimaUuidType;
  sampleCollection?: ISampleCollection;
  referenceSample?: IBrdInspectionReferenceSample;
  checks?: IWorkflowCheckTemplate[];
  document?: IWorkflowTemplateStepActionDocument;
  ccRecipients?: string[];
  bccRecipients?: string[];
  testsChecklistId?: number;
  defectsChecklistId?: number;
  documentId?: string;
  products?: IMeasurementChecklistProduct[];
  calculationMethod?: EWorkflowTemplateStepActionInspectionPreparationCalculationMethod;
  updatableByInspector?: boolean;
  canInspectionBeMarkedAsPending?: boolean;
  instructions?: string;
  commentsMandatoryWhenNotApplicable?: boolean;
  commentsMandatoryWhenDefectsFound?: boolean;
  photosMandatoryWhenDefectsFound?: boolean;
  fileId?: string;
  text?: string;
  // properties for SIMPLIFIED_MEASUREMENTS_CHECKLIST
  measurementOverallResultCalculationMethod?: EMeasurementOverallResultCalculationMethod;
  expectedResult?: string;
  isCommentRequiredWhenMarkedAsNA?: boolean;
  requiredProofs?: EWorkflowMeasurementProof[];
  // properties for STARTING_PICTURES
  pictureSource?: PictureSource;
}

export class InspectionEditionWorkflowStepAction implements IInspectionEditionWorkflowStepAction {
  public static create(stepActions: IWorkflowTemplateStepAction[]): IInspectionEditionWorkflowStepAction[] {
    const editionStepActions: IInspectionEditionWorkflowStepAction[] = [];

    stepActions.forEach((stepAction: IWorkflowTemplateStepAction): void => {
      editionStepActions.push({
        actionName: stepAction.actionName ?? stepAction.name,
        id: stepAction.id,
        uuid: stepAction.uuid,
        type: stepAction.type,
        sampleCollection: stepAction.sampleCollection,
        referenceSample: stepAction.referenceSample,
        checks: stepAction.checks,
        document: stepAction.document,
        ccRecipients: stepAction.ccRecipients,
        bccRecipients: stepAction.bccRecipients,
        testsChecklistId: stepAction.testsChecklistId,
        defectsChecklistId: stepAction.defectsChecklistId,
        documentId: stepAction.documentId,
        products: stepAction.products,
        calculationMethod: stepAction.calculationMethod,
        updatableByInspector: stepAction.updatableByInspector,
        instructions: stepAction.instructions,
        commentsMandatoryWhenNotApplicable: stepAction.commentsMandatoryWhenNotApplicable,
        photosMandatoryWhenDefectsFound: stepAction.photosMandatoryWhenDefectsFound,
        commentsMandatoryWhenDefectsFound: stepAction.commentsMandatoryWhenDefectsFound,
        canInspectionBeMarkedAsPending: stepAction.canInspectionBeMarkedAsPending,
        fileId: stepAction.fileId,
        text: stepAction.text,
        // properties for SIMPLIFIED_MEASUREMENTS_CHECKLIST
        measurementOverallResultCalculationMethod: stepAction.measurementOverallResultCalculationMethod,
        expectedResult: stepAction.expectedResult,
        isCommentRequiredWhenMarkedAsNA: stepAction.isCommentRequiredWhenMarkedAsNA,
        requiredProofs: stepAction.requiredProofs,
        // properties for STARTING_PICTURES
        pictureSource: stepAction.pictureSource,
      });
    });

    return editionStepActions;
  }

  public actionName: string;
  public type: WorkflowAnyStepActionTypeType;
  public id?: string;
  public uuid?: QimaUuidType;
  public sampleCollection?: ISampleCollection;
  public referenceSample?: IBrdInspectionReferenceSample;
  public recipients?: string[];
  public checks?: IWorkflowCheckTemplate[];
  public document?: IWorkflowTemplateStepActionDocument;
  public testsChecklistId?: number;
  public defectsChecklistId?: number;
  public documentId?: string;
  public products?: IMeasurementChecklistProduct[];
}

import { tensor1d } from "@tensorflow/tfjs-core";
import { registerOp } from "@tensorflow/tfjs-converter";
let registered = false;
export function registerOps() {
  if (registered) return;
  registerOp("UnsortedSegmentMax", node => {
    const valuesShape = node.inputs[0].shape,
      indexesShape = node.inputs[1].shape;
    if (valuesShape.length != indexesShape.length) throw new Error("UnsortedSegmentMax: values and indexes must have the same rank.");
    for (let i = 0; i < valuesShape.length; i++) if (valuesShape[i] != indexesShape[i]) throw new Error("UnsortedSegmentMax: values and indexes must have the same shape.");
    const values = node.inputs[0].dataSync();
    const indexes = node.inputs[1].dataSync();
    const result = [];
    for (let i = 0; i < values.length; i++) {
      const segmentId = indexes[i];
      const v = values[i];
      if (!(v <= result[segmentId])) result[segmentId] = v;
    }
    return tensor1d(result);
  });
  registered = true;
}
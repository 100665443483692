import { QpLoggerService } from '@library/services/qp-logger/qp-logger.service';
import { ETrackingEventId } from '@one/app/shared/services/third-party/analytics-manager/tracking/tracking.models';
import { MIXPANEL_ID } from '@one/app/shared/tokens/mixpanel.token';
import { Injectable, Inject } from '@angular/core';
import { isEmpty } from 'lodash/index';
import mixpanel, { Dict } from 'mixpanel-browser';

@Injectable({
  providedIn: 'root',
})
export class MixpanelService {
  public constructor(
    @Inject(MIXPANEL_ID) private readonly _mixpanel_id: string,
    private readonly _qpLoggerService: QpLoggerService
  ) {}

  public init(): void {
    if (!this._mixpanel_id || isEmpty(this._mixpanel_id.trim())) {
      this._qpLoggerService.warn('Mixpanel environment Id not defined');

      return;
    }

    mixpanel.init(this._mixpanel_id);
  }

  public identify(userToken: string, properties: Dict): void {
    if (this._mixpanel_id && userToken) {
      mixpanel.identify(userToken);
      mixpanel.people.set(properties);
    }
  }

  public track(id: ETrackingEventId, properties?: Dict): void {
    if (id && this._mixpanel_id) {
      mixpanel.track(id, properties);
    }
  }

  public reset(): void {
    if (this._mixpanel_id) {
      mixpanel.reset();
    }
  }
}

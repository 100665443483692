import { IQpCoordinates } from '@library/models/qp-coordinates.models';
import { IQpPosition } from '@library/models/qp-position';

export class QpCoordinates implements IQpCoordinates {
  public static create(coordinates: { latitude: number; longitude: number }): QpCoordinates {
    return new QpCoordinates(coordinates.latitude, coordinates.longitude);
  }

  public static fromPosition(position: IQpPosition): IQpCoordinates {
    return {
      latitude: +position.coords.latitude.toFixed(6),
      longitude: +position.coords.longitude.toFixed(6),
    };
  }

  private constructor(
    public latitude: number,
    public longitude: number
  ) {}
}
